import { useState } from 'react';
import { FaShareAlt } from 'react-icons/fa';
import {
  FaFileLines,
  FaLocationDot,
  FaMoneyBills,
  FaShirt,
  FaUser,
} from 'react-icons/fa6';
import { useParams } from 'react-router-dom';
import { CurrencyCell } from '@breeze-ai/ui-library';
import {
  Button,
  LinkButton,
  SectionLayout,
  Typography,
} from '@breezeai-frontend/cargo-ui';
import { useSuspenseQuery } from '@tanstack/react-query';
import { capitalize } from 'lodash-es';
import invariant from 'tiny-invariant';

import { useUser } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { useTracking } from '../../../utils/snowplow/hooks';
import { ActiveOpenCoverTooltip } from '../../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { AdditionalClauses } from '../../components/AdditionalClauses/AdditionalClauses';
import { AttributesGrid } from '../../components/AttributesGrid/AttributesGrid';
import { InfoItem } from '../../components/InfoItem/InfoItem';
import { PageSection } from '../../components/PageSection/PageSection';
import { PremiumBreakdown } from '../../components/PremiumBreakdown/PremiumBreakdown';
import { QuoteStatusBanner } from '../../components/QuoteStatusBanner/QuoteStatusBanner';
import { CONVEYANCE_TYPE_ICONS } from '../../constants';
import labels from '../../labels';
import { getModeOfTransportDisplayString } from '../../policies/PolicyDetailsPage/PolicyShipmentDetails/utils';
import { ShareQuoteDialog } from '../../policy-flow/components/ShareQuoteDialog/ShareQuoteDialog';
import { useShareQuote } from '../../policy-flow/hooks/use-share-quote';
import {
  getPlaceDisplayValue,
  getPlaceModelDisplayValue,
} from '../../utils/places';
import { getPortShortDisplayValue } from '../../utils/ports';
import { quotesQueries } from '../network/queries';

// TODO Create skeleton loader for this component that inherits width and height from the parent component
export function QuoteDetailsPage() {
  useTracking();
  const user = useUser();
  const showShareQuote = Boolean(user?.permissions.quotes?.show_premiums);
  const [shareModalVisible, setShareModalVisibility] = useState<boolean>(false);
  const shareQuote = useShareQuote();

  const { isWtw } = usePlatform();
  const { quoteId } = useParams<{
    quoteId: string;
  }>();
  const { data } = useSuspenseQuery(quotesQueries.details({ quoteId }));
  invariant(quoteId, 'quoteId is required');
  // TODO remove this invariant when the query options don't return null result if quoteId is undefined
  invariant(data, 'quote is required');

  const {
    origin_port,
    origin_place,
    destination_port,
    destination_place,
    delivery_place,
    primary_transport_mode_code,
    letter_of_credit,
    distributor,
    customer,
    commodity_external_description,
    loading_place,
    commodity_value,
    commodity_currency,
    insured_value,
    insured_value_currency,
    freight_cost,
    premium_currency,
    external_reference,
    special_conditions,
    commodity_category,
    exclusion_reasons,
    quote_additional_clauses,
    status,
    id,
    premium_value,
    premium_rate,
    exchange_rate,
    tax,
    converted_customer_premium_value,
    converted_tax_amount,
  } = data || {};

  const originPortValue = origin_port
    ? getPortShortDisplayValue(origin_port)
    : undefined;
  const originPlaceValue = origin_place
    ? getPlaceDisplayValue(origin_place)
    : undefined;

  const destinationPortValue = destination_port
    ? getPortShortDisplayValue(destination_port)
    : undefined;
  const destinationPlaceValue = destination_place
    ? getPlaceDisplayValue(destination_place)
    : undefined;

  // Note: Above invariant makes this impossible for data to be undefined. The invariant should be removed.
  const isAuthorized = Boolean(user?.permissions.quotes?.view);
  const isAuthorizedToDuplicateQuote = Boolean(data?.permissions?.create);

  return (
    <SectionLayout
      isAuthorized={isAuthorized}
      actions={
        <div className="flex flex-row gap-2">
          {isAuthorizedToDuplicateQuote && (
            <LinkButton
              variant="secondary"
              width="full"
              label="Duplicate Quote"
              href={`/quotes/duplicate/${quoteId}`}
              data-testid="duplicate-quote-button"
            />
          )}
          {showShareQuote && (
            <Button
              variant="ghost"
              width="full"
              leftIcon={<FaShareAlt size={16} />}
              data-testid="share-quote-button"
              onPress={() => setShareModalVisibility(true)}
              label="Share quote via email"
            />
          )}
        </div>
      }
      className="flex flex-col gap-6"
      data-testid="quote-details-page"
      title={`Quote ${quoteId}`}
      backLink="/quotes"
      tooltip={
        isWtw
          ? {
              trigger: <ActiveOpenCoverTooltip.Trigger />,
              content: <ActiveOpenCoverTooltip.Content />,
            }
          : undefined
      }
    >
      <QuoteStatusBanner exclusionReasons={exclusion_reasons} status={status} />
      <div className="flex flex-wrap gap-6">
        <div className="flex flex-col gap-6 flex-1 basis-[300px]">
          <PageSection
            title="Shipment details"
            actions={
              external_reference && (
                <span
                  role="booking-reference"
                  className="flex flex-row gap-1 whitespace-nowrap"
                >
                  <Typography level="base" color="tertiary">
                    Reference:{' '}
                  </Typography>
                  <Typography level="h5">{external_reference}</Typography>
                </span>
              )
            }
          >
            <AttributesGrid>
              {loading_place && (
                <InfoItem
                  title="Place of loading"
                  value={getPlaceModelDisplayValue(loading_place)}
                  testId="shipment-loading-place"
                  Icon={FaLocationDot}
                />
              )}
              <InfoItem
                title="Origin"
                value={originPortValue || originPlaceValue}
                testId="shipment-origin"
                Icon={
                  primary_transport_mode_code === 'air' ||
                  primary_transport_mode_code === 'sea'
                    ? CONVEYANCE_TYPE_ICONS[primary_transport_mode_code]
                    : FaLocationDot
                }
              />
              <InfoItem
                title="Destination"
                value={destinationPortValue || destinationPlaceValue}
                testId="shipment-destination"
                Icon={
                  primary_transport_mode_code === 'air' ||
                  primary_transport_mode_code === 'sea'
                    ? CONVEYANCE_TYPE_ICONS[primary_transport_mode_code]
                    : FaLocationDot
                }
              />
              {delivery_place && (
                <InfoItem
                  title="Place of Discharge"
                  value={getPlaceModelDisplayValue(delivery_place)}
                  testId="shipment-delivery-place"
                  Icon={FaLocationDot}
                />
              )}
              <InfoItem
                title="Mode Of Transport"
                value={
                  primary_transport_mode_code
                    ? getModeOfTransportDisplayString(
                        primary_transport_mode_code,
                        Boolean(loading_place || delivery_place),
                      )
                    : undefined
                }
                Icon={
                  primary_transport_mode_code
                    ? CONVEYANCE_TYPE_ICONS[primary_transport_mode_code]
                    : FaLocationDot
                }
                testId="shipment-primary-mot"
              />
            </AttributesGrid>
          </PageSection>
          <PageSection
            title="Insurance details"
            data-testid="insurance-details"
          >
            <AttributesGrid>
              <InfoItem
                title={labels.fields.distributor}
                value={distributor?.legal_name}
                testId="shipment-distributor"
                Icon={FaUser}
              />
              <InfoItem
                title={labels.fields.cargoOwner}
                value={customer?.company_name}
                testId="shipment-cargo-owner"
                Icon={FaUser}
              />
              <InfoItem
                title={labels.quoteDetails.cargoValue}
                value={
                  <CurrencyCell
                    value={commodity_value}
                    currency={commodity_currency}
                    typographyProps={{ bold: true, level: 'h5' }}
                  />
                }
                testId="shipment-commodity-value"
                Icon={FaMoneyBills}
              />
              <InfoItem
                title="Insured Value"
                value={
                  <CurrencyCell
                    value={insured_value}
                    currency={insured_value_currency}
                    typographyProps={{ bold: true, level: 'h5' }}
                  />
                }
                testId="shipment-insured-value"
                Icon={FaMoneyBills}
              />
              <InfoItem
                title="Freight Cost"
                value={
                  <CurrencyCell
                    value={freight_cost}
                    currency={premium_currency}
                    typographyProps={{ bold: true, level: 'h5' }}
                  />
                }
                testId="shipment-freight-cost"
                Icon={FaMoneyBills}
              />
              <InfoItem
                title={labels.fields.commodity.category}
                value={commodity_category}
                testId="shipment-commodity-category"
                Icon={FaShirt}
              />
              <InfoItem
                title={labels.policyDetails.commodity}
                value={commodity_external_description}
                testId="shipment-commodity-description"
                Icon={FaShirt}
              />
            </AttributesGrid>
          </PageSection>
        </div>
        {premium_value && premium_currency && (
          <PageSection
            title="Price details"
            className="flex flex-1 flex-col lg:flex-grow-0 basis-80"
          >
            <PremiumBreakdown
              premium_value={premium_value}
              premium_currency={premium_currency}
              premium_rate={premium_rate}
              tax={tax}
              exchange_rate={exchange_rate}
              converted_customer_premium_value={
                converted_customer_premium_value
              }
              converted_tax_amount={converted_tax_amount}
              hide_tax_info={distributor?.hide_tax_info}
              componentProps={{
                components: { currency: { level: 'base' } },
                total: { title: { level: 'h5' }, currency: { level: 'h2' } },
              }}
            />
          </PageSection>
        )}
      </div>
      {(special_conditions?.length || letter_of_credit) && (
        <PageSection
          title="Additional details"
          data-testid="additional-details"
        >
          <AttributesGrid>
            {special_conditions?.map((condition, index) => (
              <InfoItem
                key={index}
                title="Special Condition"
                value={capitalize(condition.replace('_', ' '))}
                testId={`special-condition-${condition}`}
                Icon={FaFileLines}
              />
            ))}
            {letter_of_credit && (
              <InfoItem
                title={labels.quoteDetails.letterOfCredit}
                value={letter_of_credit}
                Icon={FaFileLines}
                testId="shipment-letter-of-credit"
              />
            )}
          </AttributesGrid>
        </PageSection>
      )}
      {quote_additional_clauses && quote_additional_clauses.length > 0 && (
        <AdditionalClauses additionalClause={quote_additional_clauses[0]} />
      )}
      <ShareQuoteDialog
        visible={shareModalVisible}
        onSend={async (recipientEmail: string) => {
          try {
            await shareQuote(id, recipientEmail);
          } catch (error) {
            reportException(error);
          } finally {
            setShareModalVisibility(false);
          }
        }}
        onClose={() => setShareModalVisibility(false)}
      />
    </SectionLayout>
  );
}
