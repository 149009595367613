import { useState } from 'react';
import { Button, Icon, Popover, Typography } from '@breeze-ai/ui-library';
import classnames from 'classnames';

import { usePlatform } from '../../../context/PlatformContext';
import { useSendSupportIssueEmail } from './support-email-hooks';
import styles from './SupportDialog.module.scss';

export const SupportDialog = () => {
  const { isWtw } = usePlatform();

  const [supportDialogVisible, setSupportDialogVisibility] =
    useState<boolean>();
  const [issueSubmitted, setIssueSubmitted] = useState<boolean>();
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>();
  const [userInput, setUserInput] = useState<string>('');
  const sendSupportIssueEmail = useSendSupportIssueEmail();

  const closeDialog = () => {
    setSupportDialogVisibility(false);
    setShowSuccessMessage(false);
    setIssueSubmitted(false);
    setUserInput('');
  };

  const toggleSupportDialog = () => {
    supportDialogVisible ? closeDialog() : setSupportDialogVisibility(true);
  };

  const submitUserInput = async () => {
    setIssueSubmitted(true);

    await sendSupportIssueEmail(userInput);
    setShowSuccessMessage(true);
  };

  const triggerButton = (
    <div className={styles.triggerButtonWrapper}>
      {/* // TODO THEMING - migrate to TW */}
      <Button
        level="secondary"
        role="support-dialog-button"
        circle
        className={styles.triggerButton}
        onClick={toggleSupportDialog}
        data-testid="contact-us-button"
        icon={supportDialogVisible ? 'times' : 'question'}
      />
    </div>
  );

  return (
    <Popover
      anchorElement={triggerButton}
      placement="top-left"
      visible={supportDialogVisible}
      onClose={closeDialog}
      className={styles.dialog}
    >
      <div className={styles.title}>
        <Typography level="h3">Contact Us</Typography>
      </div>
      <div
        role="dialog-content"
        data-testid="support-dialog-content"
        className={classnames(styles.body, {
          [styles.userInputPreviewVisible]: issueSubmitted,
          [styles.successMessage]: showSuccessMessage,
        })}
      >
        {showSuccessMessage ? (
          <div role="success-message" className={styles.successMessageWrapper}>
            <Icon
              icon="check"
              type="circle"
              variant="success"
              className={styles.icon}
            />
            <Typography level="h4" className={styles.successTitle}>
              Your message was sent successfully!
            </Typography>
            <Typography className={styles.successSubtitle}>
              {isWtw
                ? 'A member of our key account management team will be in touch by email as soon as possible.'
                : 'A member of our key account management team will be in touch by email within the next 24 hours.'}
            </Typography>
          </div>
        ) : (
          <>
            {issueSubmitted && (
              <Typography className={styles.userInputPreview}>
                {userInput}
              </Typography>
            )}
            <div>
              {!issueSubmitted && (
                <textarea
                  onChange={({ target: { value } }) => setUserInput(value)}
                  placeholder="Please describe your issue..."
                  className={styles.textarea}
                />
              )}
              <Button
                size="large"
                name="submit"
                block={true}
                onClick={submitUserInput}
                disabled={!userInput || issueSubmitted}
                prefixIcon="paper-plane"
                className={styles.submitButton}
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </div>
    </Popover>
  );
};
