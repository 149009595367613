import React, { type ComponentProps } from 'react';

import { Button } from '../../../components/Button/Button';
import { ErrorFlag } from '../../../components/common/illustrations/ErrorFlag';
import { ErrorStorm } from '../../../components/common/illustrations/ErrorStorm';
import { clsxMerge } from '../../../components/common/utils/classNameUtils';
import { Typography } from '../../../components/Typography/Typography';

export interface ErrorMessageProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  type: keyof typeof errorIllustration;

  /**
   * Each detail will be a different paragraph to help with centering the error message.
   */
  details: string[];
  // TODO change to a more generic type (ie children) to allow for more flexibility
  actions?: ComponentProps<typeof Button>[];
}

export const ClientErrors: Record<keyof typeof errorIllustration, string> = {
  PageNotFound: '404',
  Unauthorized: '403',
  SomethingWentWrong: '500',
} as const;

const errorIllustration = {
  PageNotFound: <ErrorFlag height={280} width={280} />,
  Unauthorized: <ErrorFlag height={280} width={280} />,
  SomethingWentWrong: <ErrorStorm height={280} width={280} />,
};

export function ErrorMessage({ title, details, ...props }: ErrorMessageProps) {
  return (
    <div
      {...props}
      role="error-message"
      className={clsxMerge(
        'flex flex-col items-center justify-center space-y-4 text-center',
        props.className,
      )}
    >
      {errorIllustration[props.type ?? 'SomethingWentWrong']}
      <div className="space-y-2 px-10">
        <Typography level="h1" color="primary">
          {title}
        </Typography>
        {details &&
          details.length &&
          details.map((detail) => (
            <Typography
              key={detail}
              text="noBold"
              color="tertiary"
              customStyles="text-lg leading-5"
            >
              {detail}
            </Typography>
          ))}
        <div className="flex justify-center gap-4 pt-6">
          {props.actions?.map((action) => (
            <Button
              key={action.label}
              data-testid={`error-message-action-${action.id}`}
              {...action}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
