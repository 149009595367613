import DOMPurify from 'dompurify';

import { removeInlineStyles } from '../../../../../../packages/cargo-ui/src/utils/tools';
import { type AdditionalClause } from '../../../model/AdditionalClause';
import { DeductibleMessage } from '../DeductibleMessage/DeductibleMessage';
import { PageSection } from '../PageSection/PageSection';

interface Props {
  additionalClause: AdditionalClause;
}

export function AdditionalClauses({ additionalClause }: Props) {
  const { clause_text, ...deductibleProps } = additionalClause;
  const cleanTextMarkup = DOMPurify.sanitize(clause_text);

  return (
    <PageSection title="Additional clauses">
      <div className="flex flex-col gap-1.5">
        <DeductibleMessage {...deductibleProps} />
        <div
          dangerouslySetInnerHTML={{
            __html: removeInlineStyles(cleanTextMarkup),
          }}
          className="text-text-secondary text-sm leading-3 text-left"
        />
      </div>
    </PageSection>
  );
}
