import { type HTMLProps, type ReactNode } from 'react';
import { Typography } from '@breeze-ai/ui-library';
import { clsxMerge } from '@breezeai-frontend/cargo-ui';

type PageSectionProps = HTMLProps<HTMLDivElement> & {
  title?: string;
  subtitle?: ReactNode;
  actions?: ReactNode;
  separator?: boolean;
  scroll?: boolean;
};

export const PageSection = ({
  title,
  subtitle,
  actions,
  separator = true,
  children,
  className,
  scroll,
  ...props
}: PageSectionProps) => {
  const showHeader = title || actions;

  return (
    <div
      className={clsxMerge(
        'w-full bg-system-grey-white p-6 rounded-radius-suface-2',
        className,
      )}
      {...props}
      style={{ overflow: scroll ? 'auto' : 'visible' }}
    >
      {showHeader && (
        <div
          className={clsxMerge(
            'flex items-center justify-between pb-4 flex-wrap',
            {
              'border-b-[1px]': separator,
            },
          )}
        >
          <div className="flex flex-col gap-2 whitespace-nowrap">
            <Typography role="heading" level="h4">
              {title}
            </Typography>
            {subtitle}
          </div>
          {actions}
        </div>
      )}
      <div
        className={showHeader ? 'pt-4' : ''} // If there is a header, add padding to the top of the children
      >
        {children}
      </div>
    </div>
  );
};
