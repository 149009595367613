import { FaUserFriends } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import { useSuspenseQuery } from '@tanstack/react-query';

import { InfoItem } from '../../../components/InfoItem/InfoItem';
import { PageSection } from '../../../components/PageSection/PageSection';
import { certificateQueries } from '../../network/queries';
import { gridClass } from '../CertificateShipmentDetails/CertificateShipmentDetails';

type PolicyShipmentDetailsProps = {
  className?: string;
  policyId?: string;
};

export const NamedAssuredDetails = ({
  className,
  policyId,
}: PolicyShipmentDetailsProps) => {
  const { data: certificate } = useSuspenseQuery(
    certificateQueries.details({ policyId }),
  );

  return (
    <PageSection
      title="Assured Details"
      className={className}
      data-testid="assured-details"
    >
      <div className={gridClass}>
        <InfoItem
          title="Primary assured"
          value={certificate?.quote?.distributor?.legal_name}
          testId="primary-assured"
          Icon={FaUserFriends}
        />
        <InfoItem
          title="Named Assured"
          value={certificate?.customer?.name}
          testId="named-assured"
          Icon={FaUserFriends}
        />
        <InfoItem
          className="lg:col-span-2"
          title="Named Assured Address"
          value={certificate?.customer_address?.full_address}
          testId="named-assured-address"
          Icon={FaLocationDot}
        />
      </div>
    </PageSection>
  );
};
