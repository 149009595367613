import { useMemo } from 'react';
import { FaCopy, FaDownload } from 'react-icons/fa6';
import {
  CurrencyCell,
  DateCell,
  getBaseColumnDefinition,
  type TableColumn,
  TextCell,
  Tooltip,
} from '@breeze-ai/ui-library';
import { useSuspenseQuery } from '@tanstack/react-query';
import { startCase } from 'lodash';
import capitalize from 'lodash/capitalize';

import { useFeatureToggle, useUser } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { type Policy } from '../../../model/Policy';
import { ActionMenu } from '../../components/ActionMenu/ActionMenu';
import { QuotePriceBreakdownTooltip } from '../../components/QuotePriceBreakdownTooltip/QuotePriceBreakdownTooltip';
import { COVERAGE_PACKAGES } from '../../constants';
import labels from '../../labels';
import { policiesQueries } from '../network/queries';

export const usePoliciesTableColumns = (): TableColumn<Policy>[] => {
  const { isWtw } = usePlatform();

  const sortingEnabled = useFeatureToggle('enable_column_sorting');
  const user = useUser();
  const isAuthorizedToViewTotalPremiumColumn = Boolean(
    user?.permissions.quotes?.show_premiums,
  );

  const {
    data: { coverage_options },
  } = useSuspenseQuery(policiesQueries.policyFormConfiguration());

  return useMemo(
    () =>
      [
        {
          ...getBaseColumnDefinition(
            'certificate_id',
            `${capitalize(labels.terms.policy.singular)} Number`,
          ),
          valueGetter: ({ row }: { row: Policy }) => row.certificate_id,
          renderCell: ({ value }: { value: string }) => (
            <TextCell>{value as string}</TextCell>
          ),
          minWidth: isWtw ? 190 : 150,
          sortable: true,
        },
        {
          ...getBaseColumnDefinition('created_at', 'Created On'),
          type: 'date',
          minWidth: 150,
          valueGetter: ({ row }: { row: Policy }) => row.created_time,
          renderCell: ({ row }: { row: Policy }) => (
            <DateCell
              format="SHORT_MONTH_NAME_DATE"
              value={row.created_time}
              createdBy={
                row.created_by_user && {
                  avatarColor: row.created_by_user.avatar_color,
                  name: `${row.created_by_user.first_name} ${row.created_by_user.last_name}`,
                }
              }
            />
          ),
          sortable: sortingEnabled,
        },
        ...(coverage_options.length > 1
          ? ([
              {
                ...getBaseColumnDefinition('coverage_package', 'Coverage Type'),
                minWidth: 150,
                valueGetter: ({ row: { quote } }: { row: Policy }) =>
                  quote?.coverage_package,
                renderCell: ({ row: { quote } }: { row: Policy }) => (
                  <TextCell>
                    {quote?.coverage_package
                      ? COVERAGE_PACKAGES[quote.coverage_package].label
                      : undefined}
                  </TextCell>
                ),
                sortable: true,
              },
            ] as TableColumn<Policy>[])
          : []),
        {
          ...getBaseColumnDefinition(
            'external_reference',
            labels.fields.bookingReference,
          ),
          valueGetter: ({ row }: { row: Policy }) =>
            row.quote?.external_reference,
          renderCell: ({ value }: { value: string }) => (
            <TextCell>{value as string}</TextCell>
          ),
          minWidth: isWtw ? 130 : 180,
          sortable: sortingEnabled,
        },
        isWtw
          ? {
              ...getBaseColumnDefinition(
                'primary_assured',
                labels.fields.distributor,
              ),
              valueGetter: ({ row }: { row: Policy }) =>
                row.distributor?.legal_name,
              renderCell: ({ value }: { value: string }) => (
                <Tooltip content={String(value)}>
                  <div className="flex flex-row items-center gap-2 truncate w-full">
                    <TextCell>{value as string}</TextCell>
                  </div>
                </Tooltip>
              ),
              sortable: false,
              minWidth: 160,
            }
          : null,
        {
          ...getBaseColumnDefinition('customer_name', labels.fields.cargoOwner),
          description: 'Co-Insured',
          valueGetter: ({ row }: { row: Policy }) => row.customer?.company_name,
          renderCell: ({ value }: { value: string }) => (
            <TextCell>{value as string}</TextCell>
          ),
          minWidth: 150,
          sortable: false,
        },
        {
          ...getBaseColumnDefinition('insured_value', 'Insured Value'),
          type: 'number',
          valueGetter: ({ row: { quote } }: { row: Policy }) =>
            quote?.insured_value,
          renderCell: ({ row: { quote } }: { row: Policy }) => (
            <CurrencyCell
              value={quote?.insured_value}
              currency={quote?.insured_value_currency}
            />
          ),
          minWidth: 150,
          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition(
            'commodity_type',
            labels.policiesTable.columns.commodity.title,
          ),
          valueGetter: ({ row: { quote } }: { row: Policy }) =>
            quote?.commodity_external_description,
          valueFormatter: ({ value }: { value: string }) =>
            startCase(value as string),
          renderCell: ({ formattedValue }: { formattedValue: string }) => (
            <TextCell>{formattedValue}</TextCell>
          ),
          minWidth: isWtw ? 200 : 150,
          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition('etd', 'Departure'),
          type: 'date',
          valueGetter: ({ row: { quote } }: { row: Policy }) => quote?.etd,
          renderCell: ({
            row: { quote },
            value,
          }: {
            row: Policy;
            value: string;
          }) => (
            <DateCell
              value={value as ISODate}
              title={
                quote?.origin_place?.display_name ?? quote?.origin_port_code
              }
            />
          ),
          minWidth: 150,
          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition('eta', 'Arrival'),
          valueGetter: ({ row: { quote } }: { row: Policy }) => quote?.eta,
          renderCell: ({
            row: { quote },
            value,
          }: {
            row: Policy;
            value: string;
          }) => (
            <DateCell
              value={value as ISODate}
              title={
                quote?.destination_place?.display_name ??
                quote?.destination_port_code
              }
            />
          ),
          width: 160,
          sortable: sortingEnabled,
        },
        isAuthorizedToViewTotalPremiumColumn && {
          ...getBaseColumnDefinition('customer_premium_value', 'Total'),
          type: 'number',
          valueGetter: ({ row: { quote } }: { row: Policy }) => {
            const { tax, premium_value = 0 } = quote ?? {};
            return (tax?.value ?? 0) + premium_value;
          },
          renderCell: ({
            row: { quote },
            value,
          }: {
            row: Policy;
            value: number;
          }) => {
            const {
              premium_value,
              premium_currency,
              tax,
              exchange_rate,
              converted_customer_premium_value,
              converted_tax_amount,
              distributor,
            } = quote ?? {};

            if (!premium_value || !premium_currency) {
              return <TextCell>—</TextCell>;
            }

            const shouldShowTooltip = Boolean(tax?.value || exchange_rate);

            return (
              <CurrencyCell
                value={value as number}
                currency={premium_currency}
                suffix={
                  shouldShowTooltip && (
                    <QuotePriceBreakdownTooltip
                      className="w-3.5 h-3.5"
                      premium_value={premium_value}
                      premium_currency={premium_currency}
                      tax={tax}
                      converted_customer_premium_value={
                        converted_customer_premium_value
                      }
                      converted_tax_amount={converted_tax_amount}
                      exchange_rate={exchange_rate}
                      hide_tax_info={distributor?.hide_tax_info}
                    />
                  )
                }
              />
            );
          },
          width: 160,
          sortable: sortingEnabled,
        },
        {
          ...getBaseColumnDefinition('action', ''),
          minWidth: 40,
          maxWidth: 60,
          renderCell: ({ row }: { row: Policy }) => {
            const items = [
              {
                label: 'Duplicate quote',
                href: `/quotes/duplicate/${row.quote?.id}`,
                leftIcon: <FaCopy />,
                isHidden: !row.quote?.permissions?.create,
                isDownload: false,
              },
              {
                label: 'Download certificate',
                href: row.certificate_url,
                leftIcon: <FaDownload />,
                isHidden: !row.certificate_url,
                isDownload: true,
              },
            ];
            return <ActionMenu items={items} id={String(row.id)} />;
          },
        },
      ].filter(Boolean) as TableColumn<Policy>[],
    [
      isWtw,
      coverage_options,
      sortingEnabled,
      isAuthorizedToViewTotalPremiumColumn,
    ],
  );
};
