import { type Dispatch, type SetStateAction, useState } from 'react';
import { type IconName } from '@fortawesome/fontawesome-svg-core';
import {
  type DateValue,
  getLocalTimeZone,
  parseDate,
  today,
} from '@internationalized/date';
import moment from 'moment';

import { RadioDropdownV2 } from '../components/RadioDropdownV2/RadioDropdownV2';
import { useTableFilters } from '../hooks/useTableFilters';

const getUpperDateRange = (option: string) => {
  switch (option) {
    case 'today':
      return moment().format('YYYY-MM-DD');
    case 'last_week':
      return moment().subtract(1, 'weeks').format('YYYY-MM-DD');
    case 'last_month':
      return moment().subtract(1, 'months').format('YYYY-MM-DD');
    default:
      return moment().subtract(1000, 'years').format('YYYY-MM-DD');
  }
};

const todayDate = today(getLocalTimeZone());

type DateRange = {
  start: DateValue;
  end: DateValue;
};

// TODO move this component to the cargo ui
// TODO this component should be locale aware
// TODO this component should not be using moment and date/internationalized
interface CreatedOnFilterProps {
  filterName: string;
  triggerTestId?: string;
  contentTestId?: string;
  name?: string;
  label?: string;
  dropdownIcon?: IconName;
  // TODO link to useTableFilters props
  setSearchParams: Dispatch<SetStateAction<URLSearchParams>>;
  searchParams: URLSearchParams;
}

export function CreatedOnFilterV2({
  triggerTestId = 'created-on-filter-trigger',
  contentTestId = 'created-on-filter-content',
  name = 'createdOn',
  label = 'Created on',
  dropdownIcon = 'clock',
  filterName,
  setSearchParams,
  searchParams,
}: CreatedOnFilterProps) {
  const { upsertFilter, removeFilter } = useTableFilters({
    setSearchParams,
  });
  const isFilterActive = searchParams.get(filterName);

  const defaultSelectedOption = isFilterActive ? 'custom_dates' : undefined;
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    defaultSelectedOption,
  );

  const dateRange = searchParams.get(filterName)?.split('_');
  const startDate = dateRange?.[0];
  const endDate = dateRange?.[1];
  const defaultCustomStartDate = startDate ? parseDate(startDate) : undefined;
  const defaultCustomEndDate = endDate ? parseDate(endDate) : undefined;
  const defaultCustomDates =
    defaultCustomStartDate && defaultCustomEndDate
      ? {
          start: defaultCustomStartDate,
          end: defaultCustomEndDate,
        }
      : null;
  const [customDates, setCustomDates] = useState<DateRange | null>(
    defaultCustomDates,
  );

  const options = [
    { label: 'Today', value: getUpperDateRange('today') },
    {
      label: 'Last week',
      value: getUpperDateRange('last_week'),
    },
    {
      label: 'Last month',
      value: getUpperDateRange('last_month'),
    },
    { label: 'Custom dates', value: 'custom_dates' },
  ];

  const onSelect = (optionValue: string) => {
    setSelectedOption(optionValue);
    // If custom dates is selected, set the custom dates to the dates in the url params if they exist
    if (optionValue === 'custom_dates') {
      startDate &&
        endDate &&
        setCustomDates({
          start: parseDate(startDate),
          end: parseDate(endDate),
        });
    } else {
      setCustomDates(null);
      upsertFilter({
        filterName,
        values: [optionValue, moment().format('YYYY-MM-DD')],
      });
    }
  };

  return (
    <RadioDropdownV2
      triggerTestId={triggerTestId}
      contentTestId={contentTestId}
      name={name}
      label={label}
      dropdownIcon={dropdownIcon}
      triggerButtonCount={isFilterActive ? 1 : undefined}
      options={options}
      onSelect={onSelect}
      selected={selectedOption}
      setSelected={setSelectedOption}
      onReset={() => {
        setSelectedOption(undefined);
        setCustomDates(null);
        removeFilter(filterName);
      }}
      isCustomDateRangeDisplayed={selectedOption === 'custom_dates'}
      customDateRange={{
        // Disable dates after today
        isDateUnavailable: (date) => date.compare(todayDate) > 0,
        value: customDates,
        onChange: ({ start, end }) => {
          setCustomDates({ start, end });
          // return if start or end is after today
          const isInFuture =
            start.compare(todayDate) > 0 || end.compare(todayDate) > 0;
          const isStartAfterEnd = start.compare(end) > 0;
          if (isInFuture || isStartAfterEnd) {
            return;
          }
          const startDateString = moment(start.toString()).format('YYYY-MM-DD');
          const endDateString = moment(end.toString()).format('YYYY-MM-DD');
          upsertFilter({
            filterName,
            values: [startDateString, endDateString],
          });
        },
      }}
    />
  );
}
