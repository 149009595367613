import { TextField, Typography } from '@breezeai-frontend/cargo-ui';
import { type FormApi } from '@tanstack/react-form';
import { useSuspenseQuery } from '@tanstack/react-query';
import { type zodValidator } from '@tanstack/zod-form-adapter';

import { useAppConfiguration } from '../../../../context/app-configuration/useAppConfiguration';
import {
  useAuthenticatedUser,
  useFeatureToggle,
} from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import { DistributorStatusEnum } from '../../../../model/Distributor';
import { type TitledOption } from '../../../../model/TitledOption';
import { useDistributors } from '../../../../network/apis/distributors/use-distributors';
import { CurrencyValueInput } from '../../../components/CurrencyValueInput/CurrencyValueInput';
import { CustomerComboBox } from '../../../components/CustomerComboBox/CustomerComboBox';
import { type CustomerOption } from '../../../components/CustomerComboBox/types';
import labels from '../../../labels';
import { policiesQueries } from '../../../policies/network/queries';
import { CommodityComboBox } from './components/CommodityComboBox';
import { ContainerModeSelect } from './components/ContainerModeSelect';
import { DistributorSelect } from './components/DistributorSelect';
import { type QuoteFormData } from './QuoteForm';

export function CargoDetails({
  form,
  isUpdate,
}: {
  form: FormApi<QuoteFormData, typeof zodValidator>;
  isUpdate: boolean;
}) {
  const { isWtw } = usePlatform();

  const showDutyCostField = useFeatureToggle('enable_shipments_duty_cost');

  const user = useAuthenticatedUser();

  const { currencies, container_modes } = useAppConfiguration();

  const { data: configuration } = useSuspenseQuery(
    policiesQueries.policyFormConfiguration(),
  );

  const { data: unfilteredDistributors, isLoading } = useDistributors({
    params: {
      paginate: false,
      order: 'asc',
      include_archived: isUpdate,
    },
  });

  const distributors = unfilteredDistributors?.filter(
    (distributor) => distributor.can_issue_policy,
  );

  const shouldShowDistributorSelector =
    !isLoading && (distributors ?? [])?.length > 0;

  return (
    <>
      <Typography
        level="h3"
        customStyles="pt-5 pb-4 sticky top-0 bg-gradient-to-b from-white from-80% to-transparent z-10"
      >
        {labels.quoteForm.title}
      </Typography>

      <div className="xl:grid xl:grid-cols-2 xl:gap-y-5 xl:gap-x-4 max-xl:space-y-6 mb-4">
        {shouldShowDistributorSelector && (
          <>
            <form.Field
              name="distributorId"
              validators={{
                onSubmit: ({ value }) => {
                  if (!value) {
                    return 'Please select a primary assured';
                  }
                  const distributor = distributors?.find(
                    (distributor) => distributor.id === Number(value),
                  );
                  const distributorIsArchived =
                    distributor?.status === DistributorStatusEnum.ARCHIVED;

                  if (distributorIsArchived) {
                    return 'Please select a new primary assured in order to update the certificate';
                  }
                  return;
                },
              }}
              children={(field) => (
                <DistributorSelect
                  id={field.name}
                  name={field.name}
                  onBlur={field.handleBlur}
                  defaultSelectedKey={
                    form.state.values.distributorId || user.distributor.id
                  }
                  onSelectionChange={(key) => {
                    if (key) {
                      field.handleChange(Number(key));
                    }
                  }}
                  isWtw={isWtw}
                  isInvalid={field.state.meta.errors?.length > 0}
                  errorMessage={field.state.meta.errors.join(', ')}
                  distributors={distributors ?? []}
                  isLoading={isLoading}
                />
              )}
            />

            {/* empty grid item so items start from the second column */}
            <span />
          </>
        )}

        <form.Field
          name="commodityCategory"
          validators={{
            onSubmit: ({ value }) => {
              if (!value) {
                return 'Please select a commodity category';
              }
              return;
            },
          }}
          children={(field) => {
            const value = field.getValue();

            return (
              <CommodityComboBox
                data-testid="commodity-category-selector"
                isRequired
                label={labels.fields.commodity.category}
                placeholder="Select a commodity type"
                menuTrigger="focus"
                id={field.name}
                name={field.name}
                onBlur={field.handleBlur}
                defaultInputValue={value ? value.name : undefined}
                onInputChange={(value) => {
                  if (!value) {
                    field.handleChange(null);
                  }
                }}
                onSelectionChange={(key) => {
                  if (key) {
                    const { id, title }: TitledOption = JSON.parse(String(key));

                    field.handleChange({ name: title, typeId: id });
                  }
                }}
                isInvalid={field.state.meta.errors.length > 0}
                errorMessage={field.state.meta.errors.join(', ')}
              />
            );
          }}
        />
        <form.Field
          name="commodityDescription"
          validators={{
            onSubmit: ({ value }) => {
              if (!value) {
                return 'Please enter a commodity description';
              }
              return;
            },
          }}
          children={(field) => (
            <TextField
              data-testid="commodity-description-input"
              isRequired
              placeholder="Describe the commodity"
              label={labels.fields.commodity.description}
              id={field.name}
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={field.handleChange}
              isInvalid={field.state.meta.errors?.length > 0}
              errorMessage={field.state.meta.errors.join(', ')}
            />
          )}
        />
        <form.Subscribe
          selector={(state) => [state.values.commodityCurrency]}
          children={([commodityCurrency]) => (
            <>
              <form.Field name="commodityCurrency" children={() => null} />
              <form.Field
                name="commodityValue"
                validators={{
                  onSubmit: ({ value }) => {
                    if (!value) {
                      return 'Please enter a commodity value';
                    }

                    const numberValue = Number(value);
                    if (numberValue <= 0) {
                      return 'Commodity value cannot be negative or zero';
                    }

                    return;
                  },
                }}
                children={(field) => {
                  return (
                    <CurrencyValueInput
                      testId="commodity-value-input"
                      label={labels.fields.commodity.value}
                      required={true}
                      placeholder="Type amount"
                      value={
                        field.state.value
                          ? Number(field.state.value)
                          : undefined
                      }
                      name={field.name}
                      onBlur={field.handleBlur}
                      currency={commodityCurrency}
                      currencyOptions={currencies}
                      onChange={(value) => {
                        if (!value) {
                          field.handleChange(null);
                          return;
                        }

                        field.handleChange(value);
                      }}
                      onCurrencyChange={(currency) => {
                        field.form.setFieldValue('commodityCurrency', currency);

                        // Need to do this since commodityCurrency doesn't have it's own component
                        const meta = field.getMeta();
                        field.form.setFieldMeta('commodityCurrency', {
                          ...meta,
                          isTouched: true,
                          isDirty: true,
                        });
                      }}
                      error={field.state.meta.errors?.length > 0}
                      errorHelperText={field.state.meta.errors.join(', ')}
                      inputDropdownProps={{
                        testId: 'commodity-value-currency-selector',
                      }}
                    />
                  );
                }}
              />
            </>
          )}
        />
        <form.Field
          name="cargoOwner"
          validators={{
            onSubmit: ({ value }) => {
              if (!value) {
                return 'Please select or type cargo owner';
              }
              return;
            },
          }}
          children={(field) => {
            const value = field.getValue();

            return (
              <CustomerComboBox
                data-testid="customer-selector"
                isRequired
                label={labels.fields.cargoOwner}
                placeholder="Full name"
                menuTrigger="focus"
                id={field.name}
                name={field.name}
                onBlur={(e) => {
                  field.handleBlur();

                  // This is to handle the case where the user only types the company name and does not select from the dropdown
                  if (
                    e.target.value &&
                    e.target.value !== value?.company_name
                  ) {
                    field.setValue({
                      company_name: e.target.value,
                    });
                    field.setMeta({
                      ...field.getMeta(),
                      isTouched: true,
                      isDirty: true,
                    });
                  }
                }}
                defaultInputValue={value ? value.company_name : undefined}
                onInputChange={(value) => {
                  if (!value) {
                    field.handleChange(null);
                  }
                }}
                onSelectionChange={(key) => {
                  if (key) {
                    const customer: CustomerOption = JSON.parse(String(key));
                    field.handleChange(customer);
                  }
                }}
                isInvalid={field.state.meta.errors?.length > 0}
                errorMessage={field.state.meta.errors.join(', ')}
              />
            );
          }}
        />
        {!isWtw && (
          <form.Subscribe
            selector={(state) => [state.values.primaryMot]}
            children={([primaryMot]) => {
              return (
                <form.Field
                  name="containerModeId"
                  validators={{
                    onSubmit: ({ value }) => {
                      if (!value) {
                        return 'Please select a load type';
                      }

                      return;
                    },
                  }}
                  children={(field) => (
                    <ContainerModeSelect
                      data-testid="container-mode-selector"
                      id={field.name}
                      name={field.name}
                      onBlur={field.handleBlur}
                      items={primaryMot ? container_modes[primaryMot] : []}
                      selectedKey={field.getValue() ?? null}
                      onSelectionChange={(key) => {
                        if (key) {
                          field.handleChange(Number(key));
                        }
                      }}
                      isInvalid={field.state.meta.errors?.length > 0}
                      errorMessage={field.state.meta.errors.join(', ')}
                    />
                  )}
                />
              );
            }}
          />
        )}
        <form.Subscribe
          selector={(state) => [state.values.freightCostCurrency]}
          children={([freightCostCurrency]) => (
            <>
              <form.Field name="freightCostCurrency" children={() => null} />
              <form.Field
                name="freightCost"
                validators={{
                  onSubmit: ({ value }) => {
                    if (!value && configuration?.is_freight_cost_required) {
                      return 'Please enter the freight cost';
                    }

                    if (!value) {
                      return;
                    }

                    const numberValue = Number(value);
                    if (numberValue <= 0) {
                      return 'Freight cost cannot be negative or zero';
                    }

                    return;
                  },
                }}
                children={(field) => (
                  <CurrencyValueInput
                    required={configuration?.is_freight_cost_required}
                    testId="freight-cost-input"
                    label="Freight Cost"
                    placeholder="Enter amount"
                    value={
                      field.state.value ? Number(field.state.value) : undefined
                    }
                    name={field.name}
                    onBlur={field.handleBlur}
                    currency={freightCostCurrency}
                    currencyOptions={currencies}
                    onChange={(value) => {
                      if (!value) {
                        field.handleChange(null);
                        return;
                      }

                      field.handleChange(value);
                    }}
                    onCurrencyChange={(currency) => {
                      field.form.setFieldValue('freightCostCurrency', currency);

                      // Need to do this since freightCostCurrency doesn't have it's own component
                      const meta = field.getMeta();
                      field.form.setFieldMeta('freightCostCurrency', {
                        ...meta,
                        isTouched: true,
                        isDirty: true,
                      });
                    }}
                    error={field.state.meta.errors?.length > 0}
                    errorHelperText={field.state.meta.errors.join(', ')}
                    inputDropdownProps={{
                      testId: 'freight-cost-currency-selector',
                    }}
                  />
                )}
              />
            </>
          )}
        />
        <form.Field
          name="externalReference"
          children={(field) => (
            <TextField
              data-testid="booking-reference-input"
              placeholder="Your internal reference"
              label={labels.fields.bookingReference}
              id={field.name}
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={field.handleChange}
              isInvalid={field.state.meta.errors?.length > 0}
              errorMessage={field.state.meta.errors.join(', ')}
            />
          )}
        />
        {showDutyCostField && (
          <form.Subscribe
            selector={(state) => [state.values.dutyCostCurrency]}
            children={([dutyCostCurrency]) => (
              <>
                <form.Field name="dutyCostCurrency" children={() => null} />
                <form.Field
                  name="dutyCost"
                  validators={{
                    onSubmit: ({ value }) => {
                      if (!value) {
                        return;
                      }

                      const numberValue = Number(value);
                      if (numberValue <= 0) {
                        return 'Duty cost cannot be negative or zero';
                      }

                      return;
                    },
                  }}
                  children={(field) => (
                    <CurrencyValueInput
                      testId="duty-cost-input"
                      label="Duty Cost"
                      placeholder="Enter amount"
                      value={
                        field.state.value
                          ? Number(field.state.value)
                          : undefined
                      }
                      name={field.name}
                      onBlur={field.handleBlur}
                      currency={dutyCostCurrency}
                      currencyOptions={currencies}
                      onChange={(value) => {
                        if (!value) {
                          field.handleChange(null);
                          return;
                        }

                        field.handleChange(value);
                      }}
                      onCurrencyChange={(currency) => {
                        field.form.setFieldValue('dutyCostCurrency', currency);

                        // Need to do this since dutyCostCurrency doesn't have it's own component
                        const meta = field.getMeta();
                        field.form.setFieldMeta('dutyCostCurrency', {
                          ...meta,
                          isTouched: true,
                          isDirty: true,
                        });
                      }}
                      error={field.state.meta.errors?.length > 0}
                      errorHelperText={field.state.meta.errors.join(', ')}
                      inputDropdownProps={{
                        testId: 'duty-cost-currency-selector',
                      }}
                    />
                  )}
                />
              </>
            )}
          />
        )}
      </div>
    </>
  );
}
