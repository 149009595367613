import { SectionLayout } from '@breezeai-frontend/cargo-ui';
import capitalize from 'lodash/capitalize';

import { useUser } from '../../context/auth/auth-hooks';
import { usePlatform } from '../../context/PlatformContext';
import { useTracking } from '../../utils/snowplow/hooks';
import { ActiveOpenCoverTooltip } from '../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import labels from '../labels';
import { PoliciesTable } from './PoliciesTable/PoliciesTable';

export function PoliciesPage() {
  useTracking();
  const { isWtw } = usePlatform();

  const title = capitalize(labels.terms.policy.plural);
  const user = useUser();
  const isAuthorized = Boolean(user?.permissions.policies?.view);

  return (
    <SectionLayout
      isAuthorized={isAuthorized}
      title={title}
      tooltip={
        isWtw
          ? {
              trigger: <ActiveOpenCoverTooltip.Trigger />,
              content: <ActiveOpenCoverTooltip.Content />,
            }
          : undefined
      }
      data-testid="policies-page"
    >
      <PoliciesTable />
    </SectionLayout>
  );
}
