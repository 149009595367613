import { useSuspenseQuery } from '@tanstack/react-query';

import { DetailsPageClaimsSection } from '../../../components/PolicyClaims/PolicyClaims';
import { policiesQueries } from '../../../policies/network/queries';
import { certificateQueries } from '../../network/queries';

export function CertificateClaims({ policyId }: { policyId: string }) {
  const { data: certificate } = useSuspenseQuery(
    certificateQueries.details({ policyId }),
  );
  const isAuthorizedToViewClaims = Boolean(
    certificate?.claim_permissions?.view,
  );
  const isAuthorizedToCreateClaims = Boolean(
    certificate?.claim_permissions?.create,
  );

  // If the user is not authorized to view claims, we don't need to fetch them
  // This query should be inside the DetailsPageClaimsSection component
  const { data: claims } = useSuspenseQuery({
    ...policiesQueries.policyClaims({
      policyId: isAuthorizedToViewClaims ? policyId : undefined,
    }),
  });

  return (
    // TODO certificate should always be defined if using suspense query - workaround is to accommodate other components
    certificate &&
    isAuthorizedToViewClaims && (
      <DetailsPageClaimsSection
        certificateId={certificate.certificate_id}
        claims={claims ?? []}
        isAuthorizedToCreateClaims={isAuthorizedToCreateClaims}
      />
    )
  );
}
