import { useContext } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { useUser } from '../context/auth/auth-hooks';
import { AuthContext } from '../context/auth/AuthContext';
import { CertificateDetailsPage } from '../ui/certificates/CertificateDetailsPage/CertificateDetailsPage';
import { CertificateFlowPage } from '../ui/certificates/CertificateFlowPage/CertificateFlowPage';
import { CertificatesPage } from '../ui/certificates/CertificatesPage/CertificatesPage';
import { ClaimCreationFlow } from '../ui/claims/ClaimCreationFlow/ClaimCreationFlow';
import { ClaimDetailsPage } from '../ui/claims/ClaimDetailsPage/ClaimDetailsPage';
import { ClaimsPage } from '../ui/claims/ClaimsPage';
import { Section } from '../ui/components/Section/Section';
import { CustomersPage } from '../ui/customers/CustomersPage';
import { DTCFlow } from '../ui/dtc-flow/DTCFlow';
import { AnnualPolicyFlow } from '../ui/dtc-flow/flows/AnnualPolicyFlow';
import { PerShipmentFlow } from '../ui/dtc-flow/flows/PerShipmentFlow';
import { TradeCreditInsuranceFlow } from '../ui/dtc-flow/flows/TradeCreditInsuranceFlow';
import { DTCFlowSelection } from '../ui/dtc-flow/shared/DTCFlowSelection/DTCFlowSelection';
import { GuestUserPage } from '../ui/guest-user/GuestUserPage/GuestUserPage';
import { ResetPassword } from '../ui/guest-user/ResetPassword/ResetPassword';
import { SignIn } from '../ui/guest-user/SignIn/SignIn';
import { InsightsPage } from '../ui/insights/InsightsPage';
import { InvoiceDetailsPage } from '../ui/invoices/InvoiceDetailsPage/InvoiceDetailsPage';
import { InvoicesPage } from '../ui/invoices/InvoicesPage';
import { PoliciesPage } from '../ui/policies/PoliciesPage';
import {
  PolicyDetailsPage,
  PolicyDetailsPageErrorBoundary,
} from '../ui/policies/PolicyDetailsPage/PolicyDetailsPage';
import { PolicyFlow } from '../ui/policy-flow/PolicyFlow';
import { QuoteDetailsPage } from '../ui/quotes/QuoteDetailsPage/QuoteDetailsPage';
import { QuotesPage } from '../ui/quotes/QuotesPage/QuotesPage';
import { ShipmentsPage } from '../ui/shipments/ShipmentsPage';
import { useSidebarItems } from '../ui/sidebar/sidebar-hooks';
import { StorageDetailsPage } from '../ui/storage/StorageDetailsPage/StorageDetailsPage';
import { StoragePage } from '../ui/storage/StoragePage/StoragePage';
import { AuthorizedPlatform } from './AuthorizedPlatform/AuthorizedPlatform';
import { NavigateWithRoute } from './NavigateWithRoute';
import { ErrorBoundaryFallback } from './PlatformErrorBoundary/PlatformErrorBoundary';
import { ProtectedRoute } from './ProtectedRoute';
import { STSLoginCallback } from './tradeflow-login/STSLoginCallback';
import { UnassignedUserPage } from './UnassignedUserPage';

export const AppRouter = () => {
  const user = useUser();

  // As feature flags are returned via the user/ API, the DTC flow is enabled based on the theme - no user/ API call is made in the DTC flow.
  const enableDTCFlow = import.meta.env.VITE_APP_THEME === 'breeze';
  const items = useSidebarItems();
  const landingPage = items.find((item) => !item.hidden);

  // The below logic is to derive the path for the page that the user intended to visit once they login
  const { redirectToOnLogin } = useContext(AuthContext);
  let intendedPath = String(landingPage?.path);
  if (
    redirectToOnLogin &&
    redirectToOnLogin !== '/login' &&
    redirectToOnLogin !== '/'
  ) {
    intendedPath = redirectToOnLogin;
  }

  // See https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      // TODO - the error element will catch all errors in the app that dont have an error boundary lower down the tree, we should have a more specific error boundary for each type of error
      <Route path="/" errorElement={<ErrorBoundaryFallback />}>
        {/* Public Routes */}
        <Route
          path="login"
          element={user ? <Navigate to="/" replace /> : <GuestUserPage />}
        >
          <Route index element={<SignIn />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="callback" element={<STSLoginCallback />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>

        {/* Flexport routes (DTC flow) */}
        {enableDTCFlow && (
          <Route path="flexport" element={<DTCFlow />}>
            <Route
              index
              element={<Navigate to="./shipment-insurance" replace />}
            />
            <Route path="shipment-insurance" element={<PerShipmentFlow />} />
            <Route path="start" element={<DTCFlowSelection />} />
            <Route path="annual-policy" element={<AnnualPolicyFlow />} />
            <Route
              path="trade-credit-insurance"
              element={<TradeCreditInsuranceFlow />}
            />
            {/* To be removed when decided no longer necessary */}
            <Route
              path="continuous-entry-bonds"
              element={<Navigate to="/flexport/start" replace />}
            />
            <Route path="*" element={<Navigate to="/flexport" replace />} />
          </Route>
        )}

        {/* TODO this route should not exist - it should be handled by the error boundary.*/}
        <Route index path="unassigned" element={<UnassignedUserPage />} />

        {/* Authenticated Routes */}
        <Route
          ErrorBoundary={ErrorBoundaryFallback}
          path="/"
          element={
            <ProtectedRoute>
              <AuthorizedPlatform />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to={intendedPath} replace />} />
          <Route
            ErrorBoundary={ErrorBoundaryFallback}
            path="insights"
            element={<Section />}
          >
            <Route
              ErrorBoundary={ErrorBoundaryFallback}
              index
              element={<InsightsPage />}
            />
          </Route>

          <Route
            ErrorBoundary={ErrorBoundaryFallback}
            path="shipments"
            element={<Section />}
          >
            <Route index element={<ShipmentsPage />} />
          </Route>

          <Route
            ErrorBoundary={ErrorBoundaryFallback}
            path="certificates"
            element={<Section />}
          >
            <Route index element={<CertificatesPage />} />
            <Route path="create" element={<CertificateFlowPage />} />
            <Route
              path="update/:certificateId/:policyId"
              element={<CertificateFlowPage />}
            />
            <Route
              path="duplicate/:certificateId/:policyId"
              element={<CertificateFlowPage />}
            />
            {/* TODO: Remove 'details' from path */}
            <Route
              path="details/:certificateId/:policyId"
              element={<CertificateDetailsPage />}
            />
          </Route>

          <Route
            ErrorBoundary={ErrorBoundaryFallback}
            path="storage"
            element={<Section />}
          >
            <Route index element={<StoragePage />} />
            <Route
              path="create/:step/:storageLocationId?/:policyId?"
              element={<StoragePage />}
            />
            <Route
              path="update/:step/:storageLocationId/:policyId"
              element={<StorageDetailsPage />}
            />
            {/* TODO: Remove 'details' from path */}
            <Route
              path="details/:storageLocationId/:policyId"
              element={<StorageDetailsPage />}
            />
          </Route>

          {/* Quotes Routes */}
          <Route
            ErrorBoundary={ErrorBoundaryFallback}
            path="quotes"
            element={<Section />}
          >
            <Route
              index
              element={<QuotesPage />}
              ErrorBoundary={ErrorBoundaryFallback}
            />
            <Route
              path="create"
              element={<PolicyFlow />}
              ErrorBoundary={ErrorBoundaryFallback}
            />
            <Route
              path="details/:quoteId"
              element={<QuoteDetailsPage />}
              ErrorBoundary={ErrorBoundaryFallback}
            />
            {/* TODO makes this route more specific - create and "quoteId can clash" */}
            <Route
              path=":quoteId"
              element={<PolicyFlow />}
              ErrorBoundary={ErrorBoundaryFallback}
            />
            <Route
              path="duplicate/:quoteId"
              element={<PolicyFlow />}
              ErrorBoundary={ErrorBoundaryFallback}
            />
          </Route>

          {/* Policies Routes */}
          <Route
            ErrorBoundary={ErrorBoundaryFallback}
            path="policies"
            element={<Section />}
          >
            <Route index element={<PoliciesPage />} />
            <Route
              path=":policyId"
              element={<PolicyDetailsPage />}
              ErrorBoundary={PolicyDetailsPageErrorBoundary}
            />
          </Route>

          <Route
            ErrorBoundary={ErrorBoundaryFallback}
            path="claims"
            element={<Section />}
          >
            <Route index element={<ClaimsPage />} />
            <Route path="start" element={<ClaimCreationFlow />} />
            <Route path=":claimId" element={<ClaimDetailsPage />} />
          </Route>

          <Route
            ErrorBoundary={ErrorBoundaryFallback}
            path="invoices"
            element={<Section />}
          >
            <Route index element={<InvoicesPage />} />
            <Route path=":invoiceId" element={<InvoiceDetailsPage />} />
          </Route>

          <Route
            ErrorBoundary={ErrorBoundaryFallback}
            path="customers"
            element={<Section />}
          >
            <Route index element={<CustomersPage />} />
          </Route>

          {/* Backward compatibility routes */}
          <Route
            path="platform/*"
            element={
              <NavigateWithRoute
                to={({ location: { pathname, search } }) =>
                  `/${pathname}${search}`
                }
                replace
              />
            }
          />
          {/* TODO: Can be removed one route is no longer in use by emails */}
          <Route
            ErrorBoundary={ErrorBoundaryFallback}
            path="start"
            element={<Navigate to="/quotes/create" replace />}
          />

          {/* Catch all route */}
          <Route
            path="*"
            element={<Navigate to={landingPage?.path ?? 'insights'} replace />}
          />
        </Route>
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
};
