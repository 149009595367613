import { queryOptions } from '@tanstack/react-query';

import { normalizeClaimResponse } from '../../../network/apis/claims/normalizers';
import { normalizePolicyResponse } from '../../../network/apis/policies/normalizers';
import {
  type GetPolicyClaimsResponse,
  type PolicyModel,
} from '../../../network/apis/policies/types';
import { get } from '../../../network/apis/utils';
import { getApiClient } from '../../../network/AuthRequests';
import { bffServiceUrl } from '../../../network/netconfig';

export const policiesQueries = {
  details: ({ policyId }: { policyId?: string }) =>
    queryOptions({
      queryKey: ['policy', policyId!],
      // TODO: components using this set of options for suspense need to be refactored
      // as ternary/null pattern is not good
      // Reference: https://github.com/TanStack/query/discussions/6206
      queryFn: () =>
        policyId
          ? get<PolicyModel>(`${bffServiceUrl}/policies/${policyId}`)
          : null,
      select: (data) => data && normalizePolicyResponse(data),
      enabled: !!policyId,
    }),
  policyClaims: ({ policyId }: { policyId?: string }) =>
    queryOptions({
      queryKey: ['policyClaims', String(policyId)],
      // TODO: components using this set of options for suspense need to be refactored
      // as ternary/null pattern is not good
      // Reference: https://github.com/TanStack/query/discussions/6206
      queryFn: () =>
        policyId
          ? get<GetPolicyClaimsResponse>(
              `${bffServiceUrl}/policies/${policyId}/claims`,
            )
          : null,
      select: (data) => data && data.claims.map(normalizeClaimResponse),
      enabled: !!policyId,
    }),
  policyFormConfiguration: () =>
    queryOptions({
      queryKey: ['form', 'quote'],
      queryFn: async () => {
        const apiClient = await getApiClient();
        const { data } = await apiClient.getQuoteForm();

        return data;
      },
    }),
};
