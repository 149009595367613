import { useContext, useEffect } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';
import { CancelChangesModal, Typography } from '@breezeai-frontend/cargo-ui';
import { type FormApi, useForm } from '@tanstack/react-form';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { zodValidator } from '@tanstack/zod-form-adapter';

import { useAppConfiguration } from '../../../../context/app-configuration/useAppConfiguration';
import {
  useAuthenticatedUser,
  useUserSetting,
} from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import { type SupportedCurrencies } from '../../../../model/CurrencyValue';
import { type CoveragePackageType } from '../../../../model/Quote';
import {
  type ConveyanceType,
  type ShipmentSpecialCondition,
} from '../../../../model/Shipment';
import { useQuoteMutation } from '../../../../network/apis/quotes/hooks';
import { normalizeQuoteResponse } from '../../../../network/apis/quotes/normalizers';
import { type QuoteMutationActions } from '../../../../network/apis/quotes/types';
import { useRouteParams } from '../../../../router/router-hooks';
import { trackPageView } from '../../../../utils/snowplow/utils';
import { getDefaultPortOrPlace } from '../../../certificates/CertificateFlowPage/utils';
import { CoverageTypeSelector } from '../../../components/CoverageTypeSelector/CoverageTypeSelector';
import { type CustomerOption } from '../../../components/CustomerComboBox/types';
import { RouteDetails } from '../../../components/RouteDetails/RouteDetails';
import { RouteDetailsInputsProvider } from '../../../components/RouteDetails/RouteDetailsInputProvider';
import { type RouteDetailsFormData } from '../../../components/RouteDetails/types';
import { getDefaultInputValue } from '../../../components/RouteDetails/utils';
import { ShipmentSpecialConditionsSelector } from '../../../components/ShipmentSpecialConditionsSelector/ShipmentSpecialConditionsSelector';
import { policiesQueries } from '../../../policies/network/queries';
import { quotesQueries } from '../../../quotes/network/queries';
import { shipmentsQueries } from '../../../shipments/network/queries';
import { LetterOfCreditSelector } from '../../components/LetterOfCreditSelector/LetterOfCreditSelector';
import { FLOW_ERROR_MESSAGES, FlowSteps } from '../../constants';
import {
  type PolicyFlowUrlParams,
  type PolicyFlowUrlSearchParams,
} from '../../context/types';
import {
  usePolicyFlowContext,
  useSetFlowError,
  useSetFlowStep,
  useSetQuoteData,
} from '../../hooks/context-hooks';
import { CargoDetails } from './CargoDetails';
import { FormContext } from './FormContext';
import { QuoteFormActions } from './QuoteFormActions';
import { useQuoteFormLogic } from './useQuoteFormLogic';
import { getDefaultContainerModeId } from './utils';

export interface QuoteFormData extends RouteDetailsFormData {
  distributorId?: number;
  coveragePackage?: CoveragePackageType;
  commodityValue?: number | null;
  commodityCategory?: {
    name: string;
    typeId: number;
  } | null;
  commodityDescription?: string;
  commodityCurrency?: SupportedCurrencies;
  cargoOwner?: CustomerOption | null;
  containerModeId?: number;
  freightCost?: number | null;
  freightCostCurrency?: SupportedCurrencies;
  dutyCost?: number | null;
  dutyCostCurrency?: SupportedCurrencies;
  externalReference?: string;
  specialConditions?: ShipmentSpecialCondition[];
  letterOfCredit?: string;
}

export const QuoteFormContent = ({
  form,
  coverageOptions,
}: {
  form: FormApi<QuoteFormData, typeof zodValidator>;
  coverageOptions: CoveragePackageType[];
}) => {
  const { isWtw } = usePlatform();

  const { special_conditions, container_modes } = useAppConfiguration();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      form.state.isDirty &&
      currentLocation.pathname !== nextLocation.pathname &&
      // prevent the blocker from from blocking the route navigation to /quotes/:quoteId
      !nextLocation.pathname.includes('/quotes/'),
  );

  const { isDuplicate, isUpdate } = useQuoteFormLogic();

  return (
    <form
      noValidate
      data-testid="quote-submission-form"
      className="flex flex-row p-3 bg-white h-full w-full min-w-[600px] overflow-x-auto overflow-y-auto min-h-max gap-4 rounded-3xl"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        void form.handleSubmit();
      }}
    >
      <CancelChangesModal
        title="Are you sure you want to cancel this quote?"
        subtitle="Your changes will be lost."
        isOpen={blocker.state === 'blocked'}
        onOpenChange={() => blocker.reset && blocker.reset()}
        onDiscardChanges={() => blocker.proceed && blocker.proceed()}
      />

      <div className="flex flex-grow bg-surfaces-secondary rounded-2xl p-5 overflow-y-auto  min-w-96">
        <RouteDetails
          isUpdate={isUpdate}
          isDuplicate={isDuplicate}
          form={form}
          listeners={{
            primaryMot: {
              onChange: (value) => {
                form.setFieldValue(
                  'containerModeId',
                  container_modes[value][0]?.id,
                );

                const meta = form.getFieldMeta('containerModeId');
                if (!meta) return;

                form.setFieldMeta('containerModeId', {
                  ...meta,
                  isDirty: true,
                });
              },
            },
          }}
        />
      </div>

      <div className="flex flex-col h-full overflow-y-auto w-full min-w-96 ">
        {coverageOptions.length > 1 && (
          <div className="flex flex-col w-full min-h-fit">
            <Typography
              level="h3"
              customStyles="pt-5 pb-4 sticky top-0 bg-gradient-to-b from-white from-80% to-transparent z-10"
            >
              Coverage Type
            </Typography>
            <form.Field
              name="coveragePackage"
              children={(field) => (
                <CoverageTypeSelector
                  selected={field.getValue() ?? coverageOptions[0]} // Default to first option. There will always be at least one option.
                  onChange={field.handleChange}
                  options={coverageOptions}
                />
              )}
            />
          </div>
        )}
        <CargoDetails form={form} isUpdate={isUpdate} />

        <div>
          <Typography level="h4" customStyles="xl:col-span-full mt-6 mb-4">
            Special Conditions
          </Typography>
          <form.Field
            name="specialConditions"
            children={(field) => (
              <ShipmentSpecialConditionsSelector
                options={special_conditions}
                selected={form.getFieldValue('specialConditions')}
                onChange={field.handleChange}
              />
            )}
          />
        </div>

        {isWtw && (
          <div className="mb-4">
            <LetterOfCreditSelector form={form} />
          </div>
        )}

        <div className="w-full xl:w-auto flex flex-row gap-2 self-end mt-auto justify-end">
          <QuoteFormActions form={form} />
        </div>
      </div>
    </form>
  );
};

export function QuoteForm() {
  const { isWtw, isBreeze } = usePlatform();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { container_modes } = useAppConfiguration();
  const user = useAuthenticatedUser();

  const { isDuplicate, isUpdate, getPayload } = useQuoteFormLogic();

  const { resetForm } = useContext(FormContext);

  const defaultCurrency =
    useUserSetting<SupportedCurrencies>('default_currency');

  // TODO: remove these hook calls when the old context is deprecated
  const setFlowStep = useSetFlowStep();
  const setQuoteData = useSetQuoteData();
  const setFlowError = useSetFlowError();
  const { step } = usePolicyFlowContext();

  useEffect(() => {
    step === FlowSteps.QUOTE_FORM &&
      trackPageView(
        {
          eventFeature: 'quote',
          eventSubfeature: 'form',
          eventAction: 'page_view',
        },
        user,
      );
  }, [user, step]);

  const {
    params: { quoteId },
  } = useRouteParams<PolicyFlowUrlParams>();

  let action: QuoteMutationActions = 'create';
  if (isDuplicate) {
    action = 'duplicate';
  }
  if (isUpdate) {
    action = 'update';
  }

  const { mutate } = useQuoteMutation({
    action,
    quoteId,
    options: {
      onMutate: () => {
        setFlowStep(FlowSteps.LOADING);
      },
      onSuccess: async ({ data }) => {
        // TODO: deprecate old form context and hooks
        setQuoteData(normalizeQuoteResponse(data));
        setFlowStep(FlowSteps.QUOTE_SUMMARY);

        await queryClient.invalidateQueries({
          queryKey: ['quotes', String(data.id)],
          refetchType: 'active',
        });

        // Reset form so touched and dirty states are false again
        resetForm();

        navigate(`/quotes/${data.id}`);
      },
      onError: () => {
        setFlowError({
          fallbackStep: FlowSteps.QUOTE_FORM,
          message: isUpdate
            ? FLOW_ERROR_MESSAGES.updateQuote
            : FLOW_ERROR_MESSAGES.createQuote,
          confirmText: 'Try again',
          retry: () => form.handleSubmit(),
        });
      },
    },
  });

  const {
    searchParams: { shipment_id },
  } = useRouteParams<never, PolicyFlowUrlSearchParams>();

  const { data: quote } = useSuspenseQuery(quotesQueries.details({ quoteId }));
  const { data: shipment } = useSuspenseQuery(
    shipmentsQueries.details({ shipmentId: shipment_id }),
  );
  const {
    data: { coverage_options },
  } = useSuspenseQuery(policiesQueries.policyFormConfiguration());

  const preferredModeOfTransportCode: ConveyanceType =
    useUserSetting('preferred_mode_of_transport_code') ?? 'road';

  const initialData = quote || shipment;

  const isUpdateOrBreeze = isUpdate || isBreeze;

  const form = useForm<QuoteFormData, typeof zodValidator>({
    defaultValues: {
      distributorId: quote?.distributor_id || user.distributor.id,

      coveragePackage: quote?.coverage_package || coverage_options[0], // Default to first option. There will always be at least one option.

      origin: initialData
        ? getDefaultPortOrPlace(initialData, 'origin')
        : undefined,
      destination: initialData
        ? getDefaultPortOrPlace(initialData, 'destination')
        : undefined,
      primaryMot:
        initialData?.primary_transport_mode_code ||
        preferredModeOfTransportCode,

      placeOfLoading:
        quote && quote.loading_place
          ? {
              type: 'place',
              place: {
                place_id: quote.loading_place.provider_place_uuid,
                description: quote.loading_place.full_address,
              },
              port: null,
            }
          : undefined,
      placeOfLoadingMot: quote?.loading_transport_mode_code || 'road',

      placeOfDelivery:
        quote && quote.delivery_place
          ? {
              type: 'place',
              place: {
                place_id: quote.delivery_place.provider_place_uuid,
                description: quote.delivery_place.full_address,
              },
              port: null,
            }
          : undefined,
      placeOfDeliveryMot: quote?.delivery_transport_mode_code || 'road',

      commodityValue:
        isUpdateOrBreeze && initialData?.commodity_value
          ? initialData.commodity_value
          : undefined,
      commodityCurrency: initialData?.commodity_currency || defaultCurrency,
      commodityDescription:
        quote?.commodity_external_description ||
        shipment?.commodity_description ||
        '',
      commodityCategory:
        quote?.commodity_category && quote?.commodity_category_id
          ? {
              name: quote.commodity_category,
              typeId: quote.commodity_category_id,
            }
          : undefined,

      cargoOwner: initialData?.customer
        ? {
            id: initialData.customer.id,
            address: initialData.customer.address,
            company_name: initialData.customer.company_name,
          }
        : undefined,

      containerModeId: isWtw
        ? undefined
        : getDefaultContainerModeId(
            container_modes,
            preferredModeOfTransportCode,
            initialData,
          ),

      externalReference: isDuplicate
        ? ''
        : quote?.external_reference || shipment?.external_shipment_id || '',

      freightCost:
        isUpdateOrBreeze && initialData?.freight_cost
          ? initialData.freight_cost
          : undefined,
      freightCostCurrency:
        initialData?.freight_cost_currency || defaultCurrency,

      dutyCost: initialData?.duty_cost ? initialData.duty_cost : undefined,
      dutyCostCurrency: initialData?.duty_cost_currency || defaultCurrency,

      specialConditions: initialData?.special_conditions || undefined,
      letterOfCredit: quote?.letter_of_credit || '',
    },
    onSubmit: ({ formApi }) => {
      const payload = getPayload(formApi);
      return mutate(payload);
    },
    validatorAdapter: zodValidator,
  });

  return (
    <RouteDetailsInputsProvider
      // These are default values for the route details inputs
      originInputValue={getDefaultInputValue(form.getFieldValue('origin'))}
      destinationInputValue={getDefaultInputValue(
        form.getFieldValue('destination'),
      )}
      placeOfLoadingInputValue={
        form.getFieldValue('placeOfLoading')?.place?.description
      }
      placeOfDeliveryInputValue={
        form.getFieldValue('placeOfDelivery')?.place?.description
      }
    >
      <QuoteFormContent form={form} coverageOptions={coverage_options} />
    </RouteDetailsInputsProvider>
  );
}
