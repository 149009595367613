import { LinkButton, SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useUser } from '../../context/auth/auth-hooks';
import { useTracking } from '../../utils/snowplow/hooks';
import { ShipmentsTable } from './ShipmentsTable/ShipmentsTable';

export const ShipmentsPage = () => {
  useTracking();
  const user = useUser();
  const isAuthorized = Boolean(user?.permissions.shipments?.view);
  const isAuthorizedToCreateQuotes = Boolean(user?.permissions.quotes?.create);

  return (
    <SectionLayout
      isAuthorized={isAuthorized}
      title="Shipments"
      data-testid="shipments-page"
      actions={
        isAuthorizedToCreateQuotes && (
          <LinkButton
            size="small"
            width="auto"
            data-testid="get-quote-button"
            label="Get A Quote"
            href="/quotes/create"
          />
        )
      }
    >
      <ShipmentsTable />
    </SectionLayout>
  );
};
