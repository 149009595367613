import { FaShieldAlt } from 'react-icons/fa';
import {
  FaChartSimple,
  FaFileSignature,
  FaLocationDot,
  FaReceipt,
  FaShieldHalved,
  FaShip,
  FaTags,
  FaUserGroup,
} from 'react-icons/fa6';
import { RiLogoutBoxLine } from 'react-icons/ri';
import capitalize from 'lodash/capitalize';

import { useAuthActions, useUser } from '../../context/auth/auth-hooks';
import { useRouteSection } from '../../router/router-hooks';
import labels from '../labels';
import { type SidebarItem, type UserMenuItem } from './types';

export const useSidebarItems = (): SidebarItem[] => {
  const user = useUser();
  const showInsightsPage = Boolean(user?.permissions.insights?.view);
  const showQuotesPage = Boolean(user?.permissions.quotes?.view);
  const showPoliciesPage = Boolean(user?.permissions.policies?.view);
  const showShipmentsPage = Boolean(user?.permissions.shipments?.view);
  const showStoragePage = Boolean(user?.permissions.storage_locations?.view);
  const showCertificatesPage = Boolean(user?.permissions.certificates?.view);
  const showCustomersPage = Boolean(user?.permissions.customers?.view);
  const showClaimsPage = Boolean(user?.permissions.claims?.view);
  const showInvoicesPage = Boolean(user?.permissions.invoices?.view);

  return [
    {
      path: 'insights',
      Icon: FaChartSimple,
      title: 'Insights',
      hidden: !showInsightsPage,
    },
    {
      path: 'storage',
      Icon: FaLocationDot,
      title: 'Storage',
      hidden: !showStoragePage,
    },
    {
      path: 'certificates',
      Icon: FaShieldHalved,
      title: 'Certificates',
      hidden: !showCertificatesPage,
    },
    {
      path: 'customers',
      Icon: FaUserGroup,
      title: 'Customers',
      hidden: !showCustomersPage,
    },
    {
      path: 'shipments',
      Icon: FaShip,
      title: 'Shipments',
      hidden: !showShipmentsPage,
    },
    {
      path: 'quotes',
      Icon: FaTags,
      title: 'Quotes',
      hidden: !showQuotesPage,
    },
    {
      path: 'policies',
      Icon: FaShieldAlt,
      title: capitalize(labels.terms.policy.plural),
      hidden: !showPoliciesPage,
    },
    {
      path: 'claims',
      Icon: FaFileSignature,
      title: 'Claims',
      hidden: !showClaimsPage,
    },
    {
      path: 'invoices',
      Icon: FaReceipt,
      title: 'Invoices',
      hidden: !showInvoicesPage,
    },
  ];
};

export const useActiveSidebarItem = () => {
  const activeSection = useRouteSection();
  return activeSection;
};

export const useSidebarUserMenuItems = (): UserMenuItem[] => {
  const { logout } = useAuthActions();

  return [
    {
      title: 'Logout',
      icon: <RiLogoutBoxLine size="16px" />,
      onPress: logout,
    },
  ];
};
