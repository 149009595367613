import { useRef, useState } from 'react';
import {
  DatePicker,
  getDate,
  TextField,
  Typography,
} from '@breezeai-frontend/cargo-ui';
import { momentToCalendarDate } from '@breezeai-frontend/cargo-ui';
import { type FormApi } from '@tanstack/react-form';
import { useSuspenseQuery } from '@tanstack/react-query';
import { type zodValidator } from '@tanstack/zod-form-adapter';
import moment from 'moment';
import { z } from 'zod';

import { useAppConfiguration } from '../../../../context/app-configuration/useAppConfiguration';
import { useAuthenticatedUser } from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import { type SupportedCurrencies } from '../../../../model/CurrencyValue';
import { DistributorStatusEnum } from '../../../../model/Distributor';
import { type PlaceSuggestion } from '../../../../model/Place';
import { distributorQueries } from '../../../../network/apis/distributors/distributors';
import { useDistributors } from '../../../../network/apis/distributors/use-distributors';
import { CurrencyValueInput } from '../../../components/CurrencyValueInput/CurrencyValueInput';
import { CustomerComboBox } from '../../../components/CustomerComboBox/CustomerComboBox';
import { type CustomerOption } from '../../../components/CustomerComboBox/types';
import { PlacesComboBox } from '../../../components/PlacesComboBox/PlacesComboBox';
import { LetterOfCreditSelector } from '../../../policy-flow/components/LetterOfCreditSelector/LetterOfCreditSelector';
import { DistributorSelect } from '../../../policy-flow/steps/QuoteForm/components/DistributorSelect';
import {
  getArrivalDateLimits,
  getDepartureDateLimits,
} from '../../../utils/eta-etd';
import { getCarrierFieldLabel } from '../../../utils/forms';
import { type CertificateModel } from '../../types';
import { type CertificateFormProps } from './types';

export function ShipmentDetailsForm({
  form,
  isUpdate,
  certificate,
}: {
  form: FormApi<CertificateFormProps, typeof zodValidator>;
  isUpdate: boolean;
  certificate: CertificateModel | undefined;
}) {
  const { isWtw } = usePlatform();

  const { currencies } = useAppConfiguration();
  const [isAssuredAddressInputDisabled, setIsAssuredAddressInputDisabled] =
    useState(form.getFieldValue('assuredName')?.company_name.length === 0);
  const assuredNameRef = useRef<HTMLInputElement>(null);

  const { data: unfilteredDistributors, isLoading } = useDistributors({
    params: {
      paginate: false,
      order: 'asc',
      include_archived: isUpdate,
    },
  });
  const distributors = unfilteredDistributors?.filter(
    (distributor) => distributor.can_issue_policy,
  );

  const user = useAuthenticatedUser();
  const { data: openCover } = useSuspenseQuery(
    distributorQueries.activeOpenCover({ distributorId: user.distributor.id }),
  );

  const etdLimits = getDepartureDateLimits({
    eta:
      form.getFieldValue('eta') !== undefined &&
      form.getFieldValue('eta') !== ''
        ? moment(form.getFieldValue('eta'))
        : undefined,
    terminationDate:
      certificate?.open_cover?.termination_date !== undefined
        ? moment(certificate?.open_cover?.termination_date)
        : undefined,
    platform: isWtw ? 'wtw' : 'breeze',
  });

  const etaLimits = getArrivalDateLimits({
    etd:
      form.getFieldValue('etd') !== undefined &&
      form.getFieldValue('etd') !== ''
        ? moment(form.getFieldValue('etd'))
        : undefined,
    terminationDate:
      certificate?.open_cover?.termination_date !== undefined
        ? moment(certificate?.open_cover?.termination_date)
        : undefined,
    platform: isWtw ? 'wtw' : 'breeze',
  });

  return (
    <>
      <Typography level="h3" customStyles="py-4">
        Shipment Details
      </Typography>
      {/* TODO: When page is smaller than lg viewport, large, undesired whitespace is created at the bottom of the page due to the space-y- classes */}
      <div className="lg:grid lg:grid-cols-2 lg:gap-y-5 lg:gap-x-4 max-lg:space-y-6 mb-4">
        {isWtw && (
          <form.Field
            name="issueDate"
            validators={{
              onSubmit: z.string().min(1, 'Please enter a valid date value'),
            }}
            children={(field) => (
              <DatePicker
                isRequired
                data-testid="issue-date-picker"
                label="Issue date"
                // TODO reable when the diffault value is registed on the form
                // defaultValue={
                //   field.state.value ? getDate(field.state.value) : todayDate
                // }
                validate={() => {
                  if (field.state.meta.errors.length > 0) {
                    return field.state.meta.errors.join(', ');
                  }
                  return true;
                }}
                minValue={momentToCalendarDate(
                  moment(openCover.inception_date),
                )}
                maxValue={momentToCalendarDate(
                  moment(openCover.termination_date),
                )}
                id={field.name}
                name={field.name}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.toString())}
                isDateUnavailable={(date) => {
                  const issueDate = moment(date.toString());

                  return !issueDate.isBetween(
                    moment(openCover.inception_date),
                    moment(openCover.termination_date),
                  );
                }}
              />
            )}
          />
        )}
        <form.Field
          name="etd"
          validators={{
            onSubmit: z.string().min(1, 'Please enter a valid date value'),
          }}
          children={(field) => (
            <DatePicker
              isRequired
              minValue={momentToCalendarDate(etdLimits.min)}
              maxValue={momentToCalendarDate(etdLimits.max)}
              validate={() => {
                if (field.state.meta.errors.length > 0) {
                  return field.state.meta.errors.join(', ');
                }
                return true;
              }}
              data-testid="etd-date-picker"
              label="Date of Departure"
              defaultValue={getDate(field.state.value)}
              id={field.name}
              name={field.name}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.toString())}
              isDateUnavailable={(date) => {
                const etdMoment = moment(date.toString());
                const etaMoment = moment(field.form.getFieldValue('eta'));
                return (
                  etaMoment.isBefore(etdMoment) ||
                  (isWtw && etdMoment.isBefore(moment().subtract(30, 'days')))
                );
              }}
            />
          )}
        />
        <form.Field
          name="eta"
          children={(field) => {
            return (
              <DatePicker
                data-testid="eta-date-picker"
                minValue={momentToCalendarDate(etaLimits.min)}
                maxValue={momentToCalendarDate(etaLimits.max)}
                label="Date of Arrival"
                defaultValue={getDate(field.state.value)}
                id={field.name}
                name={field.name}
                onBlur={field.handleBlur}
                isDateUnavailable={(date) => {
                  const etaMoment = moment(date.toString());
                  const etdMoment = moment(field.form.getFieldValue('etd'));
                  return etaMoment.isBefore(etdMoment);
                }}
                onChange={(e) => e && field.handleChange(e.toString())}
              />
            );
          }}
        />

        <form.Field
          name="cargoValue"
          validators={{
            onSubmit: ({ value }) => {
              if (Number(value) <= 0) {
                return 'Please enter a valid amount';
              }
              return;
            },
          }}
          children={(field) => {
            return (
              <CurrencyValueInput
                type="number"
                step="any"
                aria-required
                data-testid="cargo-value-input"
                // TODO The way this is implemented is different to the rest of the fields. It will be replaced so the evil type coersion can be ignored
                value={
                  field.state.value ? Number(field.state.value) : undefined
                }
                currencyOptions={currencies}
                name={field.name}
                id={field.name}
                onChange={(value) => {
                  field.handleChange(value ? value.toString() : '');
                }}
                onBlur={field.handleBlur}
                currency={
                  field.form.state.values.cargoCurrency as SupportedCurrencies
                }
                onCurrencyChange={(currency) => {
                  field.handleChange(field.form.state.values.cargoValue);
                  field.form.setFieldValue('cargoCurrency', currency, {
                    touch: true,
                  });
                }}
                label="Commercial Invoice Value *"
                placeholder="Enter amount"
                testId="commodity-value-input"
                error={field.state.meta.errors?.length > 0}
                errorHelperText={field.state.meta.errors.join(', ')}
              />
            );
          }}
        />
        <form.Field
          name="bookingReference"
          children={(field) => (
            <TextField
              data-testid="booking-reference-input"
              placeholder="Enter booking reference"
              label="Reference"
              id={field.name}
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={field.handleChange}
              isInvalid={field.state.meta.errors?.length > 0}
              errorMessage={field.state.meta.errors.join(', ')}
            />
          )}
        />
        <form.Field
          name="cargoDescription"
          validators={{
            onSubmit: z.string().min(1, 'Please enter a valid description'),
            onChange: z
              .string()
              .max(300, 'Input exceeds maximum character limit of 300'),
          }}
          children={(field) => (
            <TextField
              isRequired
              data-testid="goods-description-text-area"
              variant="area"
              placeholder="Enter description"
              label="Description of Cargo"
              inputStyles="min-h-44 resize-none "
              id={field.name}
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={field.handleChange}
              isInvalid={field.state.meta.errors.length > 0}
              errorMessage={field.state.meta.errors.join(', ')}
            />
          )}
        />
        <form.Subscribe
          selector={(state) => [state.values.primaryMot]}
          children={([primaryMot]) => (
            <form.Field
              name="vesselName"
              children={(field) => (
                <TextField
                  data-testid="vessel-name-input"
                  placeholder="Enter name"
                  label={getCarrierFieldLabel(primaryMot)}
                  id={field.name}
                  name={field.name}
                  value={field.state.value}
                  onBlur={field.handleBlur}
                  onChange={field.handleChange}
                  isInvalid={field.state.meta.errors?.length > 0}
                  errorMessage={field.state.meta.errors.join(', ')}
                />
              )}
            />
          )}
        />
        <form.Field
          name="marksAndNumbers"
          validators={{
            onChange: z
              .string()
              .max(300, 'Input exceeds maximum character limit of 300'),
          }}
          children={(field) => (
            <TextField
              data-testid="marks-and-numbers-text-area"
              inputStyles="min-h-[100px] resize-none"
              variant="area"
              placeholder="Enter marks & numbers"
              label="Marks & Numbers"
              id={field.name}
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={field.handleChange}
              isInvalid={field.state.meta.errors?.length > 0}
              errorMessage={field.state.meta.errors.join(', ')}
            />
          )}
        />

        {isWtw && (
          <div className="col-span-2">
            <LetterOfCreditSelector form={form} />
          </div>
        )}

        <div className="lg:grid lg:grid-cols-2 lg:col-span-full lg:gap-x-4 space-y-6">
          <Typography level="h4" customStyles="lg:col-span-full mt-6">
            Assured Details
          </Typography>

          <form.Field
            name="distributorId"
            validators={{
              onSubmit: ({ value }) => {
                if (!value) {
                  return 'Please select a primary assured';
                }
                const distributor = distributors?.find(
                  (distributor) => distributor.id === Number(value),
                );
                const distributorIsArchived =
                  distributor?.status === DistributorStatusEnum.ARCHIVED;

                if (distributorIsArchived) {
                  return 'Please select a new primary assured in order to update the certificate';
                }
                return;
              },
            }}
            children={(field) => (
              <DistributorSelect
                id={field.name}
                name={field.name}
                onBlur={field.handleBlur}
                defaultSelectedKey={form.state.values.distributorId}
                onSelectionChange={(key) => {
                  if (key) {
                    field.handleChange(Number(key));
                  }
                }}
                isWtw={isWtw}
                distributors={distributors ?? []}
                isLoading={isLoading}
                isInvalid={field.state.meta.errors?.length > 0}
                errorMessage={field.state.meta.errors.join(', ')}
              />
            )}
          />
          <form.Field
            name="assuredName"
            validators={{
              onSubmit: () => {
                if (
                  !assuredNameRef.current?.value &&
                  !isAssuredAddressInputDisabled
                ) {
                  return 'Please enter an assured name';
                }
                return;
              },
            }}
            children={(field) => (
              <CustomerComboBox
                data-testid="assured-name-combobox"
                inputRef={assuredNameRef}
                label="Named Assured"
                menuTrigger="focus"
                placeholder="Enter named assured name"
                id={field.name}
                name={field.name}
                onBlur={(e) => {
                  // This is to handle the case where the user only types the company name and does not select from the dropdown
                  const isCreatingNewCustomer =
                    field.state.value?.company_name !== e.target.value;
                  if (isCreatingNewCustomer) {
                    field.setValue({
                      company_name: e.target.value.trim(),
                    });
                    field.setMeta({
                      ...field.state.meta,
                      isDirty: true,
                    });
                  }

                  // If the user clears the input, we need to clear the assured address as well.
                  if (e.target.value.length === 0) {
                    field.form.setFieldValue('assuredAddress', {
                      description: '',
                      place_id: '',
                    });
                    setIsAssuredAddressInputDisabled(true);
                    field.setMeta({
                      ...field.state.meta,
                      isDirty: true,
                    });
                  }

                  setIsAssuredAddressInputDisabled(
                    e.target.value.trim().length === 0,
                  );
                  field.handleBlur();
                }}
                onSelectionChange={(option) => {
                  if (option) {
                    const customer: CustomerOption = JSON.parse(String(option));
                    field.handleChange(customer);
                    customer.address?.address &&
                      field.form.setFieldValue(
                        'assuredAddress',
                        {
                          description: customer.address?.address?.full_address,
                          place_id: customer.address?.provider_place_uuid,
                        },
                        { touch: false },
                      );
                    setIsAssuredAddressInputDisabled(false);
                  }
                }}
                aria-label="Assured Name"
                defaultInputValue={field.state.value?.company_name}
                isInvalid={field.state.meta.errors?.length > 0}
                errorMessage={field.state.meta.errors.join(', ')}
              />
            )}
          />
          <form.Field
            name="assuredAddress"
            children={(field) => (
              <PlacesComboBox
                data-testid="assured-address-combobox"
                isDisabled={isAssuredAddressInputDisabled}
                aria-label="Named Assured Address"
                label="Named Assured Address"
                placeholder="Enter named assured address"
                id={field.name}
                name={field.name}
                onBlur={() => {
                  if (field.state.value?.place_id.length === 0) {
                    field.handleChange({
                      ...field.state.value,
                      place_id: '',
                      description: '',
                    });
                  }
                  field.handleBlur();
                }}
                selectedKey={JSON.stringify(field.state.value)}
                isInvalid={field.state.meta.errors?.length > 0}
                errorMessage={field.state.meta.errors.join(', ')}
                inputValue={field.state.value?.description}
                onInputChange={(value) => {
                  field.handleChange({
                    ...field.state.value,
                    place_id: '',
                    description: value,
                  });
                }}
                onSelectionChange={(option) => {
                  const parsedOption: PlaceSuggestion = JSON.parse(
                    String(option),
                  );
                  const isFieldUpdated =
                    parsedOption.place_id !== field.prevState.value?.place_id;
                  isFieldUpdated && field.handleChange(parsedOption);
                }}
              />
            )}
          />
        </div>
      </div>
    </>
  );
}
