import { LinkButton, SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useAuthenticatedUser } from '../../context/auth/auth-hooks';
import { usePlatform } from '../../context/PlatformContext';
import { useTracking } from '../../utils/snowplow/hooks';
import { ActiveOpenCoverTooltip } from '../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { ClaimsTable } from './ClaimsTable/ClaimsTable';

export const ClaimsPage = () => {
  useTracking();
  const user = useAuthenticatedUser();
  const { isWtw } = usePlatform();

  if (!user) {
    return null;
  }

  const isAuthorized = Boolean(user.permissions.claims?.view);
  const isAuthorizedToCreateClaims = Boolean(user.permissions.claims?.create);

  return (
    <SectionLayout
      isAuthorized={isAuthorized}
      title="Claims"
      tooltip={
        isWtw
          ? {
              trigger: <ActiveOpenCoverTooltip.Trigger />,
              content: <ActiveOpenCoverTooltip.Content />,
            }
          : undefined
      }
      data-testid="claims-page"
      actions={
        isAuthorizedToCreateClaims && (
          <LinkButton
            // TODO THEMING: Replace href with the enum
            href="/claims/start"
            data-testid="file-a-claim-button"
            variant="primary"
            width="auto"
            label="File A Claim"
          />
        )
      }
    >
      <ClaimsTable />
    </SectionLayout>
  );
};
