import { FaCalendarAlt, FaMapMarkerAlt, FaUser } from 'react-icons/fa';
import { FaFileLines } from 'react-icons/fa6';
import { DateCell, Link, Typography } from '@breeze-ai/ui-library';

import { useAppConfiguration } from '../../../context/app-configuration/useAppConfiguration';
import { usePlatform } from '../../../context/PlatformContext';
import { type Claim } from '../../../model/Claim';
import { AttributesGrid } from '../../components/AttributesGrid/AttributesGrid';
import { InfoItem } from '../../components/InfoItem/InfoItem';
import { PageSection } from '../../components/PageSection/PageSection';
import styles from './ClaimEventDetails.module.scss';

type ClaimEventDetailsProps = {
  claim: Claim;
  linkToPolicy?: boolean;
};

export const ClaimEventDetails = ({
  claim: { policy, event, description },
  linkToPolicy = false,
}: ClaimEventDetailsProps) => {
  const { isWtw } = usePlatform();

  const { claim_location_types } = useAppConfiguration();
  const { label: eventLocationType } =
    claim_location_types.find(
      (option) => option.value === event.location_type,
    ) ?? {};
  const itemType = isWtw ? 'Certificate' : 'Policy';

  return (
    <PageSection
      title="Event details"
      data-testid="claim-event-details"
      actions={
        <span data-testid="policy-certificate-id" className={styles.policyRef}>
          <Typography variant="input">{`${itemType} #`}</Typography>
          {policy && linkToPolicy && (
            <Typography clickable>
              <Link
                role="policy-link"
                icon="up-right-from-square"
                href={
                  isWtw
                    ? `/certificates/details/${policy.certificate_id}/${policy.id}`
                    : `/policies/${policy.id}`
                }
                target="_blank"
              />
            </Typography>
          )}
          <Typography bold>{policy?.certificate_id}</Typography>
        </span>
      }
    >
      {isWtw && (
        <div className="border-b border-b-gray-200 mb-6 pb-6">
          <InfoItem
            Icon={FaUser}
            title="Primary Assured"
            value={policy?.quote?.distributor?.legal_name}
            testId="event-date"
          />
        </div>
      )}
      <AttributesGrid className={styles.eventDetailsGrid}>
        <InfoItem
          Icon={FaCalendarAlt}
          title="Event Date"
          value={
            <DateCell
              value={event.start_time}
              typographyProps={{ level: 'h5', bold: true }}
            />
          }
          testId="event-date"
        />
        <InfoItem
          Icon={FaMapMarkerAlt}
          title="Location Type"
          value={eventLocationType}
          capitalize
          testId="event-location-type"
        />
        <InfoItem
          Icon={FaMapMarkerAlt}
          title="Event Address"
          value={event.location?.address?.full_address}
          testId="event-address"
        />
        <InfoItem
          Icon={FaMapMarkerAlt}
          title="Current Location of Goods"
          capitalize={false}
          value={event.current_cargo_place?.address?.full_address}
          testId="current-cargo-location"
        />
      </AttributesGrid>
      <AttributesGrid className={styles.descriptionWrapper}>
        <InfoItem
          Icon={FaFileLines}
          title="Event Description"
          value={<Typography>{description}</Typography>}
          testId="event-description"
        />
      </AttributesGrid>
    </PageSection>
  );
};
