import { useEffect, useState } from 'react';
import { Icon, Link, Typography } from '@breeze-ai/ui-library';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useSuspenseQuery } from '@tanstack/react-query';
import { chunk } from 'lodash';

import { getQuoteConditions } from '../../../network/apis/quotes/quotes';
import { type CoverageDescription } from '../../../network/apis/quotes/types';
import { policiesQueries } from '../../policies/network/queries';
import { quotesQueries } from '../../quotes/network/queries';
import { Loading } from '../Loading/Loading';
import { PageSection } from '../PageSection/PageSection';
import styles from './QuoteCoverageDescriptions.module.scss';

const icon = <Icon icon="chevron-down" size="sm" />;
const disclaimer = (isEuFreightForwarder: boolean) => (
  <Typography block>
    For full details of your coverage, please review your policy documents. If
    you still have questions, please contact us at{' '}
    {isEuFreightForwarder ? (
      <Link href="mailto:support-EU@breezeai.com">support-EU@breezeai.com</Link>
    ) : (
      <Link href="mailto:info@breezeai.com">info@breezeai.com</Link>
    )}
  </Typography>
);

// TODO the below fetchId is a temporary fix. The policy flow should submit the quote and then push the quoteId to the url when scrolling into view
export const QuoteCoverageDescriptions = ({
  quoteId,
  policyId,
}: {
  quoteId?: string;
  policyId?: string;
}) => {
  // Only one of these will run depending on the page and which id is available.
  const { data: quote } = useSuspenseQuery(quotesQueries.details({ quoteId }));
  const { data: policy } = useSuspenseQuery(
    policiesQueries.details({ policyId }),
  );

  const derivedQuoteId = quote?.id || policy?.quote?.id;

  const { data: configuration } = useSuspenseQuery(
    policiesQueries.policyFormConfiguration(),
  );

  const [descriptions, setDescriptions] = useState<CoverageDescription[]>();
  let columns: CoverageDescription[][] = [];

  useEffect(() => {
    if (!descriptions && derivedQuoteId) {
      getQuoteConditions(derivedQuoteId).then(setDescriptions);
    }
  }, [derivedQuoteId, descriptions]);

  if (descriptions) {
    const chunkSize = descriptions.length / 2;
    columns = chunk(
      descriptions,
      descriptions.length % 2 === 0 ? chunkSize : chunkSize + 1,
    );
  }

  return columns.length > 0 ? (
    <PageSection className="py-2 px-6">
      <div className={styles.wrapper} data-testid="coverage-descriptions">
        <Accordion
          disableGutters
          elevation={0}
          square
          role="main-exclusions-accordion"
        >
          <AccordionSummary className={styles.header} expandIcon={icon}>
            <Typography level="h4">Main Exclusions</Typography>
          </AccordionSummary>
          <Loading visible={!descriptions} thickness={3}>
            <AccordionDetails className={styles.content}>
              {configuration &&
                disclaimer(Boolean(configuration.is_eu_freight_forwarder))}
              <div className={styles.itemsList}>
                {columns.map((column, i) => (
                  <div key={i} className={styles.column}>
                    {column.map((coverage, j) => (
                      <Accordion
                        key={j}
                        disableGutters
                        className={styles.collapseItem}
                      >
                        <AccordionSummary expandIcon={icon}>
                          <Typography level="h4" bold>
                            {coverage.title}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="secondary">
                            {coverage.description}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Loading>
        </Accordion>
      </div>
    </PageSection>
  ) : null;
};
