import { type ComponentProps, useCallback } from 'react';
import { Autocomplete } from '@breeze-ai/ui-library';

import { usePlatform } from '../../../context/PlatformContext';
import { getPolicyCertificateIdList } from '../../../network/apis/policies/policies';

type CertificateIdAutocompleteProps = Omit<
  ComponentProps<typeof Autocomplete<string>>,
  'getOptionValue' | 'defaultValue'
>;

export const CertificateIdAutocomplete = ({
  inputProps,
  ...props
}: CertificateIdAutocompleteProps) => {
  const getCertificateIdsOptions = useCallback(getPolicyCertificateIdList, []);
  const { isWtw } = usePlatform();
  // TODO below should be handled in 'labels' file
  const label = isWtw ? 'Certificate number' : 'Policy number';
  const placeholder = `Search and select a ${
    isWtw ? 'certificate' : 'policy'
  } number...`;

  return (
    <Autocomplete<string>
      {...props}
      getOptionValue={(option) => option}
      fetchOptions={(query) => getCertificateIdsOptions({ query })}
      isOptionEqualToValue={(option, value) => option === value}
      inputProps={{
        label,
        placeholder,
        testId: 'policy-certificate-autocomplete',
        ...inputProps,
      }}
    />
  );
};
