import { Banner, Typography } from '@breezeai-frontend/cargo-ui';

import { type ExclusionReason } from '../../../model/Quote';
import { QuoteStatusEnum } from '../../../network/apis/quotes/types';

const ManualProcessBanner = ({
  exclusionReasons,
}: {
  exclusionReasons?: ExclusionReason[];
}) => {
  return (
    <Banner
      state="warning"
      title="This shipment is currently in review and not yet insured. We will notify you as soon as there is an update."
    >
      <div className="flex gap-1 pt-1 flex-wrap">
        <Typography>Exceptions:</Typography>
        {exclusionReasons?.map(({ name, value }, i) => {
          const commaSeparatedValue = `${value}${
            i !== exclusionReasons.length - 1 ? ',' : ''
          }`;
          return (
            <span key={name} className="flex gap-1 flex-wrap">
              <Typography>{name}</Typography>
              <Typography customStyles="text-buttons-secondary-text">
                {commaSeparatedValue}
              </Typography>
            </span>
          );
        })}
      </div>
    </Banner>
  );
};

const UnableToProvideCoverBanner = () => (
  <Banner
    state="error"
    title="Unable to provide cover for this shipment. This can happen if the cargo or journey falls outside of appetite for the insurer. Please feel free to contact us for more information."
  />
);

export function QuoteStatusBanner({
  status,
  exclusionReasons,
}: {
  exclusionReasons?: ExclusionReason[];
  status?: QuoteStatusEnum;
}) {
  switch (status) {
    case QuoteStatusEnum.PENDING_FOR_BREEZE_MANUAL_REVIEW:
      return <ManualProcessBanner exclusionReasons={exclusionReasons} />;
    case QuoteStatusEnum.DECLINED:
      return <UnableToProvideCoverBanner />;
    default:
      return null;
  }
}
