import { type SVGProps } from 'react';

export const ErrorStorm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 525 429"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="No Internet">
      <g id="circle">
        <path
          id="Stroke 1"
          d="M19.6626 197.141V201.553"
          stroke="#D2D8DF"
          strokeWidth="2.921"
          strokeLinecap="round"
        />
        <g id="Group 8">
          <path
            id="Stroke 2"
            d="M9.75659 199.531L13.3615 203.242"
            stroke="#D2D8DF"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            id="Stroke 4"
            d="M5.53894 209.411H10.6362"
            stroke="#D2D8DF"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            id="Stroke 6"
            d="M9.34094 219.465L12.9459 215.754"
            stroke="#D2D8DF"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
        </g>
        <path
          id="Stroke 9"
          d="M19.6626 220.669V216.257"
          stroke="#D2D8DF"
          strokeWidth="2.921"
          strokeLinecap="round"
        />
        <path
          id="Stroke 10"
          d="M27.0147 217.729L24.0736 214.788"
          stroke="#D2D8DF"
          strokeWidth="2.921"
          strokeLinecap="round"
        />
        <path
          id="Stroke 11"
          d="M32.8992 210.375H28.4875"
          stroke="#D2D8DF"
          strokeWidth="2.921"
          strokeLinecap="round"
        />
        <path
          id="Stroke 12"
          d="M29.9583 200.082L27.0172 203.023"
          stroke="#D2D8DF"
          strokeWidth="2.921"
          strokeLinecap="round"
        />
        <path
          id="Stroke 13"
          d="M256.42 4.5V7.44108"
          stroke="#D2D8DF"
          strokeWidth="2.921"
          strokeLinecap="round"
        />
        <path
          id="Stroke 15"
          d="M247.598 13.3232H250.539"
          stroke="#D2D8DF"
          strokeWidth="2.921"
          strokeLinecap="round"
        />
        <path
          id="Stroke 16"
          d="M256.42 22.1469V19.2058"
          stroke="#D2D8DF"
          strokeWidth="2.921"
          strokeLinecap="round"
        />
        <path
          id="Stroke 17"
          d="M263.775 13.3232H260.834"
          stroke="#D2D8DF"
          strokeWidth="2.921"
          strokeLinecap="round"
        />
        <path
          id="Stroke 18"
          d="M85.8373 128.024V132.436"
          stroke="#D2D8DF"
          strokeWidth="2.921"
          strokeLinecap="round"
        />
        <path
          id="Stroke 19"
          d="M72.605 141.26H77.0166"
          stroke="#D2D8DF"
          strokeWidth="2.921"
          strokeLinecap="round"
        />
        <path
          id="Stroke 20"
          d="M85.8373 153.025V148.613"
          stroke="#D2D8DF"
          strokeWidth="2.921"
          strokeLinecap="round"
        />
        <path
          id="Stroke 21"
          d="M97.6035 141.26H93.1919"
          stroke="#D2D8DF"
          strokeWidth="2.921"
          strokeLinecap="round"
        />
        <g id="Group 42">
          <path
            id="Stroke 22"
            d="M402.378 288.373C405.893 288.373 408.743 291.224 408.743 294.739C408.743 298.256 405.893 301.106 402.378 301.106C398.864 301.106 396.014 298.256 396.014 294.739"
            stroke="#D2D8DF"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            id="Stroke 24"
            d="M431.429 372.06C432.215 377.55 428.405 382.637 422.918 383.424C417.43 384.209 412.344 380.398 411.558 374.91C410.771 369.422 414.581 364.334 420.068 363.547"
            stroke="#D2D8DF"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            id="Fill 26"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M431.506 230.617C435.285 229.438 441.078 224.816 442.419 219.844C443.635 224.239 448.732 229.438 453.331 229.831C448.154 231.664 443.204 237.11 442.419 241.388C441.885 237.028 435.038 231.252 431.506 230.617Z"
            fill="#EBECEE"
          />
          <path
            id="Fill 28"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.5927 286.411C45.4828 285.822 48.3779 283.512 49.0483 281.025C49.6563 283.222 52.2043 285.822 54.5039 286.019C51.9159 286.934 49.4415 289.658 49.0483 291.797C48.7811 289.617 45.3579 286.728 43.5927 286.411Z"
            fill="#EBECEE"
          />
          <path
            id="Fill 30"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M78.6697 81.8478C78.6697 84.5751 76.46 86.7841 73.7347 86.7841C71.0081 86.7841 68.7997 84.5751 68.7997 81.8478C68.7997 79.1218 71.0081 76.9115 73.7347 76.9115C76.46 76.9115 78.6697 79.1218 78.6697 81.8478Z"
            fill="#D1D8DF"
          />
          <path
            id="Fill 32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M370.101 391.779C370.101 396.336 366.407 400.03 361.852 400.03C357.296 400.03 353.603 396.336 353.603 391.779C353.603 387.223 357.296 383.529 361.852 383.529C366.407 383.529 370.101 387.223 370.101 391.779Z"
            fill="#EBECEE"
          />
          <path
            id="Fill 34"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M522.461 72.7487C522.461 76.3638 519.532 79.2934 515.919 79.2934C512.305 79.2934 509.376 76.3638 509.376 72.7487C509.376 69.1335 512.305 66.2039 515.919 66.2039C519.532 66.2039 522.461 69.1335 522.461 72.7487Z"
            fill="#EBECEE"
          />
          <path
            id="Stroke 36"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M170.898 378.411C170.898 380.69 169.052 382.537 166.773 382.537C164.496 382.537 162.65 380.69 162.65 378.411C162.65 376.132 164.496 374.285 166.773 374.285C169.052 374.285 170.898 376.132 170.898 378.411Z"
            stroke="#D2D8DF"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            id="Stroke 38"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M168.448 50.1343C168.448 52.8616 166.238 55.0706 163.513 55.0706C160.788 55.0706 158.578 52.8616 158.578 50.1343C158.578 47.4083 160.788 45.198 163.513 45.198C166.238 45.198 168.448 47.4083 168.448 50.1343Z"
            stroke="#D2D8DF"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
          <path
            id="Stroke 40"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.328 424.099C291.174 425.299 287.645 423.714 286.445 420.561C285.246 417.407 286.829 413.875 289.983 412.676C293.136 411.476 296.665 413.06 297.865 416.214C299.066 419.369 297.482 422.899 294.328 424.099Z"
            stroke="#D2D8DF"
            strokeWidth="2.921"
            strokeLinecap="round"
          />
        </g>
      </g>
      <path
        id="Fill 1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M207.888 322.07H236.093L221.318 369.078L287.132 302.595H247.509L269.671 205.218L207.888 322.07Z"
        fill="url(#paint0_linear_4381_13328)"
      />
      <g id="Group 6">
        <path
          id="Fill 2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M376.611 175.669C369.656 170.728 361.537 167.322 352.739 165.963C347.926 123.916 312.232 91.2615 268.896 91.2615C241.892 91.2615 217.854 103.949 202.402 123.688C196.98 121.872 191.192 120.881 185.157 120.881C157.449 120.881 134.6 141.651 131.301 168.469C108.371 175.578 91.7216 196.955 91.7216 222.216C91.7216 253.2 116.765 278.327 147.72 278.473C149.314 278.688 150.934 278.813 152.587 278.813H336.577C339.974 279.449 343.459 279.796 347.039 279.796C378.118 279.796 403.302 254.607 403.302 223.534C403.302 203.308 392.628 185.583 376.611 175.669Z"
          fill="#F1F2F7"
        />
        <path
          id="Fill 4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M220.202 143.8C213.691 141.158 206.445 139.675 198.807 139.675C170.522 139.675 147.601 159.865 147.601 184.765C147.601 187.284 147.841 189.75 148.294 192.151C170.286 161.085 185.439 148.748 220.202 143.8Z"
          fill="#E0E2EE"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4381_13328"
        x1="247.51"
        y1="123.287"
        x2="119.062"
        y2="185.406"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8960" />
        <stop offset="1" stopColor="#FF62A5" />
      </linearGradient>
    </defs>
  </svg>
);
