import { Suspense, useState } from 'react';
import { SectionLayout, Spinner } from '@breezeai-frontend/cargo-ui';

import { useUser } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { ActiveOpenCoverTooltip } from '../../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { CertificateFlowForm } from './CertificateFlowForm/CertificateFlowForm';
import { useFormLogic } from './useFormLogic';

const FormPlaceholder = () => {
  return (
    <div className="flex w-full h-full bg-white rounded-3xl p-3">
      <Spinner className="self-center" />
    </div>
  );
};

export function CertificateFlowPage() {
  const { isUpdate } = useFormLogic();
  const { isWtw } = usePlatform();
  const user = useUser();

  const { certificateId } = useFormLogic();
  const [formKey, setFormKey] = useState(0);

  const title =
    isUpdate && certificateId
      ? `Certificate ${certificateId}`
      : 'Get a Certificate';

  const resetForm = () => {
    setFormKey((prev) => prev + 1);
  };
  const isAuthorized = Boolean(
    user?.permissions.certificates?.create ||
      user?.permissions.certificates?.edit_issued,
  );

  return (
    <SectionLayout
      isAuthorized={isAuthorized}
      title={title}
      backLink="/certificates"
      tooltip={
        isWtw
          ? {
              trigger: <ActiveOpenCoverTooltip.Trigger />,
              content: <ActiveOpenCoverTooltip.Content />,
            }
          : undefined
      }
    >
      <Suspense fallback={<FormPlaceholder />}>
        <CertificateFlowForm
          key={formKey}
          // Remount the form when the key changes to reset the form
          remountForm={resetForm}
        />
      </Suspense>
    </SectionLayout>
  );
}
