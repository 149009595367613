import axios from 'axios';

import { fetchIdToken } from './auth/auth';

export type NetworkRequestOptions = {
  auth?: boolean;
};

export interface NetworkResponse<T> {
  data: T;
  status: number;
  statusText: string;
}

interface RequestConfiguration {
  headers?: {
    Authorization?: string;
  };
}

export const createRequestConfig = async ({
  auth = true,
}: NetworkRequestOptions = {}): Promise<RequestConfiguration> => {
  if (!auth) {
    return {};
  }

  const token = await fetchIdToken();

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const post = async <T>(
  url: string,
  payload?: unknown,
  options?: NetworkRequestOptions,
): Promise<NetworkResponse<T>> => {
  const config = await createRequestConfig(options);
  return await axios.post<T>(url, payload, config);
};

export const put = async <T>(
  url: string,
  payload?: unknown,
  options?: NetworkRequestOptions,
): Promise<NetworkResponse<T>> => {
  const config = await createRequestConfig(options);

  return await axios.put<T>(url, payload, config);
};

export const get = async <T>(
  url: string,
  payload?: unknown,
  options?: NetworkRequestOptions,
): Promise<T> => {
  const config = await createRequestConfig(options);

  return axios
    .get<T>(url, {
      ...config,
      params: payload,
    })
    .then((res) => res.data);
};
