import { RadioGroup, RadioOption } from '@breeze-ai/ui-library';

import { type CoveragePackageType } from '../../../model/Quote';
import { COVERAGE_PACKAGES } from '../../constants';
import styles from './CoverageTypeSelector.module.scss';

type CoverageTypeSelectorProps = {
  selected: CoveragePackageType;
  onChange: (coverageType: CoveragePackageType) => void;
  options: CoveragePackageType[];
};

export const CoverageTypeSelector = ({
  selected,
  onChange,
  options,
}: CoverageTypeSelectorProps) => {
  return (
    <RadioGroup
      selected={selected}
      onChange={(option) => onChange(option as CoveragePackageType)}
      name="coverage-type-radio-group"
      outlined
      className={styles.coverageTypeSelector}
      data-testid="coverage-type-selector"
    >
      {options.map((coverageType, i) => {
        const { label, description } = COVERAGE_PACKAGES[coverageType];

        return (
          <RadioOption
            key={i}
            value={coverageType}
            label={label}
            description={description}
            data-testid={`select-option-${coverageType}`}
          />
        );
      })}
    </RadioGroup>
  );
};
