import { FaExclamationTriangle } from 'react-icons/fa';

import { tv } from '../../utils/customTv';
import { clsxMerge } from '../common/utils/classNameUtils';
import { Typography } from '../Typography/Typography';
export const banner = tv({
  slots: {
    container: `rounded-radius-suface-2 p-4 w-full flex items-center gap-4`,
    icon: `text-system-orange-500 size-6 shrink-0`,
  },
  variants: {
    state: {
      warning: {
        container: `bg-system-orange-50`,
        icon: `color-system-orange-500`,
      },
      error: {
        container: `bg-system-red-50`,
        icon: `text-system-red-500`,
      },
    },
  },
});

interface BannerProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  subtitle?: string;
  state: 'warning' | 'error';
}

export function Banner({
  children,
  title,
  subtitle,
  state,
  ...props
}: BannerProps) {
  const { container, icon } = banner({ state });

  return (
    <div {...props} className={clsxMerge(container(), props.className)}>
      <FaExclamationTriangle className={icon()} />
      <div>
        <div className="space-y-1">
          {title && <Typography level="h4">{title}</Typography>}
          {subtitle && <Typography>{subtitle}</Typography>}
        </div>
        {children}
      </div>
    </div>
  );
}
