import { useSuspenseQuery } from '@tanstack/react-query';

import { DetailsPageClaimsSection } from '../../../components/PolicyClaims/PolicyClaims';
import { policiesQueries } from '../../network/queries';

export function PolicyClaims({ policyId }: { policyId?: string }) {
  const { data: policy } = useSuspenseQuery(
    policiesQueries.details({ policyId }),
  );

  const isAuthorizedToViewClaims = Boolean(policy?.claim_permissions?.view);
  const isAuthorizedToCreateClaims = Boolean(policy?.claim_permissions?.create);

  // If the user is not authorized to view claims, we don't need to fetch them.
  // This query should be inside the DetailsPageClaimsSection component
  const { data: claims } = useSuspenseQuery(
    policiesQueries.policyClaims({
      policyId: isAuthorizedToViewClaims ? policyId : undefined,
    }),
  );

  return (
    // TODO certificate should always be defined if using suspense query - workaround is to accommodate other components that uses  policiesQueries.details
    policy &&
    isAuthorizedToViewClaims && (
      <DetailsPageClaimsSection
        certificateId={policy.certificate_id}
        claims={claims ?? []}
        isAuthorizedToCreateClaims={isAuthorizedToCreateClaims}
      />
    )
  );
}
