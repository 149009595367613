import { LinkButton, SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useUser } from '../../../context/auth/auth-hooks';
import { useTracking } from '../../../utils/snowplow/hooks';
import { StorageCreationModal } from '../StorageCreationModal/StorageCreationModal';
import { StorageTable } from './StorageTable/StorageTable';

export const StoragePage = () => {
  useTracking();
  const user = useUser();
  const isAuthorized = Boolean(user?.permissions.storage_locations?.view);
  return (
    <SectionLayout
      isAuthorized={isAuthorized}
      title="Locations"
      data-testid="storage-page"
      actions={
        <LinkButton
          size="small"
          data-testid="add-location-button"
          href="/storage/create/details"
          width="auto"
          label="Add New Location"
        />
      }
    >
      <StorageTable />
      <StorageCreationModal />
    </SectionLayout>
  );
};
