import { queryOptions } from '@tanstack/react-query';
import { stringify } from 'query-string';

import { type PaginationBaseQueryParams } from '../../../network/apis/types.ts';
import { get, post } from '../../../network/apis/utils.ts';
import { bffServiceUrl } from '../../../network/netconfig.ts';
import {
  type StorageLocationAddress,
  type StorageLocationConfiguration,
  type StorageLocationModel,
} from '../types.ts';

export const storageLocationQueries = {
  configuration: ({ distributorId }: { distributorId?: number }) =>
    queryOptions({
      queryKey: ['storage-location-configuration', distributorId],
      // TODO: components using this set of options for suspense need to be refactored
      // as ternary/null pattern is not good
      // Reference: https://github.com/TanStack/query/discussions/6206
      queryFn: () =>
        distributorId
          ? get<StorageLocationConfiguration>(
              `${bffServiceUrl}/bff-storage-locations/${distributorId}/configuration`,
            )
          : null,
      enabled: !!distributorId,
    }),

  details: ({ policyId }: { policyId?: string }) =>
    queryOptions({
      enabled: !!policyId,
      queryKey: ['storage-location', policyId],
      // TODO: components using this set of options for suspense need to be refactored
      // as ternary/null pattern is not good
      // Reference: https://github.com/TanStack/query/discussions/6206
      queryFn: () =>
        policyId
          ? get<StorageLocationModel>(
              `${bffServiceUrl}/bff-storage-locations/${policyId}`,
            )
          : null,
    }),
  addresses: ({
    distributorId,
    params,
  }: {
    distributorId?: number;
    params?: PaginationBaseQueryParams;
  }) => {
    const queryParams = params ? `?${stringify(params)}` : '';

    return queryOptions({
      queryKey: ['storage-location-addresses', queryParams],
      // TODO: components using this set of options for suspense need to be refactored
      // as ternary/null pattern is not good
      // Reference: https://github.com/TanStack/query/discussions/6206
      queryFn: () =>
        distributorId
          ? post<{ addresses: StorageLocationAddress[] }>(
              `${bffServiceUrl}/bff-storage-locations/get-addresses`,
              {
                ...(params || {}),
                distributorId,
              },
            )
          : null,
      enabled: !!distributorId,
      select: (data) => data && data.data.addresses,
    });
  },
};
