import { FaWarehouse } from 'react-icons/fa';
import { FaHouseFloodWater } from 'react-icons/fa6';
import { PiFarm } from 'react-icons/pi';
import { useNavigate, useParams } from 'react-router-dom';
import { Toggle } from '@breeze-ai/ui-library';
import {
  Button,
  clsxMerge,
  formatPrice,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@breezeai-frontend/cargo-ui';
import { useForm } from '@tanstack/react-form';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { zodValidator } from '@tanstack/zod-form-adapter';
import capitalize from 'lodash/capitalize';
import words from 'lodash/words';
import { z } from 'zod';

import { useUser, useUserSetting } from '../../../../context/auth/auth-hooks';
import { type SupportedCurrencies } from '../../../../model/CurrencyValue';
import { queryClient } from '../../../../providers/ReactQueryProvider';
import { PlacesComboBox } from '../../../components/PlacesComboBox/PlacesComboBox';
import { useCreateStorageLocation } from '../../hooks/useCreateStorageLocation';
import { storageLocationQueries } from '../../network/queries';
import {
  type CreateStorageLocationPayload,
  type StorageCreationModalRouteParams,
  StorageLocationTypeEnum,
} from '../../types';

const storageDetailsFormSchema = z.object({
  name: z.string().min(1),
  address: z.object({
    place_id: z.string().min(1),
    description: z.string().min(1),
    session_token: z.optional(z.string().min(1)),
  }),
  type: z.nativeEnum(StorageLocationTypeEnum),
  isOwnedByOthers: z.boolean(),
  currency: z.string().min(1),
  activeTiv: z.string().min(1),
  notes: z.string().min(1),
});

type StorageDetailsFormProps = z.infer<typeof storageDetailsFormSchema>;

export function StorageInputsForm({ isUpdate }: { isUpdate: boolean }) {
  const defaultCurrency =
    useUserSetting<SupportedCurrencies>('default_currency');
  const { policyId } = useParams<StorageCreationModalRouteParams>();
  const currentUser = useUser();

  const {
    data: storageLocationConfiguration,
    isLoading: isFetchingStorageLocationConfiguration,
  } = useQuery(
    storageLocationQueries.configuration({
      distributorId: currentUser?.distributor.id,
    }),
  );

  const { data } = useSuspenseQuery(
    storageLocationQueries.details({
      policyId,
    }),
  );

  const { mutate, isPending: isMutating } = useCreateStorageLocation({
    policyId,
  });
  const navigate = useNavigate();

  const form = useForm<StorageDetailsFormProps, typeof zodValidator>({
    validatorAdapter: zodValidator,
    defaultValues: {
      name: data?.location_name ?? '',
      address: {
        place_id: data?.address.provider_place_uuid ?? '',
        description: data?.address.full_address ?? '',
      },
      type: data?.type ?? StorageLocationTypeEnum.WAREHOUSE,
      isOwnedByOthers: data?.owned_or_operated_by_others ?? false,
      currency:
        data?.active_total_insured_value_currency?.code ?? defaultCurrency,
      activeTiv: String(data?.active_total_insured_value ?? ''),
      notes: data?.notes ?? '',
    },
    onSubmit: async ({ formApi, value }) => {
      const createPayload: CreateStorageLocationPayload = {
        location_name: value.name,
        location_type: value.type,
        location_address_provider_details: {
          place_id: value.address.place_id,
          session_token: value.address.session_token,
        },
        owned_or_operated_by_others: value.isOwnedByOthers,
        insured_value_limit_currency: value.currency,
        active_total_insured_value: Number(value.activeTiv),
        active_total_insured_value_currency: value.currency,
        notes: value.notes,
      };
      const updatePayload = {
        location_name: formApi.state.fieldMeta['name'].isDirty
          ? value.name
          : undefined,
        location_type: formApi.state.fieldMeta['type'].isDirty
          ? value.type
          : undefined,
        location_address_provider_details: formApi.state.fieldMeta['address']
          .isDirty
          ? {
              place_id: value.address.place_id,
              session_token: value.address.session_token,
            }
          : undefined,
        owned_or_operated_by_others: formApi.state.fieldMeta['isOwnedByOthers']
          .isDirty
          ? value.isOwnedByOthers
          : undefined,
        active_total_insured_value: formApi.state.fieldMeta['activeTiv'].isDirty
          ? Number(value.activeTiv)
          : undefined,
        notes: value.notes !== data?.notes ? value.notes : undefined,
      };

      const payload = isUpdate ? updatePayload : createPayload;

      mutate(payload, {
        onError: (error) => {
          // These validation errors are handled by the form but we can still handle if error response is received
          const { error_type, message } = error.response.data;
          switch (error_type) {
            case 'StorageLocationAddressNotAllowed': {
              const errorMessage =
                'This country is sanctioned. Please change your selection';
              form.setFieldMeta('address', {
                errorMap: {
                  onSubmit: errorMessage,
                },
                errors: [errorMessage],
                isDirty: true,
                isTouched: true,
                isPristine: false,
                isValidating: false,
                touchedErrors: [errorMessage],
              });
              break;
            }
            case 'StorageLocationInsuredValueException': {
              form.setFieldMeta('activeTiv', {
                errorMap: {
                  onSubmit: message,
                },
                errors: [message],
                isDirty: true,
                isTouched: true,
                isPristine: false,
                isValidating: false,
                touchedErrors: [message],
              });
              break;
            }
          }
        },
        onSuccess: ({ data }) => {
          if (isUpdate) {
            queryClient.setQueryData(
              ['storageLocation', String(data.id)],
              data,
            );
            navigate(`/storage/details/${data.location_id}/${data.id}`, {
              replace: true,
            });
          } else {
            navigate(`/storage/create/files/${data.location_id}/${data.id}`, {
              replace: true,
            });
          }
        },
      });
    },
  });

  return (
    <div className="h-full">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
        className="space-y-6"
      >
        {/* Details */}
        <div className="flex flex-col gap-y-3">
          <Typography level="h5">Details</Typography>
          <div className="flex flex-col gap-y-5">
            <div className="flex flex-row gap-x-6 ">
              <form.Field
                name="name"
                validators={{
                  onSubmit: z.string().min(1, 'Please enter a location name'),
                }}
                children={(field) => (
                  <TextField
                    isRequired
                    label="Location Name"
                    placeholder="Enter location name"
                    data-testid="location-name"
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={field.handleChange}
                    isInvalid={field.state.meta.errors?.length > 0}
                    errorMessage={field.state.meta.errors.join(', ')}
                  />
                )}
              />
              <form.Field
                name="address"
                validators={{
                  onBlur: ({ value }) => {
                    if (
                      // This is a check to ensure that manual location data migrated to the db are not validated
                      !value?.place_id &&
                      (data?.address?.provider === 'google' ||
                        !value?.description)
                    ) {
                      return 'Please select a location';
                    }
                    return;
                  },
                }}
                children={(field) => {
                  return (
                    <PlacesComboBox
                      isRequired
                      placeholder="Enter location address"
                      label="Location Address"
                      data-testid="storage-location-address-combobox"
                      id={field.name}
                      name={field.name}
                      selectedKey={JSON.stringify(field.state.value)}
                      onInputChange={(value) => {
                        field.handleChange({
                          ...field.state.value,
                          place_id: '',
                          description: value,
                        });
                      }}
                      inputValue={field.state.value?.description}
                      onBlur={() => {
                        if (field.state.value?.place_id.length === 0) {
                          field.handleChange({
                            ...field.state.value,
                            place_id: '',
                            description: '',
                          });
                        }
                        field.handleBlur();
                      }}
                      isInvalid={field.state.meta.errors?.length > 0}
                      errorMessage={field.state.meta.errors.join(', ')}
                      onSelectionChange={(option) => {
                        const parsedOption = JSON.parse(String(option));
                        const isFieldUpdated =
                          parsedOption.place_id !==
                          field.prevState.value?.place_id;
                        isFieldUpdated && field.handleChange(parsedOption);
                      }}
                    />
                  );
                }}
              />
            </div>
            <form.Field
              name="type"
              children={(field) => (
                <RadioGroup
                  isRequired
                  label="Location Type"
                  id={field.name}
                  data-testid="location-type-radio-group"
                  name={field.name}
                  defaultValue={field.state.value}
                  onChange={(value) =>
                    field.handleChange(value as StorageLocationTypeEnum)
                  }
                  orientation="horizontal"
                >
                  {[
                    {
                      name: StorageLocationTypeEnum.WAREHOUSE,
                      icon: <FaWarehouse className="size-4" />,
                    },
                    {
                      name: StorageLocationTypeEnum.FLOATING,
                      icon: <FaHouseFloodWater className="size-4" />,
                    },
                    {
                      name: StorageLocationTypeEnum.OPEN_YARD,
                      icon: <PiFarm className="size-4" />,
                    },
                  ].map(({ name, icon }) => (
                    <div
                      key={name}
                      className="bg-white px-4 py-3 border-1 border-field-border-default rounded-xl w-52 flex justify-start items-center gap-2"
                    >
                      <Radio value={name} data-testid={name}>
                        <Typography>
                          {words(name.replace('_', ' '))
                            .map(capitalize)
                            .join(' ')}
                        </Typography>
                      </Radio>
                      <div
                        className={clsxMerge(
                          field.state.value === name &&
                            'text-buttons-primary-bg-default',
                        )}
                      >
                        {icon}
                      </div>
                    </div>
                  ))}
                </RadioGroup>
              )}
            />
            <form.Field
              name="isOwnedByOthers"
              children={(field) => (
                <Toggle
                  label="Warehouse owned/operated by others"
                  id={field.name}
                  name={field.name}
                  value={field.state.value}
                  onChange={field.handleChange}
                />
              )}
            />
          </div>
        </div>

        {/* Capacity */}
        <div className="flex flex-col gap-y-3">
          <span className="flex flex-row gap-0.5">
            <Typography level="h5">Capacity</Typography>
            <Typography level="base" color="tertiary">
              {`(optional)`}
            </Typography>
          </span>
          <Typography level="subtext" color="secondary">
            Policy IV limit is{' '}
            {storageLocationConfiguration &&
              formatPrice({
                currency: storageLocationConfiguration.default_currency.code,
                value:
                  storageLocationConfiguration.policy_insured_value_limit ?? 0,
              })}
            {isFetchingStorageLocationConfiguration && 'loading...'}
          </Typography>
          <div className="flex flex-row gap-x-6">
            <div className="flex flex-col gap-1">
              <Typography level="subtext">Currency</Typography>
              <div className="h-9 w-14 px-3 py-2 text-text-text-disabled border border-field-border-disabled rounded-[4px]">
                {storageLocationConfiguration?.default_currency.symbol}
              </div>
            </div>

            <form.Field
              name="activeTiv"
              children={(field) => (
                <TextField
                  label="Estimated TIV on risk"
                  data-testid="estimated-tiv"
                  placeholder="0.00"
                  id={field.name}
                  name={field.name}
                  value={field.state.value}
                  onBlur={field.handleBlur}
                  onChange={field.handleChange}
                  isInvalid={field.state.meta.errors?.length > 0}
                  errorMessage={field.state.meta.errors.join(', ')}
                />
              )}
            />
          </div>
        </div>

        {/* Notes */}
        <div className="flex flex-col gap-y-3">
          <Typography level="h5">Notes</Typography>
          <form.Field
            name="notes"
            children={(field) => (
              <TextField
                inputStyles="min-h-20 resize-none"
                variant="area"
                aria-label="Notes"
                placeholder="Enter notes"
                id={field.name}
                name={field.name}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={field.handleChange}
              />
            )}
          />
        </div>

        {/* Submission Buttons */}
        <form.Subscribe
          selector={(state) => [state.isDirty]}
          children={([isDirty]) => (
            <div className="flex gap-4 w-full justify-end">
              <Button
                variant="ghost"
                size="large"
                type="reset"
                width="auto"
                label={isUpdate ? 'Cancel Changes' : 'Cancel'}
                isDisabled={!isDirty || isMutating}
              />
              {isUpdate ? (
                <Button
                  data-testid="next-button"
                  size="large"
                  width="auto"
                  type="submit"
                  loadingText="Updating Storage Location..."
                  label="Save Changes"
                  isLoading={isMutating}
                  isDisabled={!isDirty}
                />
              ) : (
                <Button
                  data-testid="next-button"
                  size="large"
                  width="auto"
                  type="submit"
                  loadingText="Next"
                  label="Next"
                  isLoading={isMutating}
                />
              )}
            </div>
          )}
        />
      </form>
    </div>
  );
}
