import {
  DatePicker,
  FormLayout,
  FormRow,
  Input,
  Select,
  type SelectOptionType,
} from '@breeze-ai/ui-library';

import { useAppConfiguration } from '../../../../context/app-configuration/useAppConfiguration';
import { useUser } from '../../../../context/auth/auth-hooks';
import { type ClaimEventLocationType } from '../../../../model/Claim';
import { CertificateIdAutocomplete } from '../../../components/CertificateIdAutocomplete/CertificateIdAutocomplete';
import { CurrencyValueInput } from '../../../components/CurrencyValueInput/CurrencyValueInput';
import { PlaceAutocomplete } from '../../../components/PlaceAutocomplete/PlaceAutocomplete';
import {
  useClaimCreationFlowContext,
  useClaimCreationStateAction,
} from '../context/hooks';
import { useClaimFormInputValidation } from '../form-validations';

export const ClaimDetailsForm = () => {
  const { currencies, claim_location_types } = useAppConfiguration();
  const { formsData, formErrors } = useClaimCreationFlowContext();
  const { setFormData, setFormError } = useClaimCreationStateAction();
  const validateInput = useClaimFormInputValidation();
  const user = useUser();
  const isCertificatesEnabled = Boolean(user?.permissions.certificates?.view);

  const {
    certificateId,
    eventDate,
    eventAddress,
    eventDescription,
    currentCargoPlace,
    eventLocationType,
    reportedAmount,
    reportedAmountCurrency,
  } = formsData;

  const claimLocationOptions = claim_location_types.map<
    SelectOptionType<ClaimEventLocationType>
  >(({ label, value }) => ({
    label,
    value,
    payload: value,
  }));

  return (
    <FormLayout>
      <FormRow gutter={20}>
        <CertificateIdAutocomplete
          value={certificateId}
          onChange={(certificateId) => {
            validateInput('certificateId', certificateId);
            setFormData({ certificateId });
          }}
          required={true}
          inputProps={{
            placeholder: `Search and select a ${
              isCertificatesEnabled ? 'certificate' : 'policy'
            } number...`,
            label: isCertificatesEnabled
              ? 'Certificate number'
              : 'Policy number',
            error: !!formErrors.certificateId,
            errorHelperText: formErrors.certificateId?.reason,
            validator: (value) => validateInput('certificateId', value),
            testId: 'certificate-id-input',
          }}
        />
        <DatePicker
          value={eventDate}
          onChange={(date) => setFormData({ eventDate: date ?? undefined })}
          position="bottom-end"
          required={true}
          disableFuture={true}
          onValidation={(error, reason) =>
            setFormError({ field: 'eventDate', error, reason })
          }
          inputProps={{
            label: 'Event date',
            error: !!formErrors?.eventDate,
            errorHelperText: formErrors?.eventDate?.reason,
            testId: 'event-date-picker',
          }}
        />
        <Select<ClaimEventLocationType>
          value={eventLocationType}
          onChange={({ payload }) =>
            setFormData({ eventLocationType: payload })
          }
          options={claimLocationOptions}
          label="Location type"
          placeholder="Select..."
          testId="event-location-type-selector"
        />
      </FormRow>
      <FormRow gutter={20}>
        <PlaceAutocomplete
          value={eventAddress}
          onChange={(eventAddress) => setFormData({ eventAddress })}
          inputProps={{
            label: 'Event address',
            placeholder: 'Where did it happen?',
            prefixIcon: 'map-marker-alt',
            required: false,
            testId: 'event-address-input',
          }}
        />
        <PlaceAutocomplete
          value={currentCargoPlace}
          onChange={(currentCargoPlace) => setFormData({ currentCargoPlace })}
          inputProps={{
            label: 'Current location of goods',
            placeholder: 'Where are the goods currently?',
            prefixIcon: 'map-marker-alt',
            required: false,
            testId: 'cargo-location-input',
          }}
        />
        <CurrencyValueInput
          label="Loss amount"
          value={reportedAmount}
          currency={reportedAmountCurrency}
          currencyOptions={currencies}
          onChange={(reportedAmount) => setFormData({ reportedAmount })}
          onCurrencyChange={(reportedAmountCurrency) =>
            setFormData({ reportedAmountCurrency })
          }
          error={!!formErrors.reportedAmount}
          errorHelperText={formErrors.reportedAmount?.reason}
          validator={(value) => validateInput('reportedAmount', value)}
          testId="reported-amount-input"
          inputDropdownProps={{ testId: 'reported-amount-currency-input' }}
        />
      </FormRow>
      <FormRow gutter={20}>
        <Input
          label="Event description"
          type="textarea"
          placeholder="Tell us what happened"
          value={eventDescription}
          required={true}
          validator={(value) => validateInput('eventDescription', value)}
          error={!!formErrors.eventDescription}
          errorHelperText={formErrors.eventDescription?.reason}
          onChange={({ target: { value: eventDescription } }) =>
            setFormData({ eventDescription })
          }
          testId="event-description-input"
        />
      </FormRow>
    </FormLayout>
  );
};
