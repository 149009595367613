import { queryOptions, useQuery } from '@tanstack/react-query';

import { type OpenCover as GetDistributorActiveOpenCoverResponse } from '../../../model/OpenCover';
import { bffServiceUrl } from '../../netconfig';
import { get } from '../utils';

export const distributorQueries = {
  activeOpenCover: ({ distributorId }: { distributorId: number }) =>
    queryOptions({
      queryKey: ['distributorActiveOpenCover', distributorId],
      queryFn: () =>
        get<GetDistributorActiveOpenCoverResponse>(
          `${bffServiceUrl}/bff-distributors/${distributorId}/active-open-cover`,
        ),
    }),
};

// TODO: replace usages of this hook with the query above
export const useDistributorActiveOpenCover = ({
  distributorId,
}: {
  distributorId: number;
}) => {
  return useQuery({
    queryKey: ['distributorActiveOpenCover', distributorId],

    queryFn: () =>
      get<GetDistributorActiveOpenCoverResponse>(
        `${bffServiceUrl}/bff-distributors/${distributorId}/active-open-cover`,
      ),
  });
};
