import { useState } from 'react';
import { FaRedo } from 'react-icons/fa';
import { PiArchiveFill } from 'react-icons/pi';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ActionConfirmationModal,
  Button,
  LinkButton,
} from '@breezeai-frontend/cargo-ui';
import { useQueryClient } from '@tanstack/react-query';

import { useCreateStorageLocation } from '../hooks/useCreateStorageLocation';
import {
  type StorageLocationModel,
  StorageLocationStatusEnum,
  type StorageRouteParams,
} from '../types';

export function StorageDetailsActions({
  location_name,
  status,
}: {
  location_name: StorageLocationModel['location_name'];
  status: StorageLocationModel['status'];
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const { policyId, storageLocationId } = useParams<StorageRouteParams>();
  const { mutate, isPending } = useCreateStorageLocation({
    policyId,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['storage-location', policyId],
        });
        navigate('/storage');
      },
    },
  });

  // TODO:
  return (
    <div className="flex flex-row sm:items-center sm:justify-between gap-2">
      <div className="flex sm:flex-row flex-col items-center gap-2">
        <LinkButton
          variant="ghost"
          label="Edit"
          width="auto"
          customStyles="border border-buttons-primary-bg-default"
          href={`/storage/update/details/${storageLocationId}/${policyId}`}
          isDisabled={
            isPending || status === StorageLocationStatusEnum.ARCHIVED
          }
        />
        <Button
          variant="ghost"
          label={
            status === StorageLocationStatusEnum.ARCHIVED
              ? 'Restore'
              : 'Archive'
          }
          isLoading={isPending}
          data-testid={
            status === StorageLocationStatusEnum.ARCHIVED
              ? 'restore-button'
              : 'archive-button'
          }
          loadingText={
            status === StorageLocationStatusEnum.ARCHIVED
              ? 'Restoring'
              : 'Archiving'
          }
          leftIcon={
            status === StorageLocationStatusEnum.ARCHIVED ? (
              <FaRedo />
            ) : (
              <PiArchiveFill size={16} />
            )
          }
          width="auto"
          customStyles="border border-buttons-primary-bg-default"
          onPress={() => setShowAlertModal(true)}
        />
      </div>
      <ActionConfirmationModal
        title={`Are you sure you would like to ${
          status === StorageLocationStatusEnum.ARCHIVED ? 'restore' : 'archive'
        } ${location_name}?`}
        description={`You will still be able to ${
          status === StorageLocationStatusEnum.ARCHIVED ? 'restore' : 'archive'
        } this location at any time`}
        heading={
          <div className="mb-3 flex p-2.5 items-center justify-center rounded-full border border-field-border-default self-start">
            {status === StorageLocationStatusEnum.ARCHIVED ? (
              <FaRedo />
            ) : (
              <PiArchiveFill size={16} />
            )}
          </div>
        }
        onOpenChange={(value: boolean) => setShowAlertModal(value)}
        isOpen={showAlertModal}
        onConfirmChanges={() =>
          mutate({
            status:
              status === StorageLocationStatusEnum.ARCHIVED
                ? StorageLocationStatusEnum.ACTIVE
                : StorageLocationStatusEnum.ARCHIVED,
          })
        }
      />
    </div>
  );
}
