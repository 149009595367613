import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

import { type ErrorResponse } from '../../network/apis/types';
import { post, put } from '../../network/apis/utils';
import { type NetworkResponse } from '../../network/AuthRequests';
import { bffServiceUrl } from '../../network/netconfig';
import {
  type CertificateCreationPayload,
  type CertificateModel,
  type CertificateUpdatePayload,
} from './types';

export const useCreateCertificate = ({
  isUpdate = false,
  options,
}: {
  isUpdate: boolean;
  options?: UseMutationOptions<
    NetworkResponse<CertificateModel>,
    ErrorResponse,
    CertificateCreationPayload | CertificateUpdatePayload
  >;
}) => {
  return useMutation({
    mutationFn: (payload) =>
      isUpdate
        ? put(`${bffServiceUrl}/bff-certificate-only/certificate-only`, payload)
        : post(
            `${bffServiceUrl}/bff-certificate-only/certificate-only`,
            payload,
          ),
    ...options,
  });
};
