import { FaDownload } from 'react-icons/fa6';
import {
  Button,
  SectionLayout,
  toastQueue,
  Tooltip,
  TooltipTriggerWrapper,
} from '@breezeai-frontend/cargo-ui';
import capitalize from 'lodash/capitalize';

import { useUser } from '../../context/auth/auth-hooks';
import { usePlatform } from '../../context/PlatformContext';
import { useTracking } from '../../utils/snowplow/hooks';
import { ActiveOpenCoverTooltip } from '../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import labels from '../labels';
import { GraphWidget } from './components/GraphWidget/GraphWidget';
import { MetricWidget } from './components/MetricWidget/MetricWidget';
import {
  type YearSelectionPayload,
  YearSelector,
} from './components/YearSelector/YearSelector';
import {
  useClaimsInsights,
  useGenerateReportMutation,
  usePoliciesInsights,
} from './hooks';
import {
  InsightsPageContextProvider,
  useInsightsPageContext,
  useInsightsPageContextAction,
} from './InsightsPageContext';

const InsightsPageContent = () => {
  useTracking({
    eventFeature: 'insights',
    eventAction: 'page_view',
    eventTrigger: 'landing_page',
  });

  const dispatch = useInsightsPageContextAction();
  const { isWtw } = usePlatform();

  const onYearSelection = ({ startDate, endDate }: YearSelectionPayload) => {
    dispatch({ type: 'set-dates', payload: { startDate, endDate } });
  };
  const { startDate, endDate, currencyCode } = useInsightsPageContext();

  const { data: claimsInsights } = useClaimsInsights({
    params: {
      period_start: startDate,
      period_end: endDate,
      currency_code: currencyCode,
    },
  });
  const { data } = usePoliciesInsights({
    params: {
      period_start: startDate,
      period_end: endDate,
      currency_code: currencyCode,
    },
  });

  const user = useUser();
  const isDownloadReportButtonVisible = Boolean(
    user?.permissions.insights?.download_report,
  );
  const isAuthorizedToViewClaims = Boolean(user?.permissions.claims?.view);
  const isAuthorizedToViewQuotePremiums = Boolean(
    user?.permissions.quotes?.show_premiums,
  );

  const policyInsights = data?.policies;
  const premiumsInsights = data?.premiums;

  const generateReportMutation = useGenerateReportMutation({
    options: {
      onError: () => {
        toastQueue.add(
          {
            level: 'error',
            title: 'Error generating report',
            description: 'Please try again later',
          },
          {
            timeout: 10000,
          },
        );
      },
      onSuccess: () => {
        toastQueue.add(
          {
            level: 'info',
            title: 'Report Generated',
            description: 'The report will be sent to your email',
          },
          {
            timeout: 10000,
          },
        );
      },
    },
  });

  const metricWidgets = [
    {
      title: capitalize(labels.terms.policy.plural),
      loading: !policyInsights,
      metricItems: policyInsights
        ? [
            {
              value: policyInsights.total,
              title: `${capitalize(labels.terms.policy.plural)}`,
              testId: `total-${labels.terms.policy.plural}`,
            },
            {
              value: policyInsights.total_insured.value,
              currency: policyInsights.total_insured.currency_code,
              title: 'Total Cargo Value Insured',
              testId: 'total-cargo-value-insured',
            },
          ]
        : [],
    },
    {
      title: 'Premiums',
      loading: !premiumsInsights,
      isEnabled: isAuthorizedToViewQuotePremiums,
      metricItems: premiumsInsights
        ? [
            {
              value: premiumsInsights.total.value,
              currency: premiumsInsights.total.currency_code,
              title: 'Total Premiums',
              testId: 'total-premiums',
            },
            {
              value: premiumsInsights.average.value,
              currency: premiumsInsights.total.currency_code,
              title: `Average Premium Per ${capitalize(
                labels.terms.policy.singular,
              )}`,
              testId: 'average-premium',
            },
          ]
        : [],
    },
    {
      title: 'Claims',
      loading: !claimsInsights,
      isEnabled: isAuthorizedToViewClaims,
      metricItems: claimsInsights
        ? [
            {
              title: 'Claims Raised',
              testId: 'total-claims',
              value: claimsInsights.total,
            },
            {
              title: 'Total Paid Amount',
              testId: 'total-claims-paid',
              currency: claimsInsights.total_paid.currency_code,
              value: claimsInsights.total_paid.value,
            },
          ]
        : [],
    },
  ];

  const isAuthorized = Boolean(user?.permissions.insights?.view);

  return (
    <SectionLayout
      isAuthorized={isAuthorized}
      data-testid="insights-page"
      title="Insured Portfolio"
      tooltip={
        isWtw
          ? {
              trigger: <ActiveOpenCoverTooltip.Trigger />,
              content: <ActiveOpenCoverTooltip.Content />,
            }
          : undefined
      }
      actions={
        <div className="flex gap-4 items-center">
          <YearSelector
            maxPastYear={3}
            earliestPastYear={2021}
            onChange={onYearSelection}
          />
          {isDownloadReportButtonVisible && (
            <TooltipTriggerWrapper delay={0} closeDelay={0}>
              <Button
                onPress={() => {
                  generateReportMutation.mutate({
                    startDate,
                    endDate,
                    currencyCode,
                  });
                }}
                isDisabled={generateReportMutation.isPending}
                data-testid="generate-report-button"
                variant="ghost"
              >
                <FaDownload className="text-icons-primary" size={14} />
              </Button>
              <Tooltip placement="bottom">Download report</Tooltip>
            </TooltipTriggerWrapper>
          )}
        </div>
      }
    >
      <div className="flex w-full h-full flex-col gap-y-4">
        <div className="flex flex-row w-full space-x-4 justify-between">
          {metricWidgets.map((widget) => (
            <MetricWidget {...widget} key={widget.title} />
          ))}
        </div>

        <GraphWidget />
      </div>
    </SectionLayout>
  );
};

export const InsightsPage = () => (
  <InsightsPageContextProvider>
    <InsightsPageContent />
  </InsightsPageContextProvider>
);
