import { type Quote } from '../../../model/Quote';
import { normalizePlaceResponse } from '../places/normalizers';
import { type EnrichedQuoteModel, type QuoteModel } from './types';

export const normalizeQuoteResponse = (
  response: QuoteModel | EnrichedQuoteModel,
): Quote => {
  const customer = (response as EnrichedQuoteModel)?.customer;

  const normalizedResponse = {
    id: response.id,
    distributor_id: response?.distributor_id,
    status: response?.status,
    active_policy_id: response?.active_policy_id,
    created_time: response?.created_time,
    submission_source: response?.submission_source,
    coverage_package: response?.coverage_package,
    distributor: response?.distributor,
    created_by_user: response.created_by_user,
    customer: {
      ...customer,
      address:
        customer?.address ??
        normalizePlaceResponse({
          zip_code: customer?.organization?.postcode,
          street_name: customer?.organization?.address_line2,
          street_number: customer?.organization?.additional_address_info,
          provider_place_uuid:
            customer?.organization?.address_provider_uuid ?? '',
          provider: customer?.organization?.address_provider ?? '',
          name: customer?.organization?.address_name ?? '',
          full_address: customer?.organization?.address_line1 ?? '',
          country_name: customer?.organization?.country_name,
          country_code: customer?.organization?.country_code,
          state_code: customer?.organization?.state_code,
          state_name: customer?.organization?.state_name,
          city: customer?.organization?.city,
          geolocation: {
            lat: Number(customer?.organization?.address_lat),
            lng: Number(customer?.organization?.address_lon),
          },
        }),
    },
    tax:
      response?.customer_tax_amount !== undefined &&
      response?.tax_currency?.code &&
      response?.tax_description
        ? {
            value: response.customer_tax_amount,
            currency_code: response.tax_currency.code,
            description: response.tax_description,
          }
        : undefined,
    converted_tax_amount: response?.converted_tax_amount,
    insured_value: response?.value_insured,
    insured_value_currency: response?.customer_premium_currency?.code,
    premium_value: response?.customer_premium_value,
    converted_customer_premium_value:
      response?.converted_customer_premium_value,
    premium_currency: response?.customer_premium_currency?.code,
    premium_rate: response?.premium_rate,
    insurer_premium_value: response?.insurer_premium_value,
    insurer_premium_currency: response?.insurer_premium_currency?.code,
    deductible_value: response?.submitted_deductible_value,
    deductible_currency: response?.customer_premium_currency?.code,
    commodity_value: response?.commodity_value,
    commodity_currency: response?.commodity_currency?.code,
    commodity_external_description: response?.commodity_external_description,
    commodity_category_id: response?.commodity?.id,
    commodity_category: response?.commodity?.commodity_type,
    freight_cost: response?.freight_cost,
    freight_cost_currency: response?.freight_cost_currency?.code,
    duty_cost: response?.duty_cost,
    duty_cost_currency: response?.duty_cost_currency?.code,
    exchange_rate: response?.currency_exchange_rate,
    external_shipment_id: response?.external_shipment_id,
    primary_transport_mode_code: response?.primary_transport_mode_code,
    secondary_transport_mode_code: response?.secondary_transport_mode_code,
    loading_place: response?.loading_place,
    loading_transport_mode_code: response?.loading_transport_mode_code,
    container_mode: response?.container_mode,
    origin_port_code: response?.origin_port_code,
    origin_port: response?.origin_port,
    origin_place: normalizePlaceResponse(response?.origin_place),
    destination_port_code: response?.destination_port_code,
    destination_port: response?.destination_port,
    destination_place: normalizePlaceResponse(response?.destination_place),
    delivery_place: response?.delivery_place,
    delivery_transport_mode_code: response?.delivery_transport_mode_code,
    incoterm_code: response?.incoterm_code,
    special_conditions: response?.special_conditions?.map(({ value }) => value),
    eta: response?.eta,
    etd: response?.etd,
    container_ids: response?.container_ids,
    vessel_name: response?.vessel_name,
    external_reference: response?.external_reference,
    expiring_on: response?.expiring_on,
    goods_marks: response?.goods_marks,
    exclusion_reasons: response?.exclusion_reasons,
    original_quote_id: response?.original_quote_id,
    quote_additional_clauses: response?.quote_additional_clauses,
    letter_of_credit: response?.letter_of_credit,
    open_cover: response?.open_cover,
    issue_date: response?.issue_date,
    uplift_percentage: response?.uplift_percentage,
    permissions: response?.permissions,
    policy_permissions: response?.policy_permissions,
  };
  return normalizedResponse;
};
