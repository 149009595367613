import { type ComponentProps } from 'react';

import {
  DashboardWidget,
  type DashboardWidgetProps,
} from '../../../components/DashboardWidget/DashboardWidget';
import { MetricValue } from '../MetricValue/MetricValue';

interface MetricWidgetProps extends DashboardWidgetProps {
  metricItems: ComponentProps<typeof MetricValue>[];
  isEnabled?: boolean;
}

export const MetricWidget = ({
  metricItems,
  isEnabled = true,
  ...props
}: MetricWidgetProps) => {
  if (!isEnabled) {
    return null;
  }

  return (
    <DashboardWidget
      {...props}
      data-testid={`${props.title.toLowerCase()}-widget`}
    >
      <div className="flex max-sm:flex-col max-sm:gap-y-2 flex-row justify-center items-center h-full">
        {metricItems.map(({ value, title, currency, testId }) => (
          <div key={title} className="flex-1 font-bold flex items-center">
            <MetricValue
              value={value}
              currency={currency}
              title={title}
              testId={testId}
            />
          </div>
        ))}
      </div>
    </DashboardWidget>
  );
};
