import { useSuspenseQuery } from '@tanstack/react-query';

import { QuoteStatusBanner } from '../../../../components/QuoteStatusBanner/QuoteStatusBanner';
import { certificateQueries } from '../../../network/queries';

export function StatusBanner({ policyId }: { policyId: string }) {
  const { data: certificate } = useSuspenseQuery(
    certificateQueries.details({ policyId }),
  );

  return (
    <QuoteStatusBanner
      status={certificate?.quote?.status}
      exclusionReasons={certificate?.quote?.exclusion_reasons}
    />
  );
}
