import {
  type FilterableFields,
  type PaginationBaseQueryParams,
} from '../../../network/apis/types';
import { get, post } from '../../../network/AuthRequests';
import { bffServiceUrl } from '../../../network/netconfig';
import { normalizeShipmentResponse } from './normalizers';
import {
  type GetShipmentResponse,
  type GetShipmentsNormalizedResponse,
  type ShipmentModel,
  type ShipmentsSortableFields,
} from './types';

export const getShipments = async (
  params?: PaginationBaseQueryParams<ShipmentsSortableFields, FilterableFields>,
): Promise<GetShipmentsNormalizedResponse> => {
  const { data } = await post<GetShipmentResponse>(
    `${bffServiceUrl}/bff_shipments/get`,
    params,
  );

  return {
    ...data,
    shipments: data.shipments.map(normalizeShipmentResponse),
  };
};

export const getShipmentById = async (
  shipmentId: string,
): Promise<ShipmentModel> => {
  const { data } = await get<ShipmentModel>(
    `${bffServiceUrl}/bff_shipments/${shipmentId}`,
  );

  return data;
};
