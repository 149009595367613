import { type BaseQueryParams, type SortOrder } from '@breeze-ai/ui-library';

export enum TableQueryURLParams {
  QUERY = 'query',
  SORT_BY = 'sort_by',
  ORDER = 'order',
}

// TODO I am not sure where this should live? cargo ui with tables? or with the filters?
export const useTableQueryParamsV2 = (
  setSearchParams: React.Dispatch<React.SetStateAction<URLSearchParams>>,
) => {
  const getSortOrderFromURLParam = (
    currentSearchParams: URLSearchParams,
  ): SortOrder | undefined => {
    const sortOrder = currentSearchParams.get(TableQueryURLParams.ORDER);
    if (sortOrder !== 'asc' && sortOrder !== 'desc') {
      return;
    }
    return sortOrder;
  };

  const getSortByFromURLParam = (currentSearchParams: URLSearchParams) =>
    currentSearchParams.get(TableQueryURLParams.SORT_BY) ?? undefined;

  const getQuerySearchURLParam = (currentSearchParams: URLSearchParams) =>
    currentSearchParams.get(TableQueryURLParams.QUERY) ?? undefined;

  const upsertTableQuery = (e: BaseQueryParams) => {
    setSearchParams((searchParams) => {
      if (e.query) {
        searchParams.delete(TableQueryURLParams.QUERY);
        searchParams.append(TableQueryURLParams.QUERY, e.query);
      } else {
        searchParams.delete(TableQueryURLParams.QUERY);
      }

      if (e.sortBy) {
        searchParams.delete(TableQueryURLParams.SORT_BY);
        searchParams.append(TableQueryURLParams.SORT_BY, e.sortBy);
      } else {
        searchParams.delete(TableQueryURLParams.SORT_BY);
      }

      if (e.order) {
        searchParams.delete(TableQueryURLParams.ORDER);
        searchParams.append(TableQueryURLParams.ORDER, e.order);
      } else {
        searchParams.delete(TableQueryURLParams.ORDER);
      }
      return searchParams;
    });
  };

  return {
    getQuerySearchURLParam,
    getSortOrderFromURLParam,
    getSortByFromURLParam,
    upsertTableQuery,
  };
};
