import { LinkButton, SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useUser } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { useTracking } from '../../../utils/snowplow/hooks';
import { ActiveOpenCoverTooltip } from '../../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { QuotesTable } from '../components/QuotesTable/QuotesTable';

export const QuotesPage = () => {
  useTracking();
  const { isWtw } = usePlatform();
  const user = useUser();
  const isAuthorized = Boolean(user?.permissions.quotes?.view);
  const isAuthorizedToCreateQuotes = Boolean(user?.permissions.quotes?.create);

  return (
    <SectionLayout
      isAuthorized={isAuthorized}
      title="Quotes"
      tooltip={
        isWtw
          ? {
              trigger: <ActiveOpenCoverTooltip.Trigger />,
              content: <ActiveOpenCoverTooltip.Content />,
            }
          : undefined
      }
      data-testid="quotes-page"
      actions={
        isAuthorizedToCreateQuotes && (
          <LinkButton
            size="small"
            data-testid="get-quote-button"
            href="/quotes/create"
            width="auto"
            label="Get A Quote"
          />
        )
      }
    >
      <QuotesTable />
    </SectionLayout>
  );
};
