import {
  type ComponentProps,
  type ReactNode,
  useEffect,
  useState,
} from 'react';
import { HiMenu } from 'react-icons/hi';
import { HiMiniSquares2X2 } from 'react-icons/hi2';
import {
  DateCell,
  Icon,
  Link,
  Loader,
  TableEmptyState,
  Typography,
  useWindowSize,
} from '@breeze-ai/ui-library';
import { Button, clsxMerge } from '@breezeai-frontend/cargo-ui';
// TODO: use clsxMerge from @breezeai-frontend/cargo-ui
import classnames from 'classnames';

import { fileTypeIcons } from '../file-icons';
import styles from './AssetsList.module.scss';

export type Asset = {
  name: string;
  path?: string;
  type?: string;
  creationDate?: string;
  issueDate?: string;
  testId?: string;
  isError?: boolean;
  isHidden?: boolean;
  isLoading?: boolean;
  onTryAgain?: () => void;
};

type AssetsListView = 'list' | 'grid';
type ViewControlButton = {
  view: AssetsListView;
  icon: ReactNode;
};

type AssetsListProps = {
  assets: Asset[];
  defaultView?: AssetsListView;
  title?: ReactNode;
  emptyState?: ReactNode;
  isError?: boolean;
  isLoading?: boolean;
};

const linkCommonProps: Partial<ComponentProps<typeof Link>> = {
  role: 'asset-link',
  className: styles.assetItem,
  newTab: true,
  underline: false,
  icon: false,
};

const LinkWrapper = ({
  children,
  isClickable,
  ...props
}: ComponentProps<typeof Link> & { isClickable?: boolean }) => {
  return isClickable ? (
    <Link {...linkCommonProps} {...props}>
      {children}
    </Link>
  ) : (
    <div
      className={linkCommonProps.className}
      aria-disabled={true}
      role={linkCommonProps.role}
    >
      {children}
    </div>
  );
};

const viewButtons: ViewControlButton[] = [
  { view: 'grid', icon: <HiMiniSquares2X2 /> },
  { view: 'list', icon: <HiMenu /> },
];

const AssetsGridItem = ({
  asset: {
    name,
    type = 'pdf',
    path,
    creationDate,
    issueDate,
    isError,
    isLoading,
    onTryAgain,
  },
}: {
  asset: Asset;
}) => (
  <LinkWrapper href={path} isClickable={!isError && !isLoading}>
    <div className={styles.body}>
      <div className={styles.illustration}>{fileTypeIcons[type]}</div>
      <Typography level="h5" ellipsis className={styles.fileName}>
        {name}
      </Typography>
    </div>
    <div className={styles.metadata}>
      {!isError && !isLoading && (issueDate || creationDate) && (
        <DateCell value={issueDate ?? creationDate} />
      )}
    </div>
    <div className={styles.status}>
      {isLoading && <Loader type="progress-bar" />}
      {isError && (
        <div className={styles.error}>
          <Icon icon="triangle-exclamation" size="sm" variant="error" />
          <Typography level="base" variant="error">
            Something went wrong.
          </Typography>
          <Button
            size="small"
            variant="ghost"
            width="fixed"
            onPress={onTryAgain}
            label="Try again."
          />
        </div>
      )}
    </div>
  </LinkWrapper>
);

const AssetsListItem = ({
  asset: {
    name,
    type = 'pdf',
    path,
    creationDate,
    issueDate,
    testId,
    isError,
    isLoading,
    onTryAgain,
  },
}: {
  asset: Asset;
}) => (
  <LinkWrapper
    href={path}
    data-testid={testId}
    isClickable={!isError && !isLoading}
  >
    <div className={styles.header}>
      <div className={styles.illustration}>{fileTypeIcons[type]}</div>
      <Typography level="h5" ellipsis>
        {name}
      </Typography>
    </div>

    <div className={styles.metadata}>
      {!isError && !isLoading && (issueDate || creationDate) && (
        <DateCell value={issueDate ?? creationDate} />
      )}
    </div>
    <div className={styles.status}>
      {isLoading && <Loader type="progress-bar" />}
      {isError && (
        <div className={styles.error}>
          <Icon icon="triangle-exclamation" size="sm" variant="error" />
          <Typography level="base" variant="error">
            Something went wrong.
          </Typography>
          <Button
            size="small"
            variant="ghost"
            width="fixed"
            onPress={onTryAgain}
            label="Try again."
          />
        </div>
      )}
    </div>
  </LinkWrapper>
);

export const AssetsList = ({
  assets,
  title,
  defaultView = 'list',
  emptyState = (
    <TableEmptyState
      title="Nothing here"
      description="There are no available documents to show"
      testId="assets-empty-state"
      className={styles.emptyState}
    />
  ),
}: AssetsListProps) => {
  const [view, setView] = useState<AssetsListView>(defaultView);
  const visibleAssets = assets.filter(({ isHidden }) => !isHidden);
  const isEmpty = visibleAssets.length === 0;
  const { width } = useWindowSize();
  const isMinWidth = width < 1000;

  useEffect(() => {
    if (isMinWidth) {
      setView('grid');
    }
  }, [isMinWidth]);

  const viewController = !isMinWidth && (
    <div className={styles.viewController}>
      {viewButtons.map(({ view: viewName, icon }, i) => (
        <Button
          key={i}
          width="square"
          size="small"
          leftIcon={icon}
          onPress={() => setView(viewName)}
          customStyles={clsxMerge(
            'rounded-md aspect-square h-7',
            view === viewName
              ? 'text-icons-white'
              : 'bg-toggle-bg-default text-icons-black hover:text-white',
          )}
        />
      ))}
    </div>
  );

  return (
    <div className={styles.assetsListWrapper}>
      <div className={classnames(styles.header, 'border-b-[1px] pb-4')}>
        {title}
        {!isEmpty && viewController}
      </div>
      {isEmpty ? (
        <div className={styles.emptyState}>{emptyState}</div>
      ) : (
        <div
          className={classnames({
            [styles.grid]: view === 'grid',
            [styles.list]: view === 'list',
          })}
        >
          {visibleAssets.map((asset, i) =>
            view === 'grid' ? (
              <AssetsGridItem asset={asset} key={i} />
            ) : (
              <AssetsListItem asset={asset} key={i} />
            ),
          )}
        </div>
      )}
    </div>
  );
};
