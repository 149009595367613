import { queryOptions } from '@tanstack/react-query';
import { stringify } from 'query-string';

import { QueryLevelEnum } from '../../../model/Place';
import { type GetPlaceSuggestionsResponse } from '../../../network/apis/places/types';
import {
  type FilterableFields,
  type PaginationBaseQueryParams,
} from '../../../network/apis/types';
import { get, post } from '../../../network/apis/utils';
import { bffServiceUrl } from '../../../network/netconfig';
import {
  type CertificateModel,
  type CertificatesSortableFields,
  type GetCertificatesResponse,
} from '../types';

export const certificateQueries = {
  details: ({ policyId }: { policyId?: string }) => {
    return queryOptions({
      enabled: !!policyId,
      queryKey: ['certificate', policyId],
      // TODO: components using this set of options for suspense need to be refactored
      // as ternary/null pattern is not good
      // Reference: https://github.com/TanStack/query/discussions/6206
      queryFn: () =>
        policyId
          ? get<CertificateModel>(
              `${bffServiceUrl}/bff-certificate-only/${policyId}`,
            )
          : null,
    });
  },
  all: ({
    params,
  }: {
    params?: PaginationBaseQueryParams<
      CertificatesSortableFields,
      FilterableFields
    >;
  }) => {
    const queryParams = params ? `?${stringify(params)}` : '';
    const queryKey = ['certificates', queryParams];
    return queryOptions({
      queryKey,
      queryFn: () =>
        post<GetCertificatesResponse>(
          `${bffServiceUrl}/bff-certificate-only/get`,
          queryParams,
        ),
    });
  },
  places: ({
    params,
  }: {
    params?: { query?: string; session_token: string };
  }) => {
    const queryParams = params
      ? `?${stringify({
          query_text: params.query,
          session_token: params.session_token,
          query_level_type: QueryLevelEnum.STREET,
        })}`
      : '';
    const queryKey = ['places', queryParams];
    return queryOptions({
      queryKey,
      queryFn: () =>
        get<GetPlaceSuggestionsResponse>(
          `${bffServiceUrl}/locations/places${queryParams}`,
        ),
      enabled: !!params?.query,
    });
  },
};
