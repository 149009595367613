import { forwardRef, type ReactNode } from 'react';
import { Icon } from '@breeze-ai/ui-library';
import { type IconName } from '@fortawesome/fontawesome-svg-core';
import { Typography } from '@mui/material';

import styles from './TriggerButton.module.scss';

type TriggerButtonProps = {
  name: string;
  label: string;
  prefixIcon?: IconName | React.ReactNode;
  suffixIcon?: IconName;
  isLoading?: boolean;
  selectedCount?: number;
};

function FilterCountWrapper({
  count,
  children,
}: {
  count?: number;
  children: ReactNode;
}) {
  return count ? (
    <div className={styles.wrapper} data-testid="filter-counter">
      <div className={styles.content}>{children}</div>

      <div className={styles.counterContainer}>
        <div className={styles.counter}>{count}</div>
      </div>
    </div>
  ) : (
    <>{children}</>
  );
}
// TODO rebuild using tailwind, aria and move to cargo ui
export const TriggerButton = forwardRef<HTMLButtonElement, TriggerButtonProps>(
  (
    { selectedCount, prefixIcon, suffixIcon, isLoading, label, name, ...rest },
    ref,
  ) => {
    return (
      <FilterCountWrapper count={selectedCount}>
        <button
          {...rest}
          ref={ref}
          aria-label={`${name} Filter options`}
          className={styles.triggerButton}
        >
          <span className="flex flex-row items-center gap-1.5">
            {typeof prefixIcon === 'string' ? (
              <Icon name={prefixIcon} size="xs" icon={prefixIcon as IconName} />
            ) : (
              prefixIcon
            )}
            <Typography variant="body2" noWrap>
              {label}
            </Typography>
          </span>
          {isLoading ? (
            <Icon
              role="loader"
              name="spinner"
              icon="spinner"
              className={styles.icon}
              size="xs"
              spin
            />
          ) : (
            suffixIcon && (
              <Icon
                name={suffixIcon}
                className={styles.icon}
                size="xs"
                icon={suffixIcon}
              />
            )
          )}
        </button>
      </FilterCountWrapper>
    );
  },
);
