import { useEffect, useMemo } from 'react';
import { FaSync } from 'react-icons/fa';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import {
  ClientErrors,
  ErrorMessage,
  type ErrorMessageProps,
} from '@breezeai-frontend/cargo-ui';
import { captureException } from '@sentry/react';
import { isAxiosError } from 'axios';

import { useUser } from '../../context/auth/auth-hooks';
import { SupportDialog } from '../../ui/components/SupportDialog/SupportDialog';

export const parseError = (error: unknown): string => {
  if (isRouteErrorResponse(error)) {
    return error.status.toString();
  } else if (isAxiosError(error)) {
    return error.response?.status.toString() ?? error.message;
  } else if (error instanceof Error) {
    return error.message;
  } else if (typeof error === 'string') {
    return error;
  }
  return 'Unknown error';
};

const useDefaultErrorPageDetails = (
  errorMessage?: string,
): ErrorMessageProps => {
  if (errorMessage?.includes(ClientErrors.PageNotFound)) {
    return {
      type: 'PageNotFound',
      title: 'Oops!',
      details: ['Sorry, the page you are looking for does not exist.'],
    };
  }
  if (errorMessage?.includes(ClientErrors.Unauthorized)) {
    return {
      type: 'PageNotFound',
      title: 'Oops!',
      details: ['Sorry, you are not authorized to view this page.'],
    };
  }
  return {
    type: 'SomethingWentWrong',
    title: 'Oops! Something went wrong.',
    details: ['Please try again in a few moments.'],
    actions: [
      {
        variant: 'secondary',
        label: 'Refresh',
        leftIcon: <FaSync />,
        id: 'refresh',
        onPress: () => window.location.reload(),
      },
    ],
  };
};

export const ErrorBoundaryFallback = ({
  ...props
}: Partial<ErrorMessageProps>) => {
  const user = useUser();
  const error = useRouteError() as Error;
  const parsedError = parseError(error);
  const defaultErrorPageDetails = useDefaultErrorPageDetails(parsedError);
  // useMemo enforced by lint error
  const errorPageDetails = useMemo(
    () => ({
      ...defaultErrorPageDetails,
      ...props,
    }),
    [defaultErrorPageDetails, props],
  );

  useEffect(() => {
    captureException(error, {
      level: 'error',
      tags: {
        errorType: 'RouteErrorBoundaryFallback',
        parsedError,
      },
      user: {
        id: user?.id,
        email: user?.email,
      },
      extra: {
        error,
      },
    });
  }, [error, user, parsedError]);

  return (
    <div
      data-testid="platform-error"
      className="h-full flex items-center justify-center"
    >
      <ErrorMessage {...errorPageDetails} />
      <SupportDialog />
    </div>
  );
};
