import { type ReactNode } from 'react';
import { Link } from 'react-aria-components';
import { FaChevronLeft } from 'react-icons/fa';

import { Typography } from '../Typography/Typography';

export function PageTitle({
  title,
  backLink,
  statusBadge,
}: {
  title: ReactNode;
  backLink?: string;
  // TODO refine statusBadge prop to be a Badge component
  statusBadge?: ReactNode;
}) {
  return typeof title === 'string' ? (
    <div className="flex flex-row gap-2 items-center">
      <Typography
        level="h2"
        customStyles="w-full whitespace-nowrap flex flex-row gap-2"
        role="heading"
        data-testid="page-title"
      >
        {backLink && (
          <Link href={backLink} className="flex items-center">
            <FaChevronLeft className="size-5" />
          </Link>
        )}
        {title}
      </Typography>
      {statusBadge}
    </div>
  ) : (
    <div
      className="w-full whitespace-nowrap flex flex-row gap-2"
      role="heading"
      data-testid="page-title"
    >
      {backLink && (
        <Link href={backLink} className="flex items-center">
          <FaChevronLeft className="size-5" />
        </Link>
      )}
      {title}
    </div>
  );
}
