import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { isTestEnv } from '../utils/env';

export const initializeSentry = () => {
  import.meta.env.VITE_APP_SENTRY_DSN && !isTestEnv
    ? Sentry.init({
        // The baggage header is used to pass data between different services. It causes these services to throw a CORS error.
        tracePropagationTargets: [/^(?!.*(snowplow|fullstory)).*$/],
        environment: import.meta.env.VITE_APP_ENV,
        dsn: import.meta.env.VITE_APP_SENTRY_DSN,
        integrations: [
          // See docs for support of different versions of variation of react router
          // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
          Sentry.replayIntegration({
            maskAttributes: ['password', 'token', 'refreshToken'],
            blockAllMedia: false,
            maskAllText: false,
            networkDetailAllowUrls: [/breezeai/, /tradeflow/],
            networkDetailDenyUrls: [/auth/],
            networkRequestHeaders: ['X-Custom-Header'],
            networkResponseHeaders: ['X-Custom-Header'],
          }),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.05,
        replaysOnErrorSampleRate: 1.0,
      })
    : console.warn('Sentry is disabled - no auth token provided');
};
