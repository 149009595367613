import moment from 'moment';

import {
  FilterableFields,
  type FilterBaseParam,
  FilterOperators,
} from '../../../network/apis/types';

// TODO find home for these utils when filters move to cargo ui

export const getFilterPayload = (
  filterName: FilterableFields,
  searchParams: URLSearchParams,
): FilterBaseParam<FilterableFields> | undefined => {
  const values = searchParams.get(filterName)?.split('_');
  if (!values) return;
  if (filterName === FilterableFields.CREATED_AT) {
    const [start, end] = values;
    const dateRangeValues = [
      moment(start).format('DD/MM/YYYY'),
      // add 1 day to the end date to include the end date
      moment(end).add(1, 'day').format('DD/MM/YYYY'),
    ];
    return {
      field: filterName,
      operator: FilterOperators.BETWEEN,
      values: dateRangeValues,
    };
  }
  return {
    field: filterName,
    operator: FilterOperators.EQUALS,
    values: values,
  };
};
