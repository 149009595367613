import { useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import {
  type BaseQueryParams,
  type PageState,
  ServerSideTableV2,
} from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';
import { type GridRowParams } from '@mui/x-data-grid-pro';

import { useFeatureToggle } from '../../../../context/auth/auth-hooks';
import { type PoliciesSortableFields } from '../../../../network/apis/policies/types';
import {
  type FilterableFields,
  type PaginationBaseQueryParams,
} from '../../../../network/apis/types';
import { post } from '../../../../network/apis/utils';
import { bffServiceUrl } from '../../../../network/netconfig';
import { reportException } from '../../../../utils/error-reporting/error-reporting';
import {
  CargoOwnersFilter,
  CreatedByFilter,
  CreatedOnFilter,
  useFilterStore,
} from '../../../components/TableFilters';
import { PrimaryAssuredFilter } from '../../../components/TableFilters/PrimaryAssuredFilter/PrimaryAssuredFilter';
import { normalizeCertificateResponse } from '../../normalizers';
import {
  type Certificate,
  type CertificatesSortableFields,
  type GetCertificatesNormalizedResponse,
  type GetCertificatesResponse,
} from '../../types';
import styles from './CertificatesTable.module.scss';
import {
  StatusFilter,
  StatusFilterTypeEnum,
} from './CertificateStatusFilter/CertificateStatusFilter';
import { useCertificatesTableColumns } from './use-certificates-table-columns';

// The table component is non compatible with react-query
export const getCertificates = async (
  params?: PaginationBaseQueryParams<
    CertificatesSortableFields,
    FilterableFields
  >,
): Promise<GetCertificatesNormalizedResponse> => {
  const { data } = await post<GetCertificatesResponse>(
    `${bffServiceUrl}/bff-certificate-only/get`,
    params,
  );

  return {
    ...data,
    certificates: data.certificates.map(normalizeCertificateResponse),
  };
};

export const CertificatesTable = () => {
  const DEFAULT_QUERY_PARAMS = {
    limit: 10,
  };

  const enableFilters = useFeatureToggle('enable_column_filters');

  const { filters, clearFilters } = useFilterStore((state) => ({
    filters: state.filters,
    clearFilters: state.clearFilters,
  }));

  const [queryParams, setQueryParams] =
    useState<BaseQueryParams>(DEFAULT_QUERY_PARAMS);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const columns = useCertificatesTableColumns();
  const navigate = useNavigate();

  const fetchCertificatesPage = useCallback(
    async (params: BaseQueryParams): Promise<PageState<Certificate>> => {
      return getCertificates({
        limit: params?.limit,
        query: params?.query,
        order: params?.order,
        //TODO This type cast is necessary because the type of sortBy is string | undefined. Would be nice to fix this in the table component.
        sort_by: params?.sortBy as PoliciesSortableFields,
        page_pointer: params?.pagePointer,
        filters,
        paginate: true,
      })
        .then((page) => {
          return {
            records: page.certificates,
            nextPagePointer: page.next_page_pointer,
            prevPagePointer: page.prev_page_pointer,
            totalRows: page.total_num_records,
          };
        })
        .catch((e) => {
          reportException(e);
          return { records: [] };
        });
    },
    [filters],
  );

  // clear filter when navigating away from page
  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, [clearFilters]);

  return (
    <div className="h-full w-full">
      <ServerSideTableV2<Certificate>
        testId="certificates-table"
        pinnedColumns={{ right: ['action'] }}
        fetchPage={fetchCertificatesPage}
        columns={columns}
        getRowId={(row) => row.id}
        onRowClick={({ row }: GridRowParams<Certificate>) => {
          navigate(
            `/certificates/details/${row.external_certificate_id}/${row.id}`,
          );
        }}
        actions={{ search: true, pageSize: true, filters: true }}
        searchProps={{
          placeholder: 'Search Certificate or Reference',
        }}
        setCurrentPageNumber={setCurrentPageNumber}
        currentPageNumber={currentPageNumber}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        autoHeight={false}
        sx={{
          '.MuiDataGrid-pinnedColumns--right': {
            boxShadow: 'none',
          },
          '.MuiDataGrid-pinnedColumnHeaders--right': {
            display: 'none',
          },
        }}
        filters={
          enableFilters ? (
            <ErrorBoundary fallbackRender={() => <></>}>
              <div className={styles.filters}>
                <CreatedOnFilter />
                <ErrorBoundary fallbackRender={() => <></>}>
                  <CreatedByFilter />
                </ErrorBoundary>
                <ErrorBoundary fallbackRender={() => <></>}>
                  <PrimaryAssuredFilter />
                </ErrorBoundary>
                <ErrorBoundary fallbackRender={() => <></>}>
                  <CargoOwnersFilter label="Named assured" />
                </ErrorBoundary>
                <StatusFilter type={StatusFilterTypeEnum.CERTIFICATE} />
                <Button
                  onPress={clearFilters}
                  variant="ghost"
                  width="fixed"
                  size="small"
                  isDisabled={!filters.length}
                  label="Clear filters"
                />
              </div>
            </ErrorBoundary>
          ) : undefined
        }
      />
    </div>
  );
};
