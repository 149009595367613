import { capitalize } from 'lodash-es';

import { useFeatureToggle, useUser } from '../../../../context/auth/auth-hooks';
import labels from '../../../labels';
import { FlowSteps } from '../../constants';

// TODO(https://breeze.fibery.io/Breeze/Task/Implement-Sealink-copy-using-internationalization-solution-2899)
// Remove this once labels are moved to internationalization solution.
/**
 *
 * @param step the current step in the Policy creation flow
 * @returns the CTA text for policy creation, or empty string otherwise
 */
export const usePolicyCtaText = (step: FlowSteps) => {
  const user = useUser();
  const enableDraftCertificate = useFeatureToggle('enable_draft_certificate');

  const enableReferPolicyFlow = user?.settings.find(
    (s) => s.name === 'enable_refer_a_policy_flow',
  )?.value;

  const policyLabel = capitalize(labels.terms.policy.singular);

  switch (step) {
    case FlowSteps.QUOTE_SUMMARY: {
      if (enableDraftCertificate) {
        return 'Issue Draft & Policy';
      }

      const action = enableReferPolicyFlow ? 'Refer' : 'Issue';
      return `${action} a ${policyLabel}`;
    }
    case FlowSteps.SHIPMENT_INFORMATION: {
      if (enableDraftCertificate) {
        return 'Create Draft & Policy';
      }

      const action = enableReferPolicyFlow ? 'Refer' : 'Create';
      return `${action} ${policyLabel}`;
    }
    default: {
      return '';
    }
  }
};
