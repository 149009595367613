import { FaCircleInfo } from 'react-icons/fa6';
import { Button, Tooltip, Typography } from '@breezeai-frontend/cargo-ui';

import { useAuthenticatedUser } from '../../../context/auth/auth-hooks';
import { useDistributorActiveOpenCover } from '../../../network/apis/distributors/distributors';

function Trigger() {
  return (
    <div className="flex items-center gap-1 text-system-blue-600">
      <Typography
        customStyles="whitespace-nowrap"
        id="active-open-cover-tooltip-trigger"
      >
        Policy Details
      </Typography>
      <Button
        aria-labelledby="active-open-cover-tooltip-trigger"
        data-testid="title-active-open-cover-tooltip-btn"
        leftIcon={<FaCircleInfo />}
        variant="icon"
      />
    </div>
  );
}

function Content() {
  const user = useAuthenticatedUser();
  const { data, isLoading, error } = useDistributorActiveOpenCover({
    distributorId: user.distributor.id,
  });

  // TODO: to be handled by the data fetching refactor
  if (isLoading || error) {
    return <></>;
  }

  return (
    <Tooltip
      placement="bottom"
      className="bg-system-grey-900 p-4 rounded-md min-w-[300px]"
    >
      <div className="flex justify-between items-center text-white gap-3">
        <Typography text="bold">{user.distributor.legal_name}</Typography>
        <Typography customStyles="bg-white text-black px-2 py-1 rounded-md text-center text-sm">
          {data?.underwriting_year}
        </Typography>
      </div>
      <div className="grid gap-3 grid-cols-[70px_1fr] mt-2 text-white">
        <Typography customStyles="text-system-grey-200">Policy ID</Typography>
        <Typography text="bold">{data?.open_cover_policy_number}</Typography>

        <Typography customStyles="text-system-grey-200">Valid from</Typography>
        <div className="flex gap-3">
          <Typography text="bold">{data?.inception_date}</Typography>
          <Typography customStyles="text-system-grey-200">to</Typography>
          <Typography text="bold">{data?.termination_date}</Typography>
        </div>
      </div>
    </Tooltip>
  );
}

export const ActiveOpenCoverTooltip = {
  Trigger,
  Content,
};
