import {
  DateRangePicker as AriaDateRangePicker,
  type DateRangePickerProps as AriaDateRangePickerProps,
  type DateValue,
} from 'react-aria-components';
import { FaCalendar } from 'react-icons/fa';

import { Typography } from '../..';
import { Button } from '../Button/Button';
import { clsxMerge } from '../common/utils/classNameUtils';
import { DateInput } from '../DateField/DateField';
import { Dialog } from '../Dialog/Dialog';
import {
  Description,
  FieldError,
  FieldGroup,
  fieldStyles,
  Label,
} from '../Field/Field';
import { Popover } from '../Popover/Popover';
import { RangeCalendar } from '../RangeCalendar/RangeCalendar';

export interface DateRangePickerProps<T extends DateValue>
  extends AriaDateRangePickerProps<T> {
  label?: string;
  description?: string;
  startInputRef?: React.RefObject<HTMLInputElement>;
  endInputRef?: React.RefObject<HTMLInputElement>;
}

export function DateRangePicker<T extends DateValue>({
  label,
  description,
  isInvalid,
  isRequired,
  startInputRef,
  endInputRef,
  ...props
}: DateRangePickerProps<T>) {
  return (
    <AriaDateRangePicker
      {...props}
      isInvalid={isInvalid}
      isRequired={isRequired}
      className={clsxMerge(
        fieldStyles({ isInvalid }),
        props.className,
        'group min-w-64',
      )}
      validationBehavior="aria"
    >
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <FieldGroup className="w-auto px-1">
        <DateInput
          slot="start"
          className="px-2 py-1.5 text-sm"
          ref={startInputRef}
        />
        <span
          aria-hidden="true"
          className="text-gray-800 group-disabled:text-gray-200 group-disabled:forced-colors:text-[GrayText]"
        >
          –
        </span>
        <DateInput
          slot="end"
          className="flex-1 px-2 py-1.5 text-sm"
          ref={endInputRef}
        />
        <Button
          data-testid="date-range-picker-calendar-trigger"
          variant="ghost"
          leftIcon={<FaCalendar className="fill-icons-default" />}
          customStyles="w-6 rounded-md hover:bg-transparent"
        />
      </FieldGroup>
      {description && !isInvalid && <Description>{description}</Description>}
      <FieldError>
        <Typography slot="errorMessage" level="subtext">
          Invalid date range
        </Typography>
      </FieldError>
      <Popover>
        <Dialog>
          <RangeCalendar />
        </Dialog>
      </Popover>
    </AriaDateRangePicker>
  );
}
