import { type ErrorResponse } from 'react-router-dom';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { type NetworkResponse } from '../../AuthRequests';
import { bffServiceUrl } from '../../netconfig';
import { post, put } from '../utils';
import {
  type EnrichedQuoteModel,
  type QuoteMutationActions,
  type QuoteSubmissionPayload,
  type QuoteUpdatePayload,
} from './types';

export const useQuoteMutation = ({
  quoteId,
  action,
  options,
}: {
  quoteId?: string;
  action: QuoteMutationActions;
  options: UseMutationOptions<
    NetworkResponse<EnrichedQuoteModel>,
    ErrorResponse,
    QuoteSubmissionPayload | QuoteUpdatePayload
  >;
}) => {
  let endpoint = `${bffServiceUrl}/form/quote`;
  let method = post;

  switch (action) {
    case 'update':
      invariant(quoteId, 'quoteId is required for update action');
      endpoint = `${bffServiceUrl}/bff_quotes/${quoteId}`;
      method = put;
      break;
    case 'duplicate':
      endpoint = `${bffServiceUrl}/bff_quotes/duplicate`;
      method = post;
      break;
    case 'create':
      break;
  }

  return useMutation({
    mutationFn: (payload) => method<EnrichedQuoteModel>(endpoint, payload),
    ...options,
  });
};
