import { FaInfoCircle, FaShieldAlt, FaUser } from 'react-icons/fa';
import { FaMoneyBills, FaShirt } from 'react-icons/fa6';
import {
  CurrencyCell,
  Typography as BreezeUITypography,
} from '@breeze-ai/ui-library';
import {
  Button,
  Tooltip,
  TooltipTriggerWrapper,
  toPercentage,
} from '@breezeai-frontend/cargo-ui';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useFeatureToggle } from '../../../../context/auth/auth-hooks';
import { useRouteParams } from '../../../../router/router-hooks';
import { getTheme } from '../../../../utils/getTheme';
import { AttributesGrid } from '../../../components/AttributesGrid/AttributesGrid';
import { InfoItem } from '../../../components/InfoItem/InfoItem';
import { PageSection } from '../../../components/PageSection/PageSection';
import { COVERAGE_PACKAGES } from '../../../constants';
import labels from '../../../labels';
import { policiesQueries } from '../../network/queries';
import { type PolicyRouteParams } from '../PolicyDetailsPage';

type PolicyInsuranceDetailsProps = {
  className?: string;
};

const isWtw = getTheme() === 'wtw';

export const PolicyInsuranceDetails = ({
  className,
}: PolicyInsuranceDetailsProps) => {
  const showDutyCost = useFeatureToggle('enable_shipments_duty_cost');

  const { params } = useRouteParams<PolicyRouteParams>();
  const { policyId } = params;
  const { data: policy } = useSuspenseQuery(
    policiesQueries.details({ policyId }),
  );
  const {
    data: { coverage_options },
  } = useSuspenseQuery(policiesQueries.policyFormConfiguration());

  const {
    commodity_value,
    commodity_currency,
    insured_value,
    insured_value_currency,
    freight_cost,
    duty_cost,
    duty_cost_currency,
    premium_currency,
    commodity_external_description,
    coverage_package,
    uplift_percentage,
  } = policy?.quote ?? {};

  const insuredValueTooltipContent = `Insured Value is ${
    isWtw ? 'Commercial Invoice Value' : 'Cargo Value & Freight Cost'
  } + ${toPercentage(uplift_percentage ?? 0.1)}%`;

  return (
    <PageSection
      title="Insurance details"
      className={className}
      data-testid="insurance-details"
    >
      <AttributesGrid>
        <InfoItem
          title={labels.fields.distributor}
          value={policy?.quote?.distributor?.legal_name}
          testId="shipment-distributor"
          Icon={FaUser}
        />
        <InfoItem
          title={labels.fields.cargoOwner}
          value={policy?.customer?.company_name}
          testId="shipment-cargo-owner"
          Icon={FaUser}
        />
        {coverage_options.length > 1 && coverage_package && (
          <InfoItem
            title="Coverage Type"
            value={COVERAGE_PACKAGES[coverage_package].label}
            testId="coverage-package"
            Icon={FaShieldAlt}
          />
        )}
        <InfoItem
          title={labels.policyDetails.commodity}
          value={commodity_external_description}
          testId="shipment-commodity-description"
          Icon={FaShirt}
        />
        <InfoItem
          title={labels.policyDetails.cargoValue}
          value={
            <CurrencyCell
              value={commodity_value}
              currency={commodity_currency}
              typographyProps={{ bold: true, level: 'h5' }}
            />
          }
          testId="shipment-commodity-value"
          Icon={FaMoneyBills}
        />
        <InfoItem
          title="Freight Cost"
          value={
            <CurrencyCell
              value={freight_cost}
              currency={premium_currency}
              typographyProps={{ bold: true, level: 'h5' }}
            />
          }
          testId="shipment-freight-cost"
          Icon={FaMoneyBills}
        />
        {showDutyCost && (
          <InfoItem
            title="Duty Cost"
            value={
              <CurrencyCell
                value={duty_cost}
                currency={duty_cost_currency}
                typographyProps={{ bold: true, level: 'h5' }}
              />
            }
            testId="shipment-duty-cost"
            Icon={FaMoneyBills}
          />
        )}
        <InfoItem
          title={
            <div className="flex flex-row items-center pt-1">
              <BreezeUITypography
                variant="input"
                level="subtext"
                capitalize={true}
                ellipsis={true}
              >
                Insured Value
              </BreezeUITypography>
              <TooltipTriggerWrapper delay={0}>
                <Button
                  variant="icon"
                  customStyles="p-0 ml-1 h-min text-icons-default"
                >
                  <FaInfoCircle />
                </Button>
                <Tooltip placement="top">{insuredValueTooltipContent}</Tooltip>
              </TooltipTriggerWrapper>
            </div>
          }
          value={
            <CurrencyCell
              value={insured_value}
              currency={insured_value_currency}
              typographyProps={{ bold: true, level: 'h5' }}
            />
          }
          testId="shipment-insured-value"
          Icon={FaMoneyBills}
        />
      </AttributesGrid>
    </PageSection>
  );
};
