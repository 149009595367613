export class NoRefreshTokenError extends Error {
  constructor(message: string = 'auth/noRefreshToken') {
    super(message);
  }
}

export class RefreshTokenInvalid extends Error {
  constructor(message: string = 'auth/refreshTokenInvalid') {
    super(message);
  }
}
