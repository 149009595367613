import { type Dispatch, type SetStateAction, useMemo, useState } from 'react';

import { usePlatform } from '../../../../context/PlatformContext';
import { useCustomers } from '../../../../network/apis/customers/hooks';
import { MultiSelectRadioDropdown } from '../components/MultiSelectRadioDropdown';
import { useTableFilters } from '../hooks/useTableFilters';

export function CargoOwnerFilterV2({
  filterLabel,
  filterName,
  setSearchParams,
  searchParams,
}: {
  filterLabel: string;
  filterName: string;
  // TODO link to useTableFilters props
  setSearchParams: Dispatch<SetStateAction<URLSearchParams>>;
  searchParams: URLSearchParams;
}) {
  const { isWtw } = usePlatform();
  const [query, setQuery] = useState<string>('');

  const { upsertFilter, removeFilter, getAppliedAndNonAppliedOptions } =
    useTableFilters({
      setSearchParams,
    });
  const filterValues = searchParams.get(filterName)?.split('_');

  const {
    data: customers,
    isError,
    isLoading,
  } = useCustomers({
    params: {
      paginate: true,
      limit: 100,
      order: 'asc',
      query,
    },
  });

  const cargoOwnersOptions = useMemo(
    () =>
      customers?.map(({ company_name, id }) => ({
        label: `${company_name}`,
        value: String(id),
      })),
    [customers],
  );

  const onSubmit = () =>
    upsertFilter({
      filterName,
      values: selectedCargoOwners,
    });

  const onReset = () => removeFilter(filterName);
  const { appliedOptions, nonAppliedOptions } = useMemo(
    () => getAppliedAndNonAppliedOptions(filterValues, cargoOwnersOptions),
    [filterValues, cargoOwnersOptions, getAppliedAndNonAppliedOptions],
  );

  const [selectedCargoOwners, setSelectedCargoOwners] = useState<string[]>(
    filterValues ?? [],
  );

  return (
    <MultiSelectRadioDropdown
      optionsCount={appliedOptions.length}
      selected={selectedCargoOwners}
      setSelected={setSelectedCargoOwners}
      onSearch={setQuery}
      onReset={onReset}
      name="cargoOwner"
      label={filterLabel}
      dropdownIcon="users"
      nonAppliedOptions={nonAppliedOptions}
      appliedOptions={appliedOptions}
      onSubmit={onSubmit}
      placeholder={`Search ${isWtw ? 'named assured' : 'cargo owner'}...`}
      isError={isError}
      isLoading={isLoading}
    />
  );
}
