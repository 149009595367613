import { type ReactNode, Suspense, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ErrorMessage } from '@breeze-ai/ui-library';
import { Spinner } from '@breezeai-frontend/cargo-ui';

import { type ErrorResponse } from '../../../network/apis/types';
import { PageNotFound } from '../../../router/PageNotFound';
import { SectionContext, useSectionError } from './SectionContext';

type SectionProps = {
  /**
   * @deprecated Use the `isAuthorized` prop on the `SectionLayout` element instead.This will be removed once all pages are using the SectionLayout component.
   */
  enabled?: boolean;
  errorComponent?: ReactNode;
};

// TODO - Remove and use error boundaries on routes
const SectionError = () => {
  const { status } = useSectionError();

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <ErrorMessage code={status} />
    </div>
  );
};

/**
 * The Section component is meant to be used as a top-level Route element,
 * scoping relevant sub-routes components.
 */
export const Section = ({
  enabled = true,
  errorComponent = <SectionError />,
}: SectionProps) => {
  const { pathname } = useLocation();
  // TODO error should be handled by the PlatformErrorBoundary and not manually in context
  const [error, setError] = useState<ErrorResponse>();

  const pageNotFound = <PageNotFound />;

  let fallback: ReactNode;

  // TODO - Throw a 500 error and catch in route error boundary as intermediate solution.
  // A permanent solution would be to handle errors in the route error boundary
  if (error) {
    fallback = errorComponent;
  }

  if (!enabled) {
    // TODO - Throw a 404 error and catch in route error boundary
    fallback = pageNotFound;
  }

  // Reset error state when navigating away
  useEffect(() => {
    setError(undefined);
  }, [pathname]);

  return (
    <SectionContext.Provider value={{ error, setError }}>
      <Suspense fallback={<Spinner className="self-center" />}>
        {fallback ?? <Outlet />}
      </Suspense>
    </SectionContext.Provider>
  );
};
