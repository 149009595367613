import { useEffect } from 'react';

import { useRouteParams } from '../../../router/router-hooks';
import { reportException } from '../../../utils/error-reporting/error-reporting';
import { useSectionError } from '../../components/Section/SectionContext';
import { normalizeShipmentResponse } from '../../shipments/network/normalizers';
import { getShipmentById } from '../../shipments/network/shipments';
import { type PolicyFlowUrlSearchParams } from '../context/types';
import { useSetShipmentData } from './context-hooks';

// TODO: no longer used - remove
export const useFetchExistingShipmentData = () => {
  const setShipmentData = useSetShipmentData();
  const { setError } = useSectionError();
  const { searchParams } = useRouteParams<never, PolicyFlowUrlSearchParams>();
  const { shipment_id } = searchParams;

  useEffect(() => {
    if (shipment_id) {
      getShipmentById(shipment_id)
        .then((response) =>
          setShipmentData(normalizeShipmentResponse(response)),
        )
        .catch((e) => {
          reportException(e);
          setError(e);
        });
    }
  }, [setError, setShipmentData, shipment_id]);

  return shipment_id;
};
