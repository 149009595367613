import { LinkButton, SectionLayout } from '@breezeai-frontend/cargo-ui';

import { useUser } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { useTracking } from '../../../utils/snowplow/hooks';
import { ActiveOpenCoverTooltip } from '../../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { CertificatesTable } from './CertificatesTable/CertificatesTable';

export const CertificatesPage = () => {
  useTracking();
  const user = useUser();
  const { isWtw } = usePlatform();
  const isAuthorized = Boolean(user?.permissions.certificates?.view);
  const isAuthorizedToCreateCertificate = Boolean(
    user?.permissions.certificates?.create,
  );

  return (
    <SectionLayout
      isAuthorized={isAuthorized}
      title="Certificates"
      tooltip={
        isWtw
          ? {
              trigger: <ActiveOpenCoverTooltip.Trigger />,
              content: <ActiveOpenCoverTooltip.Content />,
            }
          : undefined
      }
      data-testid="certificates-page"
      actions={
        isAuthorizedToCreateCertificate && (
          <LinkButton
            size="small"
            data-testid="get-certificate-button"
            href="/certificates/create"
            width="auto"
            label="Get a Certificate"
          />
        )
      }
    >
      <CertificatesTable />
    </SectionLayout>
  );
};
