import { queryOptions } from '@tanstack/react-query';

import { get } from '../../../network/apis/utils';
import { bffServiceUrl } from '../../../network/netconfig';
import { normalizeShipmentResponse } from './normalizers';
import { type ShipmentModel } from './types';

export const shipmentsQueries = {
  details: ({ shipmentId }: { shipmentId?: string }) => {
    return queryOptions({
      queryKey: ['shipments', shipmentId],
      enabled: !!shipmentId,
      queryFn: () =>
        shipmentId
          ? get<ShipmentModel>(`${bffServiceUrl}/bff_shipments/${shipmentId}`)
          : // To handle enabled option when useSuspenseQuery is used. See https://github.com/TanStack/query/discussions/6206#discussioncomment-7342889
            null,
      select: (data) => data && normalizeShipmentResponse(data),
    });
  },
};
