import type React from 'react';

import { type Option } from '../../../components/TableFilters/components/MultiSelectRadioDropdown';

// TODO moved to cargo ui

/**
 * Hook to utilize inner logic of table filters
 * @param seSearchParams
 * @returns
 * @example
 * const { clearAllFilters, upsertFilter, getFilterValue, removeFilter } = useTableFilters(setSearchParams);
 * const filterValue = getFilterValue('filterName');
 * upsertFilter({ filterName: 'filterName', values: ['value1', 'value2'] });
 * removeFilter('filterName');
 * clearAllFilters(['filterName1', 'filterName2']);
 */
export const useTableFilters = ({
  setSearchParams,
}: {
  setSearchParams: React.Dispatch<React.SetStateAction<URLSearchParams>>;
}) => {
  const upsertFilter = ({
    filterName,
    values,
  }: {
    filterName: string;
    values: string[];
  }) => {
    setSearchParams((searchParams) => {
      // remove filter if no values
      if (values.length === 0) {
        searchParams.delete(filterName);
        return searchParams;
      }

      searchParams.delete(filterName);
      searchParams.append(filterName, values.join('_'));
      return searchParams;
    });
  };

  const removeFilter = (filterName: string) => {
    setSearchParams((searchParams) => {
      searchParams.delete(filterName);
      return searchParams;
    });
  };

  const clearAllFilters = (filterNames: string[]) => {
    for (const filterName of filterNames) {
      removeFilter(filterName);
    }
  };

  const getAppliedAndNonAppliedOptions = (
    filters: string[] = [],
    allOptions: Option[] = [],
  ) => {
    const appliedOptions: Option[] = [];
    const nonAppliedOptions: Option[] = [];
    for (const option of allOptions) {
      if (filters.includes(option.value)) {
        appliedOptions.push(option);
      } else {
        nonAppliedOptions.push(option);
      }
    }
    return { appliedOptions, nonAppliedOptions };
  };

  return {
    getAppliedAndNonAppliedOptions,
    clearAllFilters,
    upsertFilter,
    removeFilter,
  };
};
