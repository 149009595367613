import { Suspense, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  CreateCertificateModal,
  SectionLayout,
} from '@breezeai-frontend/cargo-ui';
import invariant from 'tiny-invariant';

import { useUser } from '../../../context/auth/auth-hooks';
import { usePlatform } from '../../../context/PlatformContext';
import { useTracking } from '../../../utils/snowplow/hooks';
import { ActiveOpenCoverTooltip } from '../../components/ActiveOpenCoverTooltip/ActiveOpenCoverTooltip';
import { StatusBanner } from '../CertificatesPage/CertificatesTable/StatusBanner/StatusBanner';
import { type CertificatesRouteParams } from '../types';
import { CertificateAdditionalClauses } from './CertificateAdditionalClauses/CertificateAdditionalClauses';
import { CertificateClaims } from './CertificateClaims/CertificateClaims';
import styles from './CertificateDetailsPage.module.scss';
import { CertificateShipmentDetails } from './CertificateShipmentDetails/CertificateShipmentDetails';
import { NamedAssuredDetails } from './NamedAssuredDetails/NamedAssuredDetails';
import { PollingFileList } from './PollingFileList/PollingFileList';
import { CertificateActions } from './Title/Title';

// TODO Create skeleton loader for this component that inherits width and height from the parent component
export const CertificateDetailsPage = () => {
  useTracking();
  const [searchParams, setSearchParams] = useSearchParams();
  const isReady = searchParams.get('ready') === 'true';
  const [isModalOpen, setIsModalOpen] = useState(isReady);
  const { isWtw } = usePlatform();
  const { policyId, certificateId } = useParams<CertificatesRouteParams>();
  const user = useUser();
  invariant(policyId, 'policyId is required');

  const isAuthorized = Boolean(user?.permissions.certificates?.view);

  return (
    <SectionLayout
      isAuthorized={isAuthorized}
      data-testid="certificate-details-page"
      title={`Certificate ${certificateId}`}
      backLink="/certificates"
      actions={<CertificateActions />}
      tooltip={
        isWtw
          ? {
              trigger: <ActiveOpenCoverTooltip.Trigger />,
              content: <ActiveOpenCoverTooltip.Content />,
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-6 w-full">
        <StatusBanner policyId={policyId} />
        <div className="w-full flex flex-row gap-6">
          <Suspense
            fallback={
              <div
                style={{ height: '330px', width: '100%' }}
                data-testid="skeleton-loader"
                className={styles.skeletonLoader}
              />
            }
          >
            <CertificateShipmentDetails policyId={policyId} />
          </Suspense>
        </div>
        <div className="w-full flex flex-row gap-6">
          <Suspense
            fallback={
              <div
                style={{ height: '330px', width: '100%' }}
                data-testid="skeleton-loader"
                className={styles.skeletonLoader}
              />
            }
          >
            <NamedAssuredDetails policyId={policyId} />
          </Suspense>
        </div>
        <Suspense
          fallback={
            <div
              style={{ height: '200px' }}
              className={styles.skeletonLoader}
            />
          }
        >
          <CertificateAdditionalClauses />
        </Suspense>
        <Suspense
          fallback={
            <div
              style={{ height: '200px' }}
              className={styles.skeletonLoader}
            />
          }
        >
          <PollingFileList policyId={policyId} />
        </Suspense>
        <Suspense
          fallback={
            <div
              style={{ height: '100px' }}
              className={styles.skeletonLoader}
            />
          }
        >
          <CertificateClaims policyId={policyId} />
        </Suspense>
      </div>
      <CreateCertificateModal
        data-testid="create-certificate-modal"
        isOpen={isModalOpen}
        onOpenChange={(value) => {
          setIsModalOpen(value);
          setSearchParams(undefined, {
            replace: true,
          });
        }}
        onExit={() =>
          setSearchParams(undefined, {
            replace: true,
          })
        }
      />
    </SectionLayout>
  );
};
