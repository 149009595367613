import { UILibraryContextProvider } from '@breeze-ai/ui-library';
import { GlobalToastRegion } from '@breezeai-frontend/cargo-ui';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { AuthContextProvider } from './context/auth/AuthContext';
import { PlatformContextProvider } from './context/PlatformContext';
import { QueryClientProvider } from './providers/ReactQueryProvider';
import { AppRouter } from './router/AppRouter';

export default function App(): JSX.Element {
  // TODO - There should be a top level error boundary here
  return (
    <QueryClientProvider>
      <PlatformContextProvider>
        <UILibraryContextProvider>
          <GlobalToastRegion />
          <AuthContextProvider>
            <AppRouter />
          </AuthContextProvider>
        </UILibraryContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </PlatformContextProvider>
    </QueryClientProvider>
  );
}
