import { useEffect } from 'react';
import {
  DatePicker,
  FormLayout,
  FormRow,
  Input,
  Typography,
} from '@breeze-ai/ui-library';
import { Button } from '@breezeai-frontend/cargo-ui';
import { useSuspenseQuery } from '@tanstack/react-query';
import moment from 'moment';

import { useAppConfiguration } from '../../../../context/app-configuration/useAppConfiguration';
import { useUser } from '../../../../context/auth/auth-hooks';
import { usePlatform } from '../../../../context/PlatformContext';
import { type Place, QueryLevelEnum } from '../../../../model/Place';
import { type Quote } from '../../../../model/Quote';
import { IncotermSelector } from '../../../components/IncotermSelector';
import { PlaceAutocomplete } from '../../../components/PlaceAutocomplete/PlaceAutocomplete';
import { PolicySummary } from '../../../components/PolicySummary/PolicySummary';
import labels from '../../../labels';
import { policiesQueries } from '../../../policies/network/queries';
import {
  getArrivalDateLimits,
  getDepartureDateLimits,
} from '../../../utils/eta-etd';
import { FlowSteps, SHIPMENT_FORM_LABELS } from '../../constants';
import {
  useInputValidation,
  usePolicyFlowContext,
  useSetFormData,
  useSetFormError,
} from '../../hooks/context-hooks';
import { useShipmentInformationForm } from '../../hooks/use-shipment-information-form';
import { usePolicyCtaText } from '../hooks/usePolicyCtaText';

type ShipmentInformationFormProps = {
  quote: Quote;
  onSubmit: () => void;
};

export const ShipmentInformationForm = ({
  quote,
  onSubmit,
}: ShipmentInformationFormProps) => {
  const { platform, isBreeze, isWtw } = usePlatform();

  const user = useUser();
  const { incoterms } = useAppConfiguration();

  const { step } = usePolicyFlowContext();

  const { data: configuration } = useSuspenseQuery(
    policiesQueries.policyFormConfiguration(),
  );
  const {
    data: { coverage_options },
  } = useSuspenseQuery(policiesQueries.policyFormConfiguration());

  const { data, isValid, errors } = useShipmentInformationForm(configuration);
  const setFormData = useSetFormData('shipment-info');
  const setFormError = useSetFormError();
  const validateInput = useInputValidation();

  const ctaText = usePolicyCtaText(FlowSteps.SHIPMENT_INFORMATION);

  useEffect(() => {
    if (isBreeze) return;

    setFormData({ issueDate: moment() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shipmentFormLabels =
    quote.primary_transport_mode_code &&
    SHIPMENT_FORM_LABELS[quote.primary_transport_mode_code];
  const { etd, eta, issueDate, containerIds, vehicleName, incoterm, customer } =
    data;

  const { company_name, address, email } = customer ?? {};

  const enableReferPolicyFlow = Boolean(
    user?.settings.find((s) => s.name === 'enable_refer_a_policy_flow')?.value,
  );

  const etdLimits = getDepartureDateLimits({
    eta,
    terminationDate: moment(quote.open_cover?.termination_date),
    platform,
  });
  const etaLimits = getArrivalDateLimits({
    etd,
    terminationDate: moment(quote.open_cover?.termination_date),
    platform,
  });

  return (
    <div
      className="flex flex-col items-center py-7 px-11 bg-white rounded-[30px] mt-5"
      data-testid="shipment-information-form"
    >
      <Typography level="h2">
        We just need a few more details before we{' '}
        {enableReferPolicyFlow ? 'refer' : 'issue'} the{' '}
        {labels.terms.policy.singular}
        {enableReferPolicyFlow ? '.' : ' '}
        <br />
        {enableReferPolicyFlow
          ? 'Once completed, the insurer will contact the Cargo Owner.'
          : ''}
      </Typography>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-x-8 gap-y-12 xl:gap-y-0 mt-12 text-pretty">
        <FormLayout className="col-span-2">
          <FormRow>
            <Typography level="h3">
              {labels.fields.cargoOwner} Details
            </Typography>
          </FormRow>
          <FormRow>
            <Input
              label={`${labels.fields.cargoOwner} Name`}
              testId="customer-name-input"
              value={company_name}
              disabled={!!company_name}
              onChange={({ target: { value: company_name } }) =>
                setFormData({ customer: { company_name } })
              }
              error={errors?.customer?.company_name?.error}
              errorHelperText={errors?.customer?.company_name?.reason}
              validator={(value) =>
                validateInput('customer.company_name', value)
              }
            />
            <PlaceAutocomplete
              value={address as Place}
              suggestionsQueryLevel={QueryLevelEnum.STREET}
              inputProps={{
                label: 'Address',
                placeholder: 'Cargo owner address',
                testId: 'customer-address-autocomplete',
                required: configuration?.is_address_required,
                error: errors?.customer?.address?.error,
                errorHelperText: errors?.customer?.address?.reason,
                validator: (value) =>
                  validateInput('customer.address', value, {
                    required: configuration?.is_address_required,
                  }),
              }}
              onChange={(place) =>
                setFormData({ customer: { address: place } })
              }
              getOptionLabel={(value: Place) =>
                value?.address?.full_address ?? value.name ?? ''
              }
            />
          </FormRow>
          {enableReferPolicyFlow && (
            <FormRow className="col-span-1">
              <Input
                label="Cargo Owner Email"
                testId="customer-email-input"
                value={email}
                onChange={({ target: { value: email } }) =>
                  setFormData({ customer: { email } })
                }
                required={enableReferPolicyFlow}
                error={errors?.customer?.email?.error}
                errorHelperText={errors?.customer?.email?.reason}
                validator={(value) =>
                  validateInput('customer.email', value, {
                    required: enableReferPolicyFlow,
                  })
                }
              />
            </FormRow>
          )}
          <FormRow>
            <Typography level="h3">Shipment Details</Typography>
          </FormRow>

          <FormRow>
            {isWtw && (
              <DatePicker
                value={issueDate}
                minDate={
                  quote.open_cover
                    ? moment(quote.open_cover.inception_date)
                    : moment().startOf('day').subtract(365, 'days')
                }
                maxDate={
                  quote.open_cover
                    ? moment(quote.open_cover.termination_date)
                    : moment().startOf('day').add(365, 'days')
                }
                onChange={(issueDate) =>
                  setFormData({ issueDate: issueDate ?? undefined })
                }
                onValidation={(error, reason) =>
                  setFormError({ field: 'issueDate', error, reason })
                }
                testId="issue-date-picker"
                required={true}
                inputProps={{
                  label: labels.fields.issueDate,
                  error: errors?.issueDate?.error,
                  errorHelperText: errors?.issueDate?.reason,
                }}
              />
            )}
            <DatePicker
              value={etd}
              minDate={etdLimits.min}
              maxDate={etdLimits.max}
              onChange={(etd) => setFormData({ etd: etd ?? undefined })}
              onValidation={(error, reason) =>
                setFormError({ field: 'etd', error, reason })
              }
              testId="etd-date-picker"
              required={true}
              inputProps={{
                label: labels.fields.etd,
                error: errors?.etd?.error,
                errorHelperText: errors?.etd?.reason,
              }}
            />
            <DatePicker
              value={eta}
              minDate={etaLimits.min}
              maxDate={etaLimits.max}
              onChange={(eta) => setFormData({ eta: eta ?? undefined })}
              onValidation={(error, reason) =>
                setFormError({ field: 'eta', error, reason })
              }
              testId="eta-date-picker"
              required={isWtw ? false : true}
              inputProps={{
                testId: 'eta-date-picker-input',
                label: labels.fields.eta,
                error: errors?.eta?.error,
                errorHelperText: errors?.eta?.reason,
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              defaultValue={quote.external_reference ?? ''}
              disabled={!!quote.external_reference}
              label={labels.fields.bookingReference}
              placeholder="Your internal reference"
              testId="shipment-information-booking-reference-input"
              onChange={({ target: { value: externalReference } }) =>
                setFormData({ externalReference })
              }
              error={errors?.externalReference?.error}
              errorHelperText={errors?.externalReference?.reason}
              validator={(value) =>
                validateInput('externalReference', value, {
                  required: configuration?.is_shipment_id_required,
                })
              }
            />
            <Input
              value={containerIds ?? ''}
              label={
                isWtw
                  ? labels.fields.containers
                  : shipmentFormLabels?.containerIds
              }
              placeholder="Number1, number2,..."
              onChange={({ target: { value: containerIds } }) =>
                setFormData({ containerIds })
              }
              testId="container-ids-input"
              error={errors?.containerIds?.error}
              required={configuration?.is_container_numbers_required}
              helperText="Insert comma-separated numbers"
              errorHelperText={errors?.containerIds?.reason}
              validator={(value) =>
                validateInput('containerIds', value, {
                  required: configuration?.is_container_numbers_required,
                })
              }
            />
          </FormRow>
          <FormRow>
            <Input
              value={vehicleName ?? ''}
              label={shipmentFormLabels?.vehicleName}
              placeholder="Enter name"
              testId="vehicle-name-input"
              onChange={({ target: { value: vehicleName } }) =>
                setFormData({ vehicleName })
              }
              required={false}
            />
            <IncotermSelector
              options={incoterms}
              selectedIncoterm={incoterm}
              onChange={(incoterm) => setFormData({ incoterm })}
            />
          </FormRow>
          <FormRow align="end" className="mt-auto">
            <Button
              variant="primary"
              data-testid="shipment-information-form-submit-button"
              onPress={onSubmit}
              isDisabled={!isValid || step === FlowSteps.LOADING}
              label={ctaText}
            />
          </FormRow>
        </FormLayout>
        <div className="row-start-1 xl:row-auto">
          <PolicySummary quote={quote} coverageOptions={coverage_options} />
        </div>
      </div>
    </div>
  );
};
