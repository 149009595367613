import { type Distributor } from './Distributor';
import { type EntitySetting } from './EntitySetting';

export const supportedFeatureToggles = [
  'show_currencies_exchange_rates',
  'show_share_quote_via_email',
  'enable_shipments_duty_cost',
  'enable_column_filters',
  'enable_column_sorting',
  'enable_draft_certificate',
] as const;

export type FeatureToggle = (typeof supportedFeatureToggles)[number];

/**
 * @description Permissions are defined at the app level and are used to determine what the user can do in the app - ie. what buttons to display, routes to render, columns to display etc.
 * These will work in tandem with the entity level permissions (to be implemented).
 *
 * Note: view_entity permissions will always be true if the parent property is present
 */
// TODO replace with openapi client generated types
export type UserPermissions = {
  invoices?: {
    view?: true;
  };
  customers?: {
    view?: true;
  };
  shipments?: {
    view?: true;
  };
  insights?: {
    view?: true;
    download_report?: true;
  };
  quotes?: {
    create?: true;
    view?: true;
    show_rates?: true;
    show_premiums?: true;
  };
  policies?: {
    create?: true;
    view?: true;
    show_cert_pdf?: true;
    show_master_policy_pdf?: true;
  };
  certificates?: {
    create?: true;
    view?: true;
    edit_issued?: true;
    show_cert_pdf?: true;
    show_master_policy_pdf?: true;
  };
  claims?: {
    create?: true;
    view?: true;
  };
  storage_locations?: {
    view?: true;
  };
};

export type User = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  distributor: Distributor;
  has_access_to_platform: boolean;
  feature_toggles: { [K in FeatureToggle]: boolean };
  permissions: UserPermissions;
  settings: EntitySetting[];
  avatar_color?: string;

  /**
   * @deprecated will be replaced by Auth0 and token based permissions.
   */
  firebase_uid: string;
  role?: string;
};
export const isUserType = (user: unknown): user is User => {
  return (
    user !== null &&
    typeof user === 'object' &&
    'id' in user &&
    'first_name' in user &&
    'last_name' in user &&
    'email' in user &&
    'distributor' in user &&
    'feature_toggles' in user &&
    'settings' in user &&
    'firebase_uid' in user &&
    'role' in user
  );
};
