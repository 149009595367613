import { type CurrencyDefinition } from '../../model/CurrencyValue';
import { type Distributor } from '../../model/Distributor';
import { type PlaceModel } from '../../model/Place';
import { type AccountUser } from '../../network/apis/users/types';
import { type MapsApiPayload } from '../certificates/types';

export interface StorageLocationConfiguration {
  policy_insured_value_limit: number;
  default_currency: CurrencyDefinition;
}

export enum StorageLocationStatusEnum {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export enum StorageLocationTypeEnum {
  WAREHOUSE = 'warehouse',
  FLOATING = 'floating',
  OPEN_YARD = 'open_yard',
}

export enum StorageLocationFileTypeEnum {
  SURVEY_REPORT = 'survey_report',
  OTHER = 'other',
}

export enum StorageLocationFileStatusEnum {
  ACTIVE = 'active',
  EXPIRING_SOON = 'expiring_soon',
  EXPIRED = 'expired',
}

export type StorageLocationFileModel = {
  id: number;
  created_time: ISODate;
  modified_time: ISODate;
  storage_file_type: StorageLocationFileTypeEnum;
  file_id: number;
  signed_url: string;
  file_name: string;
  file_type: SupportedFileTypes;
  expiry_date?: ISODate;
  status: StorageLocationFileStatusEnum;
};

export type StorageLocationModel = {
  id: number;
  created_date_time: ISODate;
  modified_date_time: ISODate;
  location_id: string;
  distributor_id: string;
  location_name: string;
  address_id: string;
  owned_or_operated_by_others: boolean;
  type: StorageLocationTypeEnum;
  status: StorageLocationStatusEnum;
  insured_value_limit?: number;
  insured_value_limit_currency_id?: number;
  active_total_insured_value?: number;
  active_total_insured_value_currency_id?: number;
  capacity?: number;
  notes?: string;
  created_by_user_id: number;
  updated_by_user_id: number;
  address: PlaceModel;
  storage_location_files: StorageLocationFileModel[];
  active_storage_survey_report_file?: StorageLocationFileModel;
  active_total_insured_value_currency?: CurrencyDefinition;
  insured_value_limit_currency?: CurrencyDefinition;
  created_by_user: AccountUser;
  updated_by_user: AccountUser;
  distributor: Distributor;
};

export interface CreateStorageLocationPayload {
  location_name: string;
  location_type: StorageLocationTypeEnum;
  location_address_provider_details: MapsApiPayload;
  owned_or_operated_by_others: boolean;
  insured_value_limit?: number;
  insured_value_limit_currency?: string;
  active_total_insured_value?: number;
  active_total_insured_value_currency?: string;
  notes?: string;
}

export interface UpdateStorageLocationPayload
  extends Partial<CreateStorageLocationPayload> {
  status?: StorageLocationStatusEnum;
}

export enum SupportedFileTypes {
  CSV = 'csv',
  DOC = 'doc',
  DOCX = 'docx',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PDF = 'pdf',
  PNG = 'png',
  XLS = 'xls',
  XLSX = 'xlsx',
}

export type StorageLocationFilePayload = {
  name: string;
  type: StorageLocationFileTypeEnum;
  expiry_date?: ISODate;
  data_type: string;
  size_in_bytes: number;
  content: string;
  file_type: SupportedFileTypes;
};

export type StorageRouteParams = {
  policyId?: string;
  storageLocationId?: string;
};

export type StorageCreationModalRouteParams = {
  step: 'inputs' | 'files';
} & StorageRouteParams;

export type StorageLocationsSortableFields =
  | 'id'
  | 'certificate_id'
  | 'created_at';

export type StorageLocationAddress = {
  city: string;
  country: string;
  place_id: string;
};
